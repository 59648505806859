<template>
page not fount
  <vue-tel-input v-model="phone" maxlength="10"></vue-tel-input>
{{ phone }}

<v-skeleton-loader
    boilerplate
    class="mx-auto"
    elevation="2"
    max-width="360"
   
  ></v-skeleton-loader>


</template>
<script>
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'
export default{
  components: {
    VSkeletonLoader,
  },
    data() {
    return {
      phone:""
    };
  },


}

</script>