<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light" style="background-color: transparent;">
        <div class="container-fluid">
          <v-img :src="require('@/assets/buymytrip.png')" height="50px" />
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active mx-4" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link mx-4" href="#">About us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link mx-4" href="#">Destination</a>
              </li>
              <li class="nav-item">
                <a class="nav-link mx-4" href="#">Contacts</a>
              </li>
            </ul>
            <div class="booknow">
              <v-btn class="book-ticket" variant="tonal" rounded="5" height="40px" color="red" style="color:white;">Book Ticket</v-btn>
            </div>
            <div class="form-btn">
              <v-btn height="30px" class="btn-box text-dark">
                Login
              </v-btn><span class="pipe text-dark">|</span>
              <v-btn  height="30px" class="btn-box text-dark">
                Register
              </v-btn>
            </div>
          </div>
        </div>
      </nav>
      </header>
</template>

<style scoped>
    /* navbar */
.logo {
  width: 180px;
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-14);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-700);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

.v-btn__content {
  /* color: #FF5400; */
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}

/* navbar end */
</style>