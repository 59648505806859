<template>
    <v-container>
        <v-form v-model="valid" ref="form" @submit.prevent="submitForm">
            <div class="row">


                <div class="col-md-12">
                    <v-timeline direction="horizontal" line-inset="12">
                        <v-timeline-item dot-color="success">
                            <template v-slot:icon>
                                <v-icon color="white">mdi-magnify</v-icon>
                            </template>
                        </v-timeline-item>

                        <v-timeline-item dot-color="success">
                            <template v-slot:icon>
                                <v-icon color="white">mdi-airplane</v-icon>
                            </template>
                        </v-timeline-item>

                        <v-timeline-item dot-color="success">
                            <template v-slot:icon>
                                <v-icon color="white">mdi-account</v-icon>
                            </template>
                        </v-timeline-item>

                        <v-timeline-item dot-color="primary">
                            <template v-slot:icon>
                                <v-icon color="white" size="20">mdi-cash-multiple</v-icon>
                            </template>
                        </v-timeline-item>
                    </v-timeline>
                </div>


                <div class="col-lg-9 col-md-9 col-sm-12 mx-0 px-0">
                    <v-container fluid class="ps-sm-4 ps-0 pe-0 ">


                        <div class="fare-show">
                            <v-card rounded="2" max-width="400px" max-height="500px" class="pa-1">
                                <div class="d-flex justify-content-between m-1">
                                    <div>
                                        <p class="m-0" style="font-size:26px; font-weight: 500;">Total Price</p>
                                    </div>
                                    <div>
                                        <p class="tot-price">USD {{ totalAmountpassenger }}</p>
                                    </div>
                                </div>



                                <div class="row mt-2 px-1">
                                    <div class="col-md-12">
                                        <div class="row justify-content-between">
                                            <div class="col-md-6">
                                                <span style="font-size: 14px; font-weight: 500">Passengers</span>
                                            </div>
                                            <!-- <div class="col-md-4"></div> -->
                                            <div class="col-md-6" style="font-size: 14px">
                                                <span v-if="adult > 0">{{ adult }} x Adult</span><br />
                                                <span v-if="child > 0">{{ child }} x Child</span><br />
                                                <span v-if="inf > 0">{{ inf }} x Lap Infant</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <div class="mt-3 mx-1">

                                    <hr class="mt-1 mb-2" />
                                    <div class="d-flex align-end mb-2" style="cursor:pointer">


                                        <span style="padding-right:5px;" @click="isIcon = !isIcon"><a href="#">Fare
                                                Summary</a></span>

                                        <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse"
                                            href="#collapse-Example" aria-expanded="true" aria-controls="collapse-Example"
                                            v-model="isIcon" style="color:0D6EFD; overflow-anchor: none">{{ isIcon ?
                                                "mdi-chevron-up-box-outline" :
                                                "mdi-chevron-down-box-outline" }}</v-icon>
                                    </div>

                                    <hr class="mt-1 mb-2" />

                                    <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example">


                                        <div class="row">
                                            <div class="col-6">
                                                Base Price<br />
                                                Taxes & Fees
                                            </div>
                                            <div class="col-6" align="center">
                                                {{ totalbasePassanger }}<br />{{ totalTaxPassanger }}
                                            </div>
                                        </div>
                                        <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                                    </div>

                                    <br>
                                    <p style="font-size: 12px">
                                        All fares are quoted in USD .Some airlines may charge bag gage fees.
                                        Your credit/debit card may be billed in multiple charges totaling
                                        the final total price.
                                    </p>
                                </div>
                            </v-card>
                        </div>

                        <div class="mt-3 p-0" v-for="(res, index) of rountresult" :key="index">
                            <div class="hoveredCard">
                                <v-card rounded="0" style="padding:20px 0px;">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <h4 class="pt-1 ps-3">Trip Details</h4>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-lg-12">
                                            <div class="mt-2">
                                                <div>
                                                    <v-card style="overflow: unset;padding: 1px;">
                                                        <div class="row mt-4 mb-2">
                                                            <div class="col-md-3 col-3 mb-3 mb-md-0">

                                                                <div class="row ms-sm-1 ms-0 w-100">
                                                                    <div class="col-md-4 col-12">

                                                                        {{ getlogo(res.Depature) }}
                                                                        <span v-for="(result, index) of logo" :key="index">
                                                                            <span v-if="index == 0">
                                                                                <v-img class="airline-logo" :src="result"
                                                                                    width="40px"></v-img>
                                                                            </span>
                                                                            <span v-if="index > 0">
                                                                                <v-img :src="result" width="20px"></v-img>
                                                                            </span>
                                                                        </span>

                                                                    </div>
                                                                    <div class="col-md-8 col-12">
                                                                        <p class="m-0">
                                                                            <span class="f-size-14 air-name">{{
                                                                                getname(res.Depature) }}</span>
                                                                            <br />
                                                                            <span v-for="(result, index) of res.Depature"
                                                                                :key="result">
                                                                                <span class="f-size-9 air-id fw-normal">
                                                                                    {{ result.MarketingCarrier.AirlineID
                                                                                    }}-{{
    result.MarketingCarrier.FlightNumber }}
                                                                                </span>

                                                                                <span class="f-size-9"
                                                                                    v-if="index < res.Depature.length - 1">
                                                                                    /
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-md-6 col-9">
                                                                <div class="row d-flex w-100">
                                                                    <div class="col-md-3 col-4 p-0 airline-date">
                                                                        <span class="f-size-11">{{
                                                                            getDate(res.Depature) }}</span>
                                                                        <span class="f-size-16" style="margin-left:5px;">{{
                                                                            getTime(res.Depature)
                                                                        }}</span>
                                                                    </div>
                                                                    <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                                                        style="margin-bottom: 4px;">
                                                                        <span class="f-size-16 fw-500">{{
                                                                            getAirportCode(res.Depature) }}</span>
                                                                        <span class="f-size-16"> ({{ fromname }})</span>
                                                                        <span class="f-size-13">, {{
                                                                            getDepAirportName(res.Depature) }}</span>
                                                                    </div>
                                                                </div>

                                                                <div class="row d-flex w-100">
                                                                    <div class="col-sm-3 col-4 p-0 airline-date">
                                                                        <span class="f-size-11">{{
                                                                            getArrivaldate(res.Depature) }}</span>
                                                                        <span class="f-size-16" style="margin-left:5px;">{{
                                                                            getArrivalTime(res.Depature)
                                                                        }}</span>
                                                                    </div>

                                                                    <div class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                                                        style="margin-bottom: 4px;">
                                                                        <span class="f-size-16 fw-500">{{
                                                                            getArrivalCode(res.Depature) }}
                                                                        </span>
                                                                        <span class="f-size-16"> ({{ toname }})</span>
                                                                        <span class="f-size-13">, {{
                                                                            getArrAirportName(res.Depature) }}</span>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-md-1 col-3 ps-3">
                                                                <p class="f-size-12 text-center m-0">{{
                                                                    getDepStops(res.Depature) }}
                                                                    Stops</p>
                                                                <div
                                                                    class="d-flex flex-md-column flex-row justify-center align-center">
                                                                    <span class="f-size-9"
                                                                        v-for="(data, index) of res.Depature" :key="index">
                                                                        <span v-if="index !== 0">({{
                                                                            data.Departure.AirportCode
                                                                        }})</span>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-2 col-4 d-flex justify-md-center">
                                                                <div class="Timing-section">
                                                                    <v-icon size="18">mdi-clock-outline </v-icon>
                                                                    <span class="ms-1 f-size-14">{{ res.Depaturejourney.Time
                                                                    }}</span><br />
                                                                    <span class="f-size-12"
                                                                        style="line-height: 24px;">(Flight
                                                                        {{ depfligtTime(res.Depature) }})</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </v-card>
                                                </div>



                                                <br />



                                                <div>
                                                    <v-card style="overflow: unset;padding: 1px;">
                                                        <div class="row mt-4 mb-2">
                                                            <div class="col-md-3 col-3 mb-3 mb-md-0">


                                                                <div class="row ms-sm-1 ms-0 w-100">
                                                                    <div class="col-md-4 col-12">
                                                                        {{ getlogo1(res.Return) }}
                                                                        <span v-for="(result, index) of logo1" :key="index">

                                                                            <span v-if="index == 0">
                                                                                <v-img class="airline-logo" :src="result"
                                                                                    width="40px"></v-img>
                                                                            </span>
                                                                            <span v-if="index > 0">
                                                                                <v-img :src="result" width="20px"></v-img>
                                                                            </span>
                                                                        </span>




                                                                    </div>
                                                                    <div class="col-md-8 col-12">
                                                                        <p class="m-0">
                                                                            <span class="f-size-14 air-name">{{
                                                                                getname(res.Return) }}</span>
                                                                            <br />
                                                                            <span v-for="(result, index) of res.Return"
                                                                                :key="result">
                                                                                <span class="f-size-9 air-id fw-normal">{{
                                                                                    result.MarketingCarrier.AirlineID }}-{{
        result.MarketingCarrier.FlightNumber
    }}</span>
                                                                                <span class="f-size-9"
                                                                                    v-if="index < res.Return.length - 1"> /
                                                                                </span>
                                                                            </span>


                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-md-6 col-9">

                                                                <div class="row d-flex w-100">
                                                                    <div class="col-md-3 col-4 p-0 airline-date">
                                                                        <span class="f-size-11">{{
                                                                            getreturnDate(res.Return) }}</span>
                                                                        <span class="f-size-16" style="margin-left:5px;">{{
                                                                            getreturnTime(res.Return)
                                                                        }}</span>
                                                                    </div>
                                                                    <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                                                        style="margin-bottom: 4px;">
                                                                        <span class="f-size-16 fw-500">
                                                                            {{ getreturnAirportCode(res.Return) }}</span>
                                                                        <span class="f-size-16"> ({{ toname }})</span>
                                                                        <span class="f-size-13">, {{
                                                                            getArrAirportName(res.Depature) }}</span>
                                                                    </div>
                                                                </div>

                                                                <div class="row d-flex w-100">
                                                                    <div class="col-md-3 col-4 p-0 airline-date">
                                                                        <span class="f-size-11">{{
                                                                            getreturnArrivaldate(res.Return) }}</span>
                                                                        <span class="f-size-16" style="margin-left:5px;">{{
                                                                            getreturnArrivalTime(res.Return)
                                                                        }}</span>
                                                                    </div>

                                                                    <div class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                                                        style="margin-bottom: 4px;">
                                                                        <span class="f-size-16 fw-500">
                                                                            {{ getreturnArrivalcode(res.Return) }}
                                                                        </span>
                                                                        <span class="f-size-16"> ({{ fromname }})</span>
                                                                        <span class="f-size-13">, {{
                                                                            getDepAirportName(res.Depature) }}</span>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-md-1 col-3 ps-3">
                                                                <p class="f-size-12 text-center m-0">{{
                                                                    getReturnStops(res.Return) }}
                                                                    Stops</p>
                                                                <div
                                                                    class="d-flex flex-md-column flex-row justify-center align-center">
                                                                    <span class="f-size-9"
                                                                        v-for="(data, index) of res.Return" :key="index">
                                                                        <span v-if="index !== 0">({{
                                                                            data.Departure.AirportCode
                                                                        }})</span>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-2 col-4 d-flex justify-md-center">
                                                                <div class="Timing-section">
                                                                    <v-icon size="18">mdi-clock-outline </v-icon>
                                                                    <span class="ms-1 f-size-14">{{ res.Returnjourney.Time
                                                                    }}</span><br />
                                                                    <span class="f-size-12"
                                                                        style="line-height: 24px;">(Flight
                                                                        {{ retfligtTime(res.Return) }})</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </v-card>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </div>


                        <div>
                            <div class="mt-3">
                                <v-card rounded="1" style="font-size: 14px;">


                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <h4 class="pt-1 ps-3">Payment Details</h4>
                                        </div>
                                    </div>

                                    <div class="p-4">

                                        <div class="row">
                                            <div class="col-md-4 col-sm-6">
                                                <p class="mb-1">Card Number <span class="text-danger">*</span></p>
                                                <v-text-field :rules="[v => !!v || 'This field is required']"
                                                    v-model="billingDetails.cardnumber" type="number" maxlength="16"
                                                    placeholder="xxxx-xxxx-xxxx-xxxx" variant="outlined"></v-text-field>
                                            </div>
                                            <div class="col-md-3 col-sm-6">
                                                <p class="mb-1">Name on the card <span class="text-danger">*</span></p>
                                                <v-text-field :rules="cardNameValid" type="text"
                                                    v-model="billingDetails.cardholdername"
                                                    variant="outlined"></v-text-field>
                                            </div>
                                            <div class="col-md-3 col-sm-4 col-8">
                                                <p class="mb-1">Expire Date <span class="text-danger">*</span></p>
                                                <v-text-field v-model="billingDetails.ExpireDate" :rules="expDate"
                                                    @keydown="handleInput" label="MM / YY" @input="formatExpireDate"
                                                    variant="outlined"></v-text-field>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-4">
                                                <p class="mb-1">CVV <span class="text-danger">*</span></p>
                                                <v-text-field :rules="[v => !!v || 'This field is required']"
                                                    v-model="billingDetails.cvvnumber" type="number" maxlength="3"
                                                    variant="outlined" name="cvv"></v-text-field>
                                            </div>
                                        </div>


                                        <div>
                                            <div class="d-flex flex-sm-row flex-column mt-2">
                                                <div>We accept all major Credit Card : </div>
                                                <div class="d-flex ps-2">
                                                    <v-img :src="require('../assets/american_express.png')" width="65px" />
                                                    <v-img :src="require('../assets/master_card.png')" width="42px" />
                                                    <v-img :src="require('../assets/visa_card.png')" width="65px" />
                                                    <v-img :src="require('../assets/jcb.png')" width="42px" />
                                                    <v-img :src="require('../assets/discover.png')" width="46px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </v-card>
                            </div>


                            <div class="mt-3">
                                <v-card style="font-size: 14px;">

                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <h4 class="pt-1 ps-3">Billing Details</h4>
                                        </div>
                                    </div>

                                    <div class="p-4">


                                        <div class="row ps-2">
                                            <div class="col-md-4">
                                                <p class="mb-2">Full Name<span class="text-danger"> *</span></p>
                                                <v-text-field v-model="billingDetails.name" label="Enter Free Text"
                                                    :rules="[v => !!v || 'This field is required']"
                                                    variant="outlined"></v-text-field>
                                            </div>

                                            <div class="col-md-4">
                                                <p class="mb-2">Country<span class="text-danger"> *</span></p>
                                                <v-autocomplete :items="countriesData" item-title="name" variant="outlined"
                                                    :rules="[v => !!v || 'This field is required']"
                                                    v-model="billingDetails.country" class="country-field">
                                                </v-autocomplete>
                                            </div>

                                            <div class="col-md-4">
                                                <p class="mb-2">Address Line 1<span class="text-danger"> *</span></p>
                                                <v-text-field :rules="[v => !!v || 'This field is required']"
                                                    v-model="billingDetails.address1" label="Enter Address Line 1"
                                                    variant="outlined"></v-text-field>
                                            </div>
                                        </div>

                                        <div class="row ps-2">
                                            <div class="col-md-4">
                                                <p class="mb-2">Address Line 2 (Optional)</p>
                                                <v-text-field label="Enter Address Line 2" variant="outlined"
                                                    v-model="billingDetails.address2"></v-text-field>
                                            </div>

                                            <div class="col-md-4">
                                                <p class="mb-2">Region/State/Province<span class="text-danger"> *</span></p>

                                                <v-autocomplete :items="stateData" item-title="name" variant="outlined"
                                                    :rules="[v => !!v || 'This field is required']"
                                                    v-model="billingDetails.state" class="country-field">
                                                </v-autocomplete>

                                                <!-- <v-select class="v-select" label="State" :items="['America', 'Canada']"
                                                    v-model="billingDetails.state"
                                                    :rules="[v => !!v || 'This field is required']"
                                                    variant="outlined"></v-select> -->
                                            </div>

                                            <div class="col-md-4">
                                                <p class="mb-2">City<span class="text-danger"> *</span></p>
                                                <v-text-field :rules="[v => !!v || 'This field is required']"
                                                    v-model="billingDetails.city" label="Enter City"
                                                    variant="outlined"></v-text-field>
                                            </div>
                                        </div>

                                        <div class="row ps-2">
                                            <div class="col-md-4">
                                                <p class="mb-2">Zip Code<span class="text-danger"> *</span></p>
                                                <v-text-field v-model="billingDetails.zipcode" label="Enter Zip Code"
                                                    :rules="[v => !!v || 'This field is required']" type="number"
                                                    maxlength="8" variant="outlined"></v-text-field>
                                            </div>

                                            <div class="col-md-4">
                                                <p class="mb-2">Mobile / Phone Number<span class="text-danger"> *</span></p>

                                                <vue-tel-input v-model="billingDetails.phonenumber"></vue-tel-input>

                                            </div>

                                            <div class="col-md-4">
                                                <p class="mb-2">Email<span class="text-danger"> *</span></p>
                                                <v-text-field label="Enter Email" :rules="emailRequire" variant="outlined"
                                                    v-model="billingDetails.email"></v-text-field>
                                            </div>
                                        </div>

                                        <div class="row ps-2">
                                            <div class="col-md-4">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex align-end">
                                                        <label class="mb-1 d-flex justify-center align-center"
                                                            style="font-size: 13px;">Alternate Contact</label>
                                                    </div>
                                                    <v-radio-group class="alternate-Details" inline v-model="radioBtn">
                                                        <div class="d-flex align-items-end">
                                                            <v-radio label="Phone" value="1"
                                                                @click="altContact = true, mailAddress = false"></v-radio>
                                                            <v-radio label="Email" value="2"
                                                                @click="mailAddress = true, altContact = false"></v-radio>
                                                        </div>
                                                    </v-radio-group>
                                                </div>


                                                <vue-tel-input v-model="alterPhone" v-if="altContact"></vue-tel-input>

                                                <v-text-field v-model="billingDetails.email1" type="email" label="E-mail"
                                                    variant="outlined" required v-if="mailAddress"></v-text-field>
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>

                            <div class="mt-5 mb-5">
                                <p class="text-center font-weight terms-text">By clicking 'Confirm & Book', I agree that I
                                    have read
                                    and
                                    accepted the
                                    <span class="text-primary">Terms of use</span>.
                                </p>
                                <div class="d-flex justify-center continue-section">
                                    <v-btn class="continue-btn text-capitalize" type="submit" color="#5191fa"
                                        size="large">Confirm & Book</v-btn>
                                </div>
                            </div>
                        </div>

                    </v-container>
                </div>






                <div class="col-lg-3 col-md-3">
                    <div class="fare-summery" :style="{ top: topSticky }">
                        <v-card max-width="400px" max-height="500px" class="pa-1 m-auto">
                            <div class="d-flex justify-content-between m-1">
                                <div>
                                    <p class="m-0" style="font-size:20px; font-weight: 500;">Total Price</p>
                                </div>
                                <div>
                                    <p class="tot-price">USD {{ totalAmountpassenger }}</p>
                                </div>
                            </div>



                            <div class="row mt-2 px-1">
                                <div class="col-md-12">
                                    <div class="row justify-content-between">
                                        <div class="col-md-6">
                                            <span style="font-size: 14px; font-weight: 500">Passengers</span>
                                        </div>
                                        <!-- <div class="col-md-4"></div> -->
                                        <div class="col-md-6" style="font-size: 14px">
                                            <span v-if="adult > 0">{{ adult }} x Adult</span><br />
                                            <span v-if="child > 0">{{ child }} x Child</span><br />
                                            <span v-if="inf > 0">{{ inf }} x Lap Infant</span>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div class="mt-3 mx-1">

                                <hr class="mt-1 mb-2" />
                                <div class="d-flex align-end mb-2" style="cursor:pointer">


                                    <span style="padding-right:5px;" @click="isIcon = !isIcon"><a href="#">Fare
                                            Summary</a></span>

                                    <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse"
                                        href="#collapse-Example" aria-expanded="true" aria-controls="collapse-Example"
                                        v-model="isIcon" style="color:0D6EFD; overflow-anchor: none">{{ isIcon ?
                                            "mdi-chevron-up-box-outline" :
                                            "mdi-chevron-down-box-outline" }}</v-icon>
                                </div>

                                <hr class="mt-1 mb-2" />

                                <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example">


                                    <div class="row">
                                        <div class="col-6">
                                            Base Price<br />
                                            Taxes & Fees
                                        </div>
                                        <div class="col-6" align="center">
                                            {{ totalbasePassanger }}<br />{{ totalTaxPassanger }}
                                        </div>
                                    </div>
                                </div>

                                <br>
                                <p style="font-size: 12px">
                                    All fares are quoted in USD .Some airlines may charge bag gage fees.
                                    Your credit/debit card may be billed in multiple charges totaling
                                    the final total price.
                                </p>
                            </div>
                        </v-card>


                        <div class="mt-2 mb-2 continue-section" align="center">
                            <p class="font-weight f-size-11">By clicking 'Confirm & Book', I agree that I have read
                                and
                                accepted the
                                <span class="text-primary">Terms of use</span>.
                            </p>
                            <v-btn height="40px" class="continue-btn-1" type="submit"
                                style="font-size:16px; font-weight:500; color: white;text-transform: capitalize;background-color:#5191fa;">
                                Continue & Book</v-btn>
                        </div>
                    </div>

                </div>

            </div>
        </v-form>



    </v-container>
</template>
    
<script>

import moment from "moment";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import countries from '@/countries.json';
import state from '@/statas.json'
import { ref } from "vue";
import axios from "axios";


const month = ref({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
});


export default {
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },


    data() {
        return {
            limitPosition: 500,
            scrolled: false,
            lastPosition: 0,
            topSticky: "26%",
            countriesData: [],
            stateData: [],
            alterPhone: "",
            totalAmountpassenger: [],
            totalTaxPassanger: [],
            totalbasePassanger: [],
            det: {
                name: "",
                email: "",
            },
            adult: "",
            child: "",
            inf: "",
            from: "",
            to: "",
            fromdate: "",
            todate: "",
            fromname: "",
            toname: "",
            fromcode: "",
            tocode: "",
            basePrice: "",
            taxprice: "",
            city: "",
            month,
            isIcon: false,
            fromData: "",
            toData: "",
            adultLength: "",
            childLength: "",
            infantLength: "",
            classed: "",
            // flow,
            billingDetails: {
                cardnumber: "",
                cardholdername: "",
                ExpireDate: "",
                cvvnumber: "",
                logo: [],
                logo1: [],
                name: "",
                country: null,
                address1: "",
                address2: "",
                state: null,
                city: "",
                zipcode: "",
                phonenumber: "",
                email: "",
                phonenumber1: "",
                email1: "",
            },
            depAirId: [],
            retAirId: [],
            depAirLogo: [],
            retAirLogo: [],
            rountresult: [],
            totalmsg: [],
            strResult: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            bookinginiId: "",
            onewayresult: [],
            valid: false,
            altContact: true,
            mailAddress: false,
            // formatDate: "Month,year",
            emailId: "john@gmail.com",
            phone: "",


            // expDate: [
            //     v => !!v || 'This field is required',
            //     v => /^(0[1-9]|1[0-2])\/\d{2}$/.test(v) || 'Invalid format (MM / YY)',
            //     v => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
            // ],

            expDate: [
                v => !!v || 'This field is required',
                v => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
                v => {
                    const [month] = v.split('/');
                    const isInvalidMonth = parseInt(month) > 12;
                    const isInvalidFormat = !(v.match(/^(0[1-9]|1[0-2])\/\d{2}$/));

                    if (isInvalidMonth) return 'only 12 months';
                    if (isInvalidFormat) return 'Invalid format (MM / YY)';
                    return true;
                }
            ],




            emailRequire: [
                v => !!v || "This field is required",
                v => /.+@.+\..+/.test(v) || 'Please enter a valid email address',
            ],

            cardNameValid: [
                v => !!v || 'This field is required',
                v => /^[a-zA-Z]*$/.test(v) || 'Only letters are allowed'
            ],


            radioBtn: "1",
            todayDate: '',
            fullName: "",
            selectedCountries: [],
            addressLine: "",
            billingData: {
                hit_id: "ca-650932b2905438-25549374-2684",
                paxbill: {
                    bill_fullname: "RETEYEY",
                    bill_Country: "Ukraine",
                    bill_state: "Cherkas'ka(Cherkasy)",
                    bill_address1: "sdgsdgs",
                    bill_address2: "dfhfdh",
                    bill_city: "sdgsdgsdg",
                    bill_zipcode: "sdgsdgsd",
                    bill_email: "web.arishn@gmail.com",
                    bill_phone: "0655656569",
                    bill_alternate_contactno: null,
                    bill_alternate_email: null,
                },
                paxcard: {
                    card_no: "4111 1111 11111111",
                    card_name: "GHCHGHG",
                    card_expiry: "05/55",
                    card_cvc: "555",
                },
            },

            userBooking: {
                "title": "",
                "heading": "Trip Confirmation",
                "from": [
                    "booking@buymytrip.com"
                ],
                "body": "Trip Ref# bookinginiId",
                "message": "YVR-ICN-2023/12/21-1-OZ=>6101-12:05-17:00,ICN-SFO-2024/01/10-2-OZ=>212-20:50-14:00,SFO-YVR-2024/01/10-2-AC=>571-16:35-19:00",
                "country_id": "ICN",
                "country_state": "Seoul",
                "airp": "Incheon International Airport",
                "trip": "return",
                "itinstring": "",
                "dateformto": " ",
                "dateto": "",
                "flight": "AC-571",
                "booking_ref_id": "bookinginiId",
                "formto": "ICN-YVR",
                "paxname": "FDHDF",
                "adult": "1",
                "child": "0",
                "paxInfant": "0",
                "adultlastname": [],
                "adultfirstname": [],
                "adultmiddlename": [],
                "adultsalutation": [],
                "adultgender": [],
                "infantlapfirstname": [],
                "infantlaplastname": [],
                "infantlapmiddlename": [],
                "infantlapsalutation": [],
                "infantlapgender": [],
                "childfirstname": [],
                "childlastname": [],
                "childmiddlename": [],
                "childsalutation": [],
                "childgender": [],
                "modulated_pax": [],
                "DisplayTotalFare": "CAD 2,617.26",
                "pointofsale": "CA",
                "hit_id": "ca-650d54435c0152-12069909-7399",
                "pax_dob": [
                    "15-Oct-2011"
                ],
                "infantlapdob": [],
                "childdob": [],
                "getBookingRequestDatetime": "2023-Sep-22 09:08",
                "phoneno": "45345345",
                "bill_email": "web.arishn@gmail.com",
                "bill_phone": "653",
                "card_number": "4111 1111 1111 1111",
                "card_name": "BDFFF",
                "cabin": false,
                "single_price": "CAD 2,617.26",
                "arrival_time_date": ""
            },


        };
    },
    methods: {

        formatExpireDate(event) {
            if (this.billingDetails.ExpireDate.length <= 2 && event.inputType === 'deleteContentBackward') {

                this.billingDetails.ExpireDate = this.billingDetails.ExpireDate.slice(0, 3);
            }
            else if (this.billingDetails.ExpireDate.length === 2) {
                this.billingDetails.ExpireDate += '/';
            }
        },

        handleInput(e) {

            if (this.billingDetails.ExpireDate.length === 5 && e.keyCode !== 8) {
                e.preventDefault();
            }
        },

        // isMMYYFormat(value) {
        //     const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        //     return regex.test(value);
        // },





        passeData() {

            this.billingData.paxbill.bill_fullname = this.billingDetails.name
            this.billingData.paxbill.bill_Country = this.billingDetails.country
            this.billingData.paxbill.bill_state = this.billingDetails.state
            this.billingData.paxbill.bill_address1 = this.billingDetails.address1
            this.billingData.paxbill.bill_address2 = this.billingDetails.address2
            this.billingData.paxbill.bill_city = this.billingDetails.city
            this.billingData.paxbill.bill_zipcode = this.billingDetails.zipcode
            this.billingData.paxbill.bill_email = this.billingDetails.email
            this.billingData.paxbill.bill_phone = this.billingDetails.phonenumber
            this.billingData.paxbill.bill_alternate_contactno = this.billingDetails.phonenumber1
            this.billingData.paxbill.bill_alternate_email = this.billingDetails.email1
            this.billingData.paxcard.card_no = this.billingDetails.cardnumber
            this.billingData.paxcard.card_name = this.billingDetails.cardholdername
            this.billingData.paxcard.card_expiry = "05/55"
            this.billingData.paxcard.card_cvc = this.billingDetails.cvvnumber



            console.log(this.billingData, 'this.billingData  ')
        },

        retfligtTime(data) {
            console.log(data, 'ppppppppp')
            let $event = data
            let layoverTime = []
            for (let i = 0; i <= $event.length - 1; i++) {
                // console.log($event[i], 'data.length - 1')

                // const currentIndex = i;
                // const nextIndex = (i + 1) % data.length;

                const time1 = new Date(
                    `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
                );
                const time2 = new Date(
                    `${data[i].Departure.Date}T${data[i].Departure.Time}`
                );

                const diffInMilliseconds = Math.abs(time2 - time1);
                const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

                const hours = Math.floor(diffInMinutes / 60);
                const minutes = diffInMinutes % 60;

                layoverTime.push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`);

            }
            let totalMinutes = layoverTime.reduce((total, time) => {
                let [hours, minutes] = time.match(/\d+/g).map(Number);
                return total + (hours * 60 + minutes);
            }, 0);

            let totalHours = Math.floor(totalMinutes / 60);
            let remainingMinutes = totalMinutes % 60;

            let combinedLayoverTime = `${totalHours.toString().padStart(2, "0")} h:${remainingMinutes.toString().padStart(2, "0")} min`;
            return combinedLayoverTime
        },

        depfligtTime(data) {
            console.log(data, 'ppppppppp')
            let $event = data
            let layoverTime = []
            for (let i = 0; i <= $event.length - 1; i++) {
                // console.log($event[i], 'data.length - 1')


                const time1 = new Date(
                    `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
                );
                const time2 = new Date(
                    `${data[i].Departure.Date}T${data[i].Departure.Time}`
                );

                const diffInMilliseconds = Math.abs(time2 - time1);
                const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

                const hours = Math.floor(diffInMinutes / 60);
                const minutes = diffInMinutes % 60;

                layoverTime.push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`);

            }
            console.log(layoverTime, 'layoverTimelayoverTimelayoverTimelayoverTime')
            let totalMinutes = layoverTime.reduce((total, time) => {
                let [hours, minutes] = time.match(/\d+/g).map(Number);
                return total + (hours * 60 + minutes);
            }, 0);

            let totalHours = Math.floor(totalMinutes / 60);
            let remainingMinutes = totalMinutes % 60;

            let combinedLayoverTime = `${totalHours.toString().padStart(2, "0")} h:${remainingMinutes.toString().padStart(2, "0")} min`;
            return combinedLayoverTime
        },




        getlogo(data) {
            // console.log(data,'ppppppppppppp')
            this.logo = []
            for (let i = 0; i < data.length; i++) {
                if (!this.logo.includes(data[i].MarketingCarrier.logo)) {
                    this.logo.push(data[i].MarketingCarrier.logo)
                    break
                }

            }
            // console.log(this.logo,'logologologologo')
        },
        getlogo1(data) {
            // console.log(data,'ppppppppppppp')
            this.logo1 = []
            for (let i = 0; i < data.length; i++) {
                if (!this.logo1.includes(data[i].MarketingCarrier.logo)) {
                    this.logo1.push(data[i].MarketingCarrier.logo)
                    break
                }

            }
            // console.log(this.logo,'logologologologo')
        },
        getData() {
            let localData = JSON.parse(localStorage.getItem("rountData"));
            this.adult = localData.adult;
            this.child = localData.child;
            this.inf = localData.infrant;
            this.fromdate = moment(localData.dedate).format('ddd, MMM D, YYYY');
            this.todate = moment(localData.redate).format('ddd, MMM D, YYYY');
            this.city = localData.city;



            if (localData.from) {
                this.from = localData.from.split(" ");
                this.from.forEach((v, i) => {
                    if (i !== this.from.length - 1) {
                        this.fromname += v;
                        console.log(v, this.fromname, "tdtdtddtdttdd......");
                    }
                });
                this.fromcode = this.from[this.from.length - 1]
                    .replace("(", "")
                    .replace(")", "");
            }



            if (localData.to) {
                this.to = localData.to.split(" ");
                this.to.forEach((v, i) => {
                    if (i !== this.to.length - 1) {
                        this.toname += v;
                        console.log(v, this.toname, "ttsdtsdyusdsdsjd.....");
                    }
                });

                this.tocode = this.to[this.to.length - 1]
                    .replace("(", "")
                    .replace(")", "");
            }


            // ------------->Check this Function<--------------
            if (localData.from.name) {
                this.from = localData.from.name.split(" ");
                this.from.forEach((v, i) => {
                    if (i !== this.from.length - 1) {
                        this.fromname += v;
                    }
                });

                this.fromcode = this.from[this.from.length - 1]
                    .replace("(", "")
                    .replace(")", "");
            }



            if (localData.to.name) {
                this.to = localData.to.name.split(" ");
                this.tocode = this.to[this.to.length - 1]
                    .replace("(", "")
                    .replace(")", "");
            } else {
                this.to = localData.to.split(" ");
                this.tocode = this.to[this.to.length - 1]
                    .replace("(", "")
                    .replace(")", "");
            }
            // -------------><--------------


            console.log(this.fromcode, " this.from");
            console.log(this.tocode, "  this.to");
            console.log(localData, "localData");
        },


        getadult() {
            this.rountresult.forEach((v) => {
                this.totalAmountpassenger = parseFloat(v.TotalAmount);
                this.totalTaxPassanger = parseFloat(v.Taxprice);
                this.totalbasePassanger = parseFloat(v.price);
                console.log(v.price, "pppppppppppppppp");
                console.log(typeof this.TotalAmount, "this.TotalAmount");
            });
        },

        onewayData() {
            this.onewayresult.forEach((v) => {
                this.totalAmountpassenger = parseFloat(v.TotalAmount);
                this.totalTaxPassanger = parseFloat(v.Taxprice);
                this.totalbasePassanger = parseFloat(v.price);
                console.log(v.price, "pppppppppppppppp");
                console.log(typeof this.TotalAmount, "this.TotalAmount");
            });
        },

        getprice1() {
            let priceData = [];
            let rount = JSON.parse(localStorage.getItem("itineryData"));
            let oneway = JSON.parse(localStorage.getItem("onewayItineyData"));
            if (rount) {
                priceData = rount;
            }
            if (oneway) {
                priceData = oneway;
            }
            this.basePrice = priceData.price;
            this.taxPrice = priceData.Taxprice;
            console.log(priceData, "priceData");
        },

        getprice(res) {
            return (res.price + res.Taxprice).toFixed(2);
        },

        getdepseat(data) {
            // console.log(data,'ppppppppppppppppppppppp')
            return data[0].Seat;
        },

        getretseat(data) {
            return data[0].Seat;
        },


        getname($event) {
            return $event[0].MarketingCarrier.Name;
        },

        getDate($event) {
            return moment($event[0].Departure.Date).format("MMM DD");
        },
        getAirportCode($event) {
            return $event[0].Departure.AirportCode;
        },

        getDepAirportName($event) {
            return $event[0].Departure.AirportName;
        },

        getReturnDepAirName($event) {
            return $event[0].Arrival.AirportName;
        },

        getArrAirportName($event) {
            return $event[$event.length - 1].Arrival.AirportName;
        },

        getReturnArrAirName($event) {
            return $event[0].Arrival.AirportName;
        },

        getDepStops($event) {
            return $event.length - 1;
        },

        getReturnStops($event) {
            return $event.length - 1;
        },

        // getDep1($event) {
        //     return $event[0].Departure.AirportCode;
        // },

        getarrivaldata($event) {
            return $event[0].Arrival.AirportName;
        },
        getreturndata($event) {
            return $event[0].Arrival.AirportName;
        },
        getarrtime($event) {
            return $event[0].Arrival.Time;
        },
        getreturntime($event) {
            return $event[0].Arrival.Time;
        },
        get1arrtime($event) {
            // console.log($event,index,'$event')

            return $event[$event.length - 1].Arrival.AirportName;
        },
        get1returntime($event) {
            return $event[$event.length - 1].Arrival.AirportName;
        },

        get2arrtime($event) {
            return $event[$event.length - 1].Arrival.Time;
        },

        get2returntime($event) {
            return $event[$event.length - 1].Arrival.Time;
        },
        getTime($event) {
            return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
        },
        getArrivalTime($event) {
            return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
                "HH:mm"
            );
        },
        getArrivaldate($event) {
            return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
        },

        getArrivalCode($event) {
            return $event[$event.length - 1].Arrival.AirportCode;
        },

        getreturnname($event) {
            return $event[0].MarketingCarrier.Name;
        },
        getreturnNumber($event) {
            return $event[0].MarketingCarrier.FlightNumber;
        },
        getreturnid($event) {
            return $event[0].MarketingCarrier.AirlineID;
        },
        getreturnDate($event) {
            return moment($event[0].Departure.Date).format("MMM DD");
        },
        getdeparDate($event) {
            return moment($event[0].Departure.Date).format("ddd MMM DD");
        },

        getdated($event) {
            return moment($event).format("ddd, MMM DD, YYYY");
        },
        getdated1($event) {
            return moment($event).format("ddd, MMM DD, YYYY");
        },
        getdated2($event) {
            return moment($event).format("ddd, MMM DD, YYYY");
        },
        getdated3($event) {
            return moment($event).format("ddd, MMM DD, YYYY");
        },
        getreturnAirportCode($event) {
            return $event[0].Departure.AirportCode;
        },
        getdeparAirportCode($event) {
            return $event[0].Departure.AirportCode;
        },

        getreturnTime($event) {
            return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
        },
        getreturnArrivalTime($event) {
            return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
                "HH:mm"
            );
        },
        getreturnArrivaldate($event) {
            return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
        },
        getreturnArrivalcode($event) {
            return $event[$event.length - 1].Arrival.AirportCode;
        },
        getdeparArrivalcode($event) {
            return $event[$event.length - 1].Arrival.AirportCode;
        },


        handleScroll() {
            if (
                this.lastPosition < window.scrollY &&
                this.limitPosition < window.scrollY
            ) {
                this.scrolled = true;
                this.topSticky = "5%";
            } else {
                this.scrolled = false;
                this.topSticky = "26%";
            }

            this.lastPosition = window.scrollY;
        },
        getRandomChars(length) {
            let result = '';
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * this.strResult.length);
                result += this.strResult[randomIndex];
            }
            console.log(result, 'result')
            return result;
        },
        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        generateBookingRefId() {
            const randomChars1 = this.getRandomChars(3);
            const randomNum = this.getRandomInt(10, 99);
            const randomChars2 = this.getRandomChars(3);

            this.bookinginiId = randomChars1 + randomNum + randomChars2;
            console.log(this.bookinginiId, this.userBooking, 'bookingRefIdbookingRefIdbookingRefId')
        },

        userbook() {

            this.userBooking.title = `BuyMyTrip CA - Booking Confirmation - ${this.bookinginiId}`;
            this.userBooking.body = `Trip Ref# ${this.bookinginiId}`;
            this.userBooking.booking_ref_id = this.bookinginiId
            this.userBooking.adult = this.adult
            this.userBooking.child = this.child
            this.userBooking.paxInfant = this.inf
            this.userBooking.itinstring = `${this.fromcode} - ${this.tocode} - ${this.tocode} - ${this.fromcode}`;
            this.userBooking.dateformto = this.fromdate,
                this.userBooking.dateto = this.todate
            this.userBooking.phoneno = this.billingDetails.phonenumber
            this.userBooking.bill_email = this.billingDetails.email
            this.userBooking.bill_phone = this.billingDetails.cvvnumber
            this.userBooking.card_name = this.billingDetails.cardholdername
            this.userBooking.card_number = this.billingDetails.cardnumber
            this.userBooking.card_number = this.billingDetails.cardnumber
            this.userBooking.getBookingRequestDatetime = this.todayDate
            console.log(this.rountresult, 'lplllplplpllplplplplplplp')
            let messDeparture = [];

            this.rountresult.forEach(s => {
                this.userBooking.DisplayTotalFare = `CAD ${s.TotalAmount}`
                this.userBooking.single_price = `CAD ${s.TotalAmount}`
                s.Depature.forEach(v => {
                    let departureObj = {
                        Depcode: v.Departure.AirportCode,
                        arrcode: v.Arrival.AirportCode,
                        Depdate: moment(v.Departure.Date).format('YYYY/MM/DD'),
                        Depflightid: v.MarketingCarrier.AirlineID,
                        Depflightnum: v.MarketingCarrier.FlightNumber,
                        deptime: moment(v.Departure.Time, "HH:mm:ss").format("HH:mm"),
                        arrtime: moment(v.Arrival.Time, "HH:mm:ss").format("HH:mm"),
                    };
                    messDeparture.push(departureObj);
                });
            });

            let messReturn = [];

            this.rountresult.forEach(s => {
                s.Return.forEach(v => {
                    let departureObj = {
                        Depcode: v.Departure.AirportCode,
                        arrcode: v.Arrival.AirportCode,
                        Depdate: moment(v.Departure.Date).format('YYYY/MM/DD'),
                        Depflightid: v.MarketingCarrier.AirlineID,
                        Depflightnum: v.MarketingCarrier.FlightNumber,
                        deptime: moment(v.Departure.Time, "HH:mm:ss").format("HH:mm"),
                        arrtime: moment(v.Arrival.Time, "HH:mm:ss").format("HH:mm"),
                    };
                    messReturn.push(departureObj);
                });
            });



            this.totalmsg = messDeparture.concat(messReturn)
            let amout = '';
            this.totalmsg.forEach((c) => {
                amout += `${c.Depcode}-${c.arrcode}-${c.Depdate}-${1}-${c.Depflightid}=>${c.Depflightnum}-${c.deptime}-${c.arrtime},`
            });
            this.userBooking.message = amout


            let passDeatail = JSON.parse(localStorage.getItem("TravelerDetails"))
            console.log(passDeatail, 'passDeatail')
            passDeatail.adultDatas.forEach(v => {
                this.userBooking.adultlastname.push(v.last)
                this.userBooking.adultfirstname.push(v.fname)
                this.userBooking.adultmiddlename.push(v.middle)
                this.userBooking.adultsalutation.push(v.salutation)
                this.userBooking.adultgender.push(v.genter)
                this.userBooking.pax_dob.push(moment(v.date).format('DD-MMM-YYYY'))
            })

            let adultDetailData = []
            for (let i = 0; i < passDeatail.adultDatas.length; i++) {

                adultDetailData.push(
                    {
                        "lastname": "", "firstname": "", "middlename": null, "salutation": "", "gender": "",
                        "dob": "", "pax_type": "Adult"
                    }
                )

            }
            adultDetailData.forEach((a, i) => {
                passDeatail.adultDatas.forEach((v, j) => {
                    if (i == j) {
                        a.lastname = v.last
                        a.firstname = v.fname
                        a.middlename = v.middle
                        a.salutation = v.salutation
                        a.gender = v.genter
                        a.dob = moment(v.date).format('DD-MMM-YYYY')
                    }
                })

            })

            adultDetailData.forEach(l => {
                this.userBooking.modulated_pax.push(l)
            })




            passDeatail.childDatas.forEach(v => {
                console.log(v, 'opopopopopopo')
                this.userBooking.childlastname.push(v.last)
                this.userBooking.childfirstname.push(v.fname)
                this.userBooking.childmiddlename.push(v.middle)
                this.userBooking.childsalutation.push(v.salutation)
                this.userBooking.childgender.push(v.genter)
                this.userBooking.childdob.push(moment(v.date).format('DD-MMM-YYYY'))
            })



            let childDetailData = []
            for (let i = 0; i < passDeatail.childDatas.length; i++) {

                childDetailData.push(
                    {
                        "lastname": "", "firstname": "", "middlename": null, "salutation": "", "gender": "",
                        "dob": "", "pax_type": "Adult"
                    }
                )

            }
            childDetailData.forEach((a, i) => {
                passDeatail.childDatas.forEach((v, j) => {
                    if (i == j) {
                        a.lastname = v.last
                        a.firstname = v.fname
                        a.middlename = v.middle
                        a.salutation = v.salutation
                        a.gender = v.genter
                        a.dob = moment(v.date).format('DD-MMM-YYYY')
                    }
                })

            })

            childDetailData.forEach(l => {
                this.userBooking.modulated_pax.push(l)
            })








            passDeatail.infrantDatas.forEach(v => {
                console.log(v, 'opopopopopopo')
                this.userBooking.infantlaplastname.push(v.last)
                this.userBooking.infantlapfirstname.push(v.fname)
                this.userBooking.infantlapmiddlename.push(v.middle)
                this.userBooking.infantlapsalutation.push(v.salutation)
                this.userBooking.infantlapgender.push(v.genter)
                this.userBooking.infantlapdob.push(moment(v.date).format('DD-MMM-YYYY'))
            })


            let infDetailData = []
            for (let i = 0; i < passDeatail.infrantDatas.length; i++) {

                infDetailData.push(
                    {
                        "lastname": "", "firstname": "", "middlename": null, "salutation": "", "gender": "",
                        "dob": "", "pax_type": "Adult"
                    }
                )

            }
            infDetailData.forEach((a, i) => {
                passDeatail.infrantDatas.forEach((v, j) => {
                    if (i == j) {
                        a.lastname = v.last
                        a.firstname = v.fname
                        a.middlename = v.middle
                        a.salutation = v.salutation
                        a.gender = v.genter
                        a.dob = moment(v.date).format('DD-MMM-YYYY')
                    }
                })

            })

            infDetailData.forEach(l => {
                this.userBooking.modulated_pax.push(l)
            })








        },
        submitForm() {

            this.passeData()
            this.generateBookingRefId()
            this.userbook()
            console.log(this.fromdate, 'this.tocodethis.tocode')
            if (this.valid) {
                axios.post("https://vtapi.wistirna.com/api/insertpaxbillandcard",
                    this.billingData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                    .then((response) => {
                        console.log(response, "billingDatabillingData");
                    });


                this.$router.push("/bookingCofirm");
                alert("thank you");
            } else {
                alert("please enter details  ");
            }

            // console.log(this.billingDetails, "qqqqqqqqqqqqqqqqqqqqqqqqqqq");
            // console.log(this.valid, "88888888888888888888888");
        },
        countryData() {

            countries.forEach(v => {
                this.countriesData.push(v)
            })
            console.log(this.countriesData, 'ppppppppp')

        },


    },

    computed: {
        dotColor() {
            return this.itineraryInfo ? "primary" : "success";
        },
        timeTextcolor() {
            return this.itineraryInfo ? "blue" : "black";
        },

        //     expDate() {
        //   return [
        //     (v) => !!v || 'This field is required',
        //     (v) => this.isMMYYFormat(v) || 'Invalid format (MM / YY)',
        //   ];
        // },
    },
    watch: {

        'billingDetails.country'(newVal) {
            let result = []
            this.countriesData.forEach(v => {
                if (v.name == newVal) {
                    result = v.code
                }
            })
            Object.keys(state).forEach(c => {
                if (c == result) {
                    this.stateData = state[c]
                    // console.log(state[c], 'pppppppppppppppppppppppppp');
                }
            });

            // console.log(newVal,'newValnewValnewVal')
            // console.log(result,'newValnewValnewVal')

        },




        'billingDetails.cvvnumber'(newValue) {
            if (newValue.length > 3) {
                this.billingDetails.cvvnumber = newValue.slice(0, 3);
            }
        },
        'billingDetails.zipcode'(newValue) {
            if (newValue.length > 8) {
                this.billingDetails.zipcode = newValue.slice(0, 8);
            }
        },

        'billingDetails.phonenumber'(newValue) {
            if (newValue.length > 12) {
                this.billingDetails.phonenumber = newValue.slice(0, 12);
            }
        },
        'billingDetails.phonenumber1'(newValue) {
            if (newValue.length > 12) {
                this.billingDetails.phonenumber1 = newValue.slice(0, 12);
            }
        },
        'billingDetails.cardnumber'(newValue) {
            if (newValue.length > 16) {
                this.billingDetails.cardnumber = newValue.slice(0, 16);
            }
        },
    },

    created() {
        window.addEventListener("scroll", this.handleScroll);
        this.todayDate = moment(new Date(), 'YYYY-MMM-DD HH:mm').format('YYYY-MMM-DD HH:mm')
        console.log(this.valid, "88888888888888888888888");
        this.getData();
        this.getprice1();
        let passDeatail1 = JSON.parse(localStorage.getItem("passengerContact"))
        passDeatail1.forEach(v => {
            this.billingDetails.phonenumber = v.number
            this.billingDetails.email = v.email
        })
        let roundData = JSON.parse(localStorage.getItem("itineryData"));
        if (roundData) {
            this.rountresult.push(roundData);
            this.getadult();
            this.itineraryInfo = true;
        }

        let onewayData = JSON.parse(localStorage.getItem("onewayItineyData"));
        if (onewayData) {
            this.onewayresult.push(onewayData);
            this.onewayData();
            this.itineraryInfo1 = true;
        }

        let resul = [];
        resul = JSON.parse(localStorage.getItem("rountData"));
        console.log(resul, "resul");
        if (resul.from.name) {
            this.fromData = resul.from.name;
        } else {
            this.fromData = resul.from;
            console.log(this.fromData, '.....2......')
        }
        if (resul.to.name) {
            this.toData = resul.to.name;
        } else {
            this.toData = resul.to;
            console.log(this.toData, '.....4......')
        }
        this.adultLength = resul.adult;
        this.childLength = resul.child;
        this.infLength = resul.infrant;
        this.classed = resul.class;
    },

    mounted() {
        this.countryData()

    }


};
</script>
    
<style scoped>
* {
    font-family: system-ui, "-apple-system", "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif !important;
}

.v-card {
    overflow: unset;
    z-index: auto;
}

.v-tool {
    height: 50px;
    display: flex;
    justify-content: center;
}

>>>.dp__action_select {
    color: white !important;
    padding: 0px 7px;
}

>>>.dp__input {
    height: 40px;
    border-color: darkgray;
}

>>>.v-input__control {
    height: 40px;
}

>>>.v-field__field {
    height: 40px;
}

>>>.v-field__input {
    padding-top: 0 !important;
}

.vue-tel-input {
    height: 40px;
}


>>>.alternate-Details .v-input__control {
    height: 0px;
}

>>>.alternate-Details .v-input__details {
    display: none;
}

>>>.alternate-Details .v-selection-control {
    --v-selection-control-size: 22px;
}

>>>.alternate-Details .v-selection-control-group--inline {
    justify-content: end;
}

>>>.alternate-Details .v-selection-control__wrapper {
    font-size: 12px;
    width: 22px;
}


>>>.alternate-Details .v-label--clickable {
    font-size: 14px;
}

>>>.country-field .v-field--variant-outlined input {
    top: 32% !important;
}

.travel-view {
    text-decoration: underline;
    color: darkblue;
    cursor: pointer;
    font-weight: 600;
}

>>>.v-toolbar__content,
.v-toolbar__extension {
    align-items: center;
    height: 30px !important;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    transition: inherit;
    width: 100%;
}

/* Timeline  */

>>>.v-timeline-divider__dot--size-large {
    height: 22px;
    width: 56px;
}

>>>.v-timeline-divider__inner-dot {
    border-radius: 15px;
}

>>>.v-timeline-divider__inner-dot span {
    font-size: 11px;
    font-weight: 600;
}

/* Timeline End  */

/* >>>.v-card {
      position: inherit !important;
    } */

.f-size-16 {
    font-size: 16px;
}

.f-size-14 {
    font-size: 14px;
}

.f-size-13 {
    font-size: 13px;
}

.f-size-12 {
    font-size: 12px;
}

.f-size-11 {
    font-size: 11px;
}

.f-size-10 {
    font-size: 10px;
}

.f-size-9 {
    font-size: 9px;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.card-table {
    width: 80% !important;
}

>>>.v-timeline--vertical.v-timeline {
    grid-row-gap: 3px;
    height: auto !important;
}

.fare-summery {
    position: sticky;
    width: 100%;
    z-index: 1;
    transition: top 0.5s;
}

>>>.v-selection-control .v-label {
    white-space: normal;
    word-break: normal;
    height: 100%;
    width: 100%;
}

.v-input--density-default {
    --v-input-control-height: 53px;
}

.v-selection-control--density-default {
    --v-selection-control-size: 30px;
}

.font-weight {
    font-weight: 500;
}

.timeline-vertical {
    display: none;
}

.res-icon {
    border: 1px solid #05386d;
    position: relative;
    top: 42px;
    width: 92%;
}

.res-icon1 {
    float: right;
    color: #05386d;
    font-size: 18px;
    position: relative;
    top: 28px;
}

.tot-price {
    font-size: 26px;
    font-weight: 600;
}

>>>.continue-section .v-btn__content {
    font-size: 16px;
    font-weight: 500;
}

>>>.continue-section .v-btn__content {
    font-size: 16px;
    font-weight: 500;
}

@media only screen and (min-width: 481px) {
    .fare-show {
        display: none;
    }

    .continue-btn {
        display: none;
    }

    .terms-text {
        display: none;
    }
}

@media only screen and (max-width: 480px) {

    .aircode-name .f-size-16 {
        font-size: 12px;
    }

    .aircode-name .f-size-13 {
        font-size: 9px;
    }

    .airline-date .f-size-16 {
        font-size: 12px;
    }

    .airline-date .f-size-11 {
        font-size: 9px;
    }

    .fare-show {
        display: block;
    }

    .fare-summery {
        display: none;
    }

    .card-space {
        margin: 2px;
    }

    .get-logo {
        width: 25px !important;
    }

    .air-name {
        font-size: 9px !important;
    }

    .air-id {
        font-size: 7px !important;
    }

    .airline-logo {
        width: 15px !important;
    }
}

@media only screen and (max-width: 768px) {

    .Timing-section .v-icon {
        font-size: 14 !important;
    }

    .Timing-section .f-size-14 {
        font-size: 11 !important;
    }

    .Timing-section .f-size-12 {
        font-size: 9 !important;
        line-height: normal !important;
    }

    .airline-logo {
        width: 20px !important;
    }

    .timeline-horizontal {
        display: none;
    }

    .timeline-vertical {
        display: inline-grid;
        height: 420px;
    }

    .content-overflow {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .content-overflow table {
        width: 100%;
        min-width: 750px;
        /* Adjust this value according to your table's content */
    }

    .card-table {
        width: 100% !important;
    }

    .air-name {
        font-size: 12px;
    }

    .air-id {
        font-size: 9px;
    }
}

/* Scroll Bar Styles */

.content-overflow::-webkit-scrollbar {
    width: 20px;
}

.content-overflow::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 25px;
}

/* minimalist design */
.content-overflow::-webkit-scrollbar {
    width: 10px;
}

.content-overflow::-webkit-scrollbar-track {
    background: rgb(179, 177, 177);
    border-radius: 10px;
}

.content-overflow::-webkit-scrollbar-thumb {
    background: rgb(136, 136, 136);
    border-radius: 10px;
}

/* striped lines */
.content-overflow::-webkit-scrollbar {
    width: 20px;
}

.content-overflow::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}

/* animated  */
.content-overflow::-webkit-scrollbar {
    width: 8px !important;
}

.content-overflow::-webkit-scrollbar-track {
    background: darkblue;
}

.content-overflow::-webkit-scrollbar-thumb {
    background: lightgray;
}

/* Scroll Bar Styles End */
</style>
    