<template>
  <div v-if="bookingShort">
    <v-card max-width="700px" class="booking-card m-auto p-3" v-for="(data, index) of paxContacts" :key="index">
      <div class="booking-title d-flex mb-4">
        <v-icon size="35px">mdi-check-circle</v-icon>
        <h3 class="ps-2">Thanks For Your Booking</h3>
      </div>
      <p class="booking-subtitle">Order number is <span>O797832</span></p>
      <div class="booking-text">
        <p class="m-0">Your order is processing, confirmation will be sent via email to {{ data.email }}</p>
        <p>Visit <span @click="bookingDetils = true, bookingShort = false" class="book-span">Booking Details</span> to
          track
          status of your booking</p>
      </div>


      <div class="row m-0 p-3 booking-contacts">
        <div class="col-sm-6">
          <h5>Contact</h5>
          <span>{{ data.email }}, {{ data.number }}</span>
        </div>

        <div class="col-sm-6">
          <h5>Billing address</h5>
          <span>Streeet 33, Toronto, M4234S</span>
        </div>
      </div>
    </v-card>
  </div>




  <!-- <div class="row d-flex justify-content-between p-2 ps-4 w-100 bg-primary">
        <div class="col-12 col-md-2 d-flex align-center justify-center py-2">
          <v-img
            :style="{ 'max-width': '180px', 'background-color': 'white' }"></v-img>
        </div>
        <div class="col-12 col-md-5 d-flex justify-end align-center py-2">
          <v-btn class="header-btn" prepend-icon="mdi-email">Mail</v-btn>

          <v-btn class="header-btn" prepend-icon="mdi-download-multiple">Download</v-btn>
          <v-btn class="header-btn" prepend-icon="mdi-printer" @click="seleceprint()">Print</v-btn>
        </div>
      </div> -->

  <!-- <h2 class="text-center text-success">Booking Confirmation</h2>
      <div class="pt-4">

        <div class="row mt-4">
          <div class="col-1 check-icon">
            <v-icon color="success" icon="mdi-check-circle-outline" size="80">
            </v-icon>
          </div>
          <div class="col-10 ps-3 my-auto">
            <h3>Congratulations! Your booking is Done</h3>
            <p class="m-0">
              Your booking request is confirmed and ticket issuance is in process.
              Upon confirmation of the tickets, e-ticket will be mailed to you.
            </p>
          </div>
        </div>

        <div>
          <v-card class="px-2 mt-3">
            <div class="row p-3 d-flex align-center">
              <div class="col-sm-6 col-12 book-detail">
                <div class="p-3 d-flex align-center">
                  <span class="custom-icon me-3">
                    <v-icon size="25" color="warning">mdi-file-document-outline</v-icon>
                  </span>
                  <span style="font-size: 20px; font-weight: 600">Booking Details</span>
                </div>
              </div>
              <div class="col-sm-6 col-12 book-detail" align="end">
                <span style="font-size: 16px; font-weight: 600">Booked on {{ todayDate }} <span> (UTC)*</span></span>
              </div>
              <div class="col-sm-4 col-12 book-detail">
                <span style="font-size: 16px; font-weight: 600">PNR : <span class="text-success"> FKCXZW</span></span>
              </div>
              <div class="col-sm-4 col-12 book-detail">
                <span style="font-size: 16px; font-weight: 600">Booking Req ID :
                  <span class="text-success"> 2C9367CC</span></span>
              </div>

              <div class="col-sm-4 col-12 book-detail">
                <span style="font-size: 16px; font-weight: 600">No. of Passengers : <span class="text-success">
                    1</span></span>
              </div>
              <div class="col-sm-4 col-12 book-detail">
                <span style="font-size: 16px; font-weight: 600">Cabin : <span class="text-success"> Economy</span></span>
              </div>
              <div class="col-sm-4 col-12 book-detail">
                <span style="font-size: 16px; font-weight: 600">Trip Type : <span class="text-success"> Round
                    Trip</span></span>
              </div>
            </div>
          </v-card>
        </div>
      </div> -->

  <v-container>
  <v-container>
    <div v-if="bookingDetils">
      <div class="mt-4">
        <v-card class="mt-3 p-3">
          <div>
            <span class="Booking-id-head">SkyNfly Booking ID:</span>
            <div class="d-flex align-center mb-4">
              <span class="Booking-id">O797832</span>
              <v-icon class="ms-4 me-2" size="12px" color="warning">mdi-circle</v-icon>
              <span style="font-size: 13px;">PROCESSING</span>
            </div>
            <h5>Your Booking Has Been Received!</h5>
            <!-- <p style="font-size: 14px;">Thank you for choosing our service! Your booking is now in progress, and your
              e-tickets will arrive in your email shortly.
            </p> -->
            <p style="font-size: 14px;">Thank you for choosing our service! Your booking has been received and the reservation is processing.<br /> 
              Soon your tickets will be issued and sent to your email.</p>
          </div>
        </v-card>
      </div>






      <div class="mt-3">
        <v-card class="px-2">
          <div v-for="(data, index) of roundresult" :key="index">
            <div v-if="itineraryInfo">
              <div class="row mt-2">
                <h3 class="col-12 col-md-6">Itinerary Information</h3>
              </div>

              <div class="mt-1">
                <v-card class="mt-4 px-2">
                  <div class="row d-flex justify-content-between align-center pt-2 pb-2"
                    style="background-color: #edf6fd">
                    <div class="col-md-2 col-12">
                      <h5 class="m-0">
                        {{ getdepcode(data.Depature) }} -

                        {{ getarrcode(data.Depature) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-12">
                      <h6 class="m-0">{{ depdate(data.Depature) }}</h6>
                    </div>
                    <div class="col-md-2 col-12">
                      <span>{{ getStop(data.Depature) }} Stops</span>
                    </div>
                    <div class="col-md-5 col-12" align="end">
                      <span style="font-size: 14px">Trip Duration {{ data.Depaturejourney.Time }}</span>
                    </div>
                  </div>

                  <v-container style="max-width: 100%">
                    <div v-for="(data1, index) of data.Depature" :key="index">
                      <div class="row mt-3 g-0 mb-2">
                        <div class="col-md-3 col-3 mb-3 mb-md-0">
                          <div class="row ms-sm-1 ms-0 w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-size-13 airline-name m-0">
                                {{ data1.MarketingCarrier.Name }}

                                <br />
                                <span class="f-size-12 airline-id">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-7 col-9">
                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{
                                getdated(data1.Departure.Date)
                              }}</span>
                              <span class="f-size-13 airline-time" style="margin-left: 5px">{{ getflightdep(data1)
                              }}</span>
                            </div>
                            <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{ data1.Departure.AirportCode }} </span>,
                              {{ data1.Departure.AirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{
                                getdated1(data1.Arrival.Date)
                              }}</span>
                              <span class="f-size-13 airline-time" style="margin-left: 5px">{{ getflightarr(data1)
                              }}</span>
                            </div>

                            <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{
                                data1.Arrival.AirportCode
                              }}</span>
                              , {{ data1.Arrival.AirportName }}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box">
                          <div class="d-flex class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-seat-passenger
                            </v-icon>

                            <span class="f-size-12" style="padding-left: 5px">
                              Economy
                            </span>
                          </div>

                          <div class="d-flex ms-md-0 ms-2 class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-food-fork-drink
                            </v-icon>

                            <span class="f-size-12" style="padding-left: 5px">
                              Meal provided
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex position-relative">
                        <hr v-if="data.Depature.length - 1 !== index"
                          style="width: 100%;position:absolute; margin-top: 8px; margin-bottom: 0px" />
                        <div class="d-flex justify-center" v-if="data.Depature.length - 1 !== index" 
                        style="width: 180px;background: antiquewhite;border-radius: 18px;z-index: 1;margin: auto;">
                          <div class="d-flex align-center">
                            <span class="f-size-10">{{ data1.layoverTimes }} Layover Time</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-card>
              </div>

              <!-- Return Flight Start -->

              <div class="mt-1">
                <v-card class="my-4 px-2">
                  <div class="row d-flex justify-content-between align-center pt-2 pb-2"
                    style="background-color: #edf6fd">
                    <div class="col-md-2 col-12">
                      <h5 class="m-0">
                        {{ getstart(data.Return) }} - {{ getend(data.Return) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-12">
                      <h6 class="m-0">{{ getstartdate(data.Return) }}</h6>
                    </div>
                    <div class="col-md-2 col-12">
                      <span>{{ getstop1(data.Return) }} Stops</span>
                    </div>
                    <div class="col-md-5 col-12" align="end">
                      <span style="font-size: 14px">Trip Duration {{ data.Depaturejourney.Time }}</span>
                    </div>
                  </div>
                  <v-container style="max-width: 100%">
                    <div v-for="(data1, index) of data.Return" :key="index">
                      <div class="row mt-3 g-0 mb-2">
                        <div class="col-md-3 col-3 mb-3 mb-md-0">
                          <div class="row ms-sm-1 ms-0 w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-size-13 airline-name m-0">
                                {{ data1.MarketingCarrier.Name }}

                                <br />
                                <span class="f-size-12 airline-id">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-7 col-9">
                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{
                                getdated(data.Return.Date)
                              }}</span>
                              <span class="f-size-13 airline-time" style="margin-left: 5px">{{ getflightdep(data1)
                              }}</span>
                            </div>
                            <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{ data1.Departure.AirportCode }} </span>,
                              {{ data1.Departure.AirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{
                                getdated1(data1.Arrival.Date)
                              }}</span>
                              <span class="f-size-13 airline-time" style="margin-left: 5px">{{ getflightarr(data1)
                              }}</span>
                            </div>

                            <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{
                                data1.Arrival.AirportCode
                              }}</span>
                              , {{ data1.Arrival.AirportName }}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box">
                          <div class="d-flex class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-seat-passenger
                            </v-icon>

                            <span class="f-size-12" style="padding-left: 5px">
                              Economy
                            </span>
                          </div>

                          <div class="d-flex ms-md-0 ms-2 class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-food-fork-drink
                            </v-icon>

                            <span class="f-size-12" style="padding-left: 5px">
                              Meal provided
                            </span>
                          </div>
                        </div>
                      </div>


                      <div class="d-flex position-relative">
                        <hr v-if="index !== data.Return.length - 1"
                          style="width: 100%;position:absolute; margin-top: 8px; margin-bottom: 0px" />
                        <div class="d-flex justify-center" v-if="data.Depature.length - 1 !== index" 
                        style="width: 180px;background: antiquewhite;border-radius: 18px;z-index: 1;margin: auto;">
                          <div class="d-flex align-center">
                            <span class="f-size-10">{{ data1.layoverTimes }} Layover Time</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </v-container>
                </v-card>
              </div>
              <!-- Return Flight End -->
              <!-- <div>
              <span
                @click="itinerary()"
                class="text-decoration-none text-info"
                style="cursor: pointer"
                >Itinerary Details</span
              >&nbsp; &nbsp; &nbsp;
              <span
                ><span
                  @click="baggage()"
                  class="text-decoration-none text-info"
                  style="cursor: pointer"
                  >Baggage Info</span
                ></span
              >
            </div> -->
            </div>
          </div>
        </v-card>
      </div>

      <div class="mt-3">
        <v-card class="px-2">
          <div>
            <div v-if="itineraryInfo">
              <div class="row mt-2">
                <h3 class="col-12 col-md-6">Passenger Details</h3>
              </div>

              <div class="my-4">
                <v-table class="text-center rounded" style="border: 1px solid lightgray">
                  <thead>
                    <tr style="background-color: aliceblue">
                      <th class="text-center text-dark">PASSENGER NAME</th>
                      <th class="text-center text-dark">GENDER</th>
                      <th class="text-center text-dark">DOB</th>
                      <th class="text-center text-dark">PAX TYPE</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(datas1, index) of paxAdultDatas" :key="index">
                      <td class="text-center text-dark">
                        {{ datas1.fname }} {{ datas1.last }}
                      </td>
                      <td class="text-center text-dark">{{ datas1.genter }}</td>
                      <td class="text-center text-dark">
                        {{ getdob(datas1.dateOfBirth) }}
                      </td>
                      <td class="text-center text-dark">{{ datas1.Type }}</td>
                    </tr>
                    <tr v-for="(datas2, index) of paxChildDatas" :key="index">
                      <td class="text-center text-dark">
                        {{ datas2.fname }} {{ datas2.last }}
                      </td>
                      <td class="text-center text-dark">{{ datas2.genter }}</td>
                      <td class="text-center text-dark">
                        {{ getdob(datas2.dateOfBirth) }}
                      </td>
                      <td class="text-center text-dark">{{ datas2.Type }}</td>
                    </tr>
                    <tr v-for="(datas3, index) of paxInfrantDatas" :key="index">
                      <td class="text-center text-dark">
                        {{ datas3.fname }} {{ datas3.last }}
                      </td>
                      <td class="text-center text-dark">{{ datas3.genter }}</td>
                      <td class="text-center text-dark">
                        {{ getdob(datas3.dateOfBirth) }}
                      </td>
                      <td class="text-center text-dark">{{ datas3.Type }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
            </div>
          </div>
        </v-card>
      </div>

      <div class="mt-3">
        <v-card class="px-2">
          <div v-for="(data, index) of paxContacts" :key="index">
            <div v-if="itineraryInfo">
              <div class="row mt-2">
                <h3 class="col-12 col-md-6">Order Summary</h3>
              </div>

              <div class="mt-2">
                <v-card class="my-4 px-2">
                  <div class="row mt-3">
                    <div class="col-md-4">
                      <span class="fw-bold">Phone : </span>
                      <span> {{ data.number }}</span>
                    </div>
                    <div class="col-md-4">
                      <span class="fw-bold">Email : </span>
                      <span> {{ data.email }}</span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-4 d-flex">
                      <span class="fw-bold">Ticket Price per person : </span>
                      <span class="ps-2">USD {{ this.totalAmountpassenger }}</span>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-4 d-flex">
                      <h4>Total Price : </h4>
                      <h4>USD {{ this.totalAmountpassenger }}</h4>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
  </v-container>
  <!-- <footerCom /> -->
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {

      bookingShort: true,
      bookingDetils: false,


      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "23%",
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      totalAmountpassenger: [],
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      clicked1: false,
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      detail1: [
        {
          number: "",
          email: "",
        },
      ],
      todayDate: "",
      userName: [(v) => !!v || "This field is required"],
      itineraryInfo: false,
      itineraryInfo1: false,
      payInfo: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infantLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      layoverTimes1: [],
      fareshow: false,
      flightList: [
        {
          departureDate: "Fri, Jul 30, 2023",
          flightName: "Air India",
          ticketNo: "AI-571/AI-131",
          fromTime: "06:50",
          toTime: "18:00",
          airportName: "Chennai (MAA)",
          fromPlace: "Chennai International Airport",
          toPlace: "Mumbai (BOM), Chhatrapati Shivaji International Airport",
          seatIcon: "mdi-seat-passenger",
          seatType: "Economy",
          foodIcon: "mdi-food-fork-drink",
          food: "Meal Provide",
          bagIcon: "mdi-bag-suitcase",
          baggage: "30kg (A)",
          refund: "Refundable",
          arrivalDate: "Fri, Jul 28, 2023",
        },
      ],
      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],

      firstName: [
        (v) => !!v || "Please enter your First Name",
        (v) => /^[a-zA-Z]+$/.test(v) || "Only alphabets are allowed",
      ],

      lastName: [
        (v) => !!v || "Please enter your Last Name",
        (v) => /^[a-zA-Z]+$/.test(v) || "Only alphabets are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) => /^[a-zA-Z]+$/.test(v) || "Only alphabets are allowed",
      ],

      passengerDetails: {
        paxlist: {
          hit_id: "ca-650580861718e5-39434893-9662",
          adultlastname: [],
          adultfirstname: [],
          adultmiddlename: [],
          adultsalutation: [],
          adultgender: [],
          dateAdt: [],
          childlastname: [],
          childfirstname: [],
          childmiddlename: [],
          childsalutation: [],
          childgender: [],
          dateChd: [],
          infantlaplastname: [],
          infantlapfirstname: [],
          infantlapmiddlename: [],
          infantlapgender: [],
          infantlapsalutation: [],
          dateInf: [],
          adulttotal: "1",
          childtotal: "0",
          infantlabtotal: "0",
          paxtotal: "1",
        },
        paxdetails: {
          hit_id: "ca-65058c29715224-51690699-4122",
          booking_ref_id: "D28X90V7",
          pax_info: "1a-1c-i-1l1a-1c-i-1l",
          pax_details: [],
          pax_details_corrected: [],
          pax_contact: [
            {
              countrycode: "+1",
              pax_phone: "0655656569",
              pax_email: "web.arishn@gmail.com",
            },
          ],
        },
      },
      adultDetails: [],
      childDetails: [],
      infDetails: [],
      adultDetails1: [],
      childDetails1: [],
      infDetails1: [],

      paxAdultDatas: [],
      paxChildDatas: [],
      paxInfrantDatas: [],
      paxContacts: [],
    };
  },
  methods: {
    seleceprint() {
      window.print()
    },

    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },

    getdepcode(data) {
      return data[0].Departure.AirportCode;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    depdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getstartdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    getdepname(data) {
      return data[0].Departure.AirportName;
    },
    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getarrname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },

    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    getdob($event) {
      return moment($event).format("YYYY-MMM-DD");
    },

    getpass() {
      let localData = JSON.parse(localStorage.getItem("rountData"));
      this.adult = localData.adult;
      this.child = localData.child;
      this.inf = localData.infrant;
    },

    getadult() {
      this.roundresult.forEach((v) => {
        this.totalAmountpassenger = parseFloat(v.TotalAmount);
        this.totalTaxPassanger = parseFloat(v.Taxprice);
        this.totalbasePassanger = parseFloat(v.price);
        console.log(v.price, "pppppppppppppppp");
        console.log(typeof this.TotalAmount, "this.TotalAmount");
      });
    },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(4);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(2);

      this.passengerDetails.paxdetails.booking_ref_id =
        randomChars1 + randomNum + randomChars2;
      console.log(
        this.passengerDetails.paxdetails.booking_ref_id,
        "bookingRefIdbookingRefIdbookingRefId"
      );
    },

    getPassDetails() {
      let Travelers = JSON.parse(localStorage.getItem("TravelerDetails"));
      let passengers = JSON.parse(localStorage.getItem("passengerContact"));

      this.paxAdultDatas = Travelers.adultDatas;
      this.paxChildDatas = Travelers.childDatas;
      this.paxInfrantDatas = Travelers.infrantDatas;
      this.paxContacts = passengers;

      console.log(this.paxAdultDatas, "TTTTTTT");
      console.log(this.paxChildDatas, "TTTTTTT");
      console.log(this.paxInfrantDatas, "TTTTTTT");

      console.log(this.paxContacts, "TTTTTTTppppppp");
    },
  },

  mounted() {
    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    console.log(this.dateOfBirth, "dobdob.....");
  },

  created() {
    this.getPassDetails();
    this.todayDate = moment(new Date(), "YYYY-MMM-DD HH:mm").format(
      "YYYY-MMM-DD HH:mm"
    );
    window.addEventListener("scroll", this.handleScroll);
    let resul = [];
    this.getpass();

    resul = JSON.parse(localStorage.getItem("rountData"));
    console.log(resul, "resul");
    if (resul.from.name) {
      this.fromData = resul.from.name;
    } else {
      this.fromData = resul.from;
    }
    if (resul.to.name) {
      this.toData = resul.to.name;
    } else {
      this.toData = resul.to;
    }
    this.adultLength = resul.adult;
    this.childLength = resul.child;
    this.infLength = resul.infrant;
    this.classed = resul.class;

    let roundData = JSON.parse(localStorage.getItem("itineryData"));
    if (roundData) {
      this.roundresult.push(roundData);
      this.getadult();
      this.itineraryInfo = true;
    }

    let onewayData = JSON.parse(localStorage.getItem("onewayItineyData"));
    if (onewayData) {
      this.onewayresult.push(onewayData);
      this.onewayData();
      this.itineraryInfo1 = true;
    }
    console.log(this.roundresult, "roundresult");

    this.fareItems.push(JSON.parse(localStorage.getItem("rounttrip")));
    console.log(this.fareItems, "fare.....");
  },
};
</script>

<style scoped>
/* .booking-card{
  font-family: sans-serif;
} */




.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}


.fw-500 {
  font-weight: 500;
}




.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}




/* .booking-text p{
  font-size: 17px;
  font-weight: 400;
} */

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}





.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}





.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

/* Departure & Return Section */

@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 480px) {
  .f-size-16 {
    font-size: 16px;
  }

  .f-size-14 {
    font-size: 14px;
  }

  .f-size-13 {
    font-size: 13px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  .f-size-11 {
    font-size: 11px;
  }

  .f-size-10 {
    font-size: 10px;
  }

  .f-size-9 {
    font-size: 10px;
  }

  .fw-500 {
    font-weight: 500;
  }
}
</style>
