<template>
  <v-container>
    <v-form ref="form" v-model="valid1" @submit.prevent="submitform">

      <div class="row" style="margin-top:70px;">

        <div class="col-md-12">
          <v-timeline direction="horizontal" line-inset="12">
            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-magnify</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-airplane</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="primary">
              <template v-slot:icon>
                <v-icon color="white">mdi-account</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="grey">
              <template v-slot:icon>
                <v-icon color="white" size="20">mdi-cash-multiple</v-icon>
              </template>
            </v-timeline-item>
          </v-timeline>
        </div>

        <div class="col-lg-9 col-md-9 col-sm-12 mx-0 px-0">
          <v-container fluid>
            <div class="fare-show">


              <v-card rounded="2" max-width="400px" max-height="500px" class="p-2 m-auto">

                <div class="d-flex justify-content-between">
                  <div>
                    <p class="m-0" style="font-size:26px; font-weight: 500;">Total Price</p>
                  </div>
                  <div>
                    <p class="tot-price">USD {{ totalAmountpassenger }}</p>
                  </div>
                </div>



                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="row justify-content-between">
                      <div class="col-md-6">
                        <span style="font-size: 14px; font-weight: 500">Passengers</span>
                      </div>
                      <!-- <div class="col-md-4"></div> -->
                      <div class="col-md-6" style="font-size: 14px">
                        <span v-if="adultLength > 0">{{ adultLength }} x Adult</span><br />
                        <span v-if="childLength > 0">{{ childLength }} x Child</span><br />
                        <span v-if="infLength > 0">{{ infLength }} x Lap Infant</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <hr class="mt-1 mb-2" />
                  <div class="d-flex align-end mb-2">


                    <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example" aria-expanded="true"
                      aria-controls="collapse-Example"
                      style="padding-right:5px; cursor:pointer;font-size: 14px;letter-spacing: 1px">
                      <a href="#">Fare Summary</a></span>

                    <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                      aria-expanded="true" aria-controls="collapse-Example" v-model="isIcon"
                      style="color:0D6EFD; cursor:pointer; overflow-anchor: none">
                      {{ isIcon ? "mdi-chevron-up-box-outline" : "mdi-chevron-down-box-outline" }}</v-icon>
                  </div>

                  <hr class="mt-1 mb-2" />

                  <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example">

                    <div class="row">
                      <div class="col-6">
                        Base Price<br />
                        Taxes & Fees
                      </div>
                      <div class="col-6" align="center">
                        {{ totalbasePassanger }}<br />{{ totalTaxPassanger }}
                      </div>
                    </div>

                  </div>

                  <br>
                  <p style="font-size: 12px">
                    All fares are quoted in USD .Some airlines may charge bag gage fees.
                    Your credit/debit card may be billed in multiple charges totaling
                    the final total price.
                  </p>


                </div>


              </v-card>


            </div>

            <div v-for="(data, index) of roundresult" :key="index">
              <v-card class="p-3 card-space" v-if="itineraryInfo">
                <div class="row">
                  <h3 class="col-12 col-md-6">Itinerary Information</h3>
                </div>

                <div class="mt-2">
                  <v-card>
                    <div class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                      style="background-color: #edf6fd">
                      <div class="col-md-2 col-4">
                        <h5 class="m-0 font-change-14">
                          {{ getdepcode(data.Depature) }} -

                          {{ getarrcode(data.Depature) }}
                        </h5>
                      </div>
                      <div class="col-md-3 col-5">
                        <h6 class="m-0 font-change-11">{{ depdate(data.Depature) }}</h6>
                      </div>
                      <div class="col-md-2 col-3 ">
                        <span class="font-change-14">{{ getStop(data.Depature) }} Stops</span>
                      </div>
                      <div class="col-md-4 col-10">
                        <span class="f-size-14 font-change-11">Trip Duration {{ data.Depaturejourney.Time }}</span>
                      </div>
                      <div class="col-md-1 p-0 col-2">
                        <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
                      </div>
                    </div>

                    <div class="px-2" style="max-width: 100%">
                      <div class="row mt-3 mb-2" v-for="(data1, index) of data.Depature" :key="index">

                        <div class="col-md-3 col-3 mb-3 mb-md-0">

                          <div class="row ms-sm-1 ms-0 w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-size-13 airline-name m-0">
                                {{ data1.MarketingCarrier.Name }}

                                <br />
                                <span class="f-size-12 airline-id">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>



                        <div class="col-md-7 col-9">

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{ getdated(data1.Departure.Date) }}</span>
                              <span class="f-size-13 airline-time" style="margin-left:5px;">{{ getflightdep(data1)
                              }}</span>
                            </div>
                            <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px;">
                              <span class="fw-500">{{ data1.Departure.AirportCode }} </span>, {{
                                data1.Departure.AirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{ getdated1(data1.Arrival.Date) }}</span>
                              <span class="f-size-13 airline-time" style="margin-left:5px;">{{ getflightarr(data1)
                              }}</span>
                            </div>

                            <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px;">
                              <span class="fw-500">{{ data1.Arrival.AirportCode }}</span> , {{
                                data1.Arrival.AirportName
                              }}
                            </div>
                          </div>
                        </div>



                        <div class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box">
                          <div class="d-flex class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-seat-passenger
                            </v-icon>

                            <span class="f-size-12" style="padding-left:5px;">
                              Economy
                            </span>
                          </div>

                          <div class="d-flex ms-md-0 ms-2 class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-food-fork-drink
                            </v-icon>

                            <span class="f-size-12" style="padding-left:5px;">
                              Meal provided
                            </span>
                          </div>
                        </div>





                        <div class="d-flex justify-content-between align-center">
                          <hr v-if="data.Depature.length - 1 !== index" style=" width: 40%;" class="mt-0 mb-0" />
                          <div class="d-flex justify-center mx-2 layover-size" v-if="data.Depature.length - 1 !== index"
                            style="width: 20%;">
                            <div class="d-flex align-center">
                              <span class="f-size-11 lay-fsize"> {{ data1.layoverTimes }} Layover Time</span>
                            </div>
                          </div>
                          <hr v-if="data.Depature.length - 1 !== index" style=" width: 40%;" class="mt-0 mb-0" />
                        </div>
                      </div>
                    </div>
                  </v-card>


                </div>










                <!-- Return Flight Start -->

                <div class="mt-3">
                  <v-card>
                    <div class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                      style="background-color: #edf6fd">
                      <div class="col-md-2 col-4">
                        <h5 class="m-0 font-change-14">
                          {{ getstart(data.Return) }} - {{ getend(data.Return) }}
                        </h5>
                      </div>
                      <div class="col-md-3 col-5">
                        <h6 class="m-0 font-change-11">{{ getstartdate(data.Return) }}</h6>
                      </div>
                      <div class="col-md-2 col-3">
                        <span class="font-change-14">{{ getstop1(data.Return) }} Stops</span>
                      </div>
                      <div class="col-md-4 col-10">
                        <span class="f-size-14  font-change-11">Trip Duration {{ data.Returnjourney.Time }}</span>
                      </div>
                      <div class="col-md-1 col-2 p-0">
                        <a href="/search" class="text-decoration-none f-size-14  font-change-11">Change</a>
                      </div>
                    </div>


                    <div class="px-2" style="max-width: 100%">
                      <div v-for="(data1, index) of data.Return" :key="index">

                        <div class="row mt-3 mb-2">
                          <div class="col-md-3 col-3 mb-3 mb-md-0">

                            <div class="row ms-sm-1 ms-0 w-100">
                              <div class="col-md-4 col-12 airline-logo">
                                <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                              </div>
                              <div class="col-md-8 col-12 airline-profile">
                                <p class="f-size-13 airline-name m-0">
                                  {{ data1.MarketingCarrier.Name }}

                                  <br />
                                  <span class="f-size-12 airline-id">
                                    {{ data1.MarketingCarrier.AirlineID }} -
                                    {{ data1.MarketingCarrier.FlightNumber }}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>



                          <div class="col-md-7 col-9">

                            <div class="row d-flex">
                              <div class="col-sm-3 p-0">
                                <span class="f-size-10 airline-date">{{ getdated(data1.Departure.Date) }}</span>

                                <span class="f-size-13 airline-time" style="margin-left:5px;">{{ getflightdep(data1)
                                }}</span>
                              </div>
                              <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px;">
                                <span class="fw-500">{{ data1.Departure.AirportCode }} </span>, {{
                                  data1.Departure.AirportName }}
                              </div>
                            </div>

                            <div class="row d-flex">
                              <div class="col-sm-3 p-0">
                                <span class="f-size-10 airline-date">{{ getdated1(data1.Arrival.Date) }}</span>
                                <span class="f-size-13 airline-time" style="margin-left:5px;">{{ getflightarr(data1)
                                }}</span>
                              </div>

                              <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px;">
                                <span class="fw-500">{{ data1.Arrival.AirportCode }}</span> , {{
                                  data1.Arrival.AirportName
                                }}
                              </div>
                            </div>
                          </div>



                          <div class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box">
                            <div class="d-flex class-type">
                              <v-icon size="15px" color="#ccc">
                                mdi-seat-passenger
                              </v-icon>

                              <span class="f-size-12" style="padding-left:5px;">
                                Economy
                              </span>
                            </div>

                            <div class="d-flex ms-md-0 ms-2 class-type">
                              <v-icon size="15px" color="#ccc">
                                mdi-food-fork-drink
                              </v-icon>

                              <span class="f-size-12" style="padding-left:5px;">
                                Meal provided
                              </span>
                            </div>
                          </div>
                        </div>





                        <div class="d-flex justify-content-between align-center">
                          <hr v-if="index !== data.Return.length - 1" style=" width: 40%;" class="mt-0 mb-0" />
                          <div class="d-flex justify-center mx-2 layover-size" v-if="index !== data.Return.length - 1"
                            style="width: 20%;">
                            <div class="d-flex align-center">
                              <span class="f-size-11 lay-fsize"> {{ data1.layoverTimes }} Layover Time</span>
                            </div>
                          </div>
                          <hr v-if="index !== data.Return.length - 1" style=" width: 40%;" class="mt-0 mb-0" />
                        </div>

                      </div>
                    </div>
                  </v-card>

                </div>
                <!-- Return Flight End -->
                <!-- <div>
              <span
                @click="itinerary()"
                class="text-decoration-none text-info"
                style="cursor: pointer"
                >Itinerary Details</span
              >&nbsp; &nbsp; &nbsp;
              <span
                ><span
                  @click="baggage()"
                  class="text-decoration-none text-info"
                  style="cursor: pointer"
                  >Baggage Info</span
                ></span
              >
            </div> -->
              </v-card>
            </div>

            <div class="mt-5" v-for="(data, index) of onewayresult" :key="index">
              <div>
                <div class="mt-1 p-2">

                  <div class="row d-flex justify-content-between align-center pt-2 pb-2"
                    style="background-color: #edf6fd">
                    <div class="col-12 col-md-2">
                      <h5 class="m-0">
                        {{ getdepcode(data.Depature) }} -

                        {{ getarrcode(data.Depature) }}
                      </h5>
                    </div>
                    <div class="col-12 col-md-3">
                      <h6 class="m-0">{{ depdate(data.Depature) }}</h6>
                    </div>
                    <div class="col-12 col-md-2">
                      <span>{{ getStop(data.Depature) }} Stops</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span style="font-size:14px">Trip Duration {{ data.Depaturejourney.Time }}</span>
                    </div>
                    <div class="col-12 col-md-1 p-0">
                      <a href="/search" class="text-decoration-none" style="font-size:14px">Change</a>
                    </div>
                  </div>



                  <div>

                    <v-card class="mt-4">
                      <div>
                        <div class="d-flex justify-content-between"
                          style="font-size: 15px;font-weight: 500;background-color: #e8f0fd;padding: 5px;">
                          <span>DEPARTURE</span>


                          <span>
                            Total Trip Duration :
                            {{ data.Depaturejourney.Time }}</span>
                        </div>
                      </div>

                      <v-container style="max-width: 100%">
                        <div v-for="(data1, index) of data.Depature" :key="index">



                          <div class="row mt-3 g-0 mb-2">
                            <div class="col-md-3 col-3 mb-3 mb-md-0">

                              <div class="row ms-sm-1 ms-0 w-100">
                                <div class="col-md-4 col-12 airline-logo">
                                  <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                </div>
                                <div class="col-md-8 col-12 airline-profile">
                                  <p class="f-size-13 airline-name m-0">
                                    {{ data1.MarketingCarrier.Name }}

                                    <br />
                                    <span class="f-size-12 airline-id">
                                      {{ data1.MarketingCarrier.AirlineID }} -
                                      {{ data1.MarketingCarrier.FlightNumber }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>



                            <div class="col-md-7 col-9">

                              <div class="row d-flex">
                                <div class="col-sm-3 p-0">
                                  <span class="f-size-10 airline-date">{{ getdated(data1.Departure.Date) }}</span>
                                  <span class="f-size-13 airline-time" style="margin-left:5px;">{{ getflightdep(data1)
                                  }}</span>
                                </div>
                                <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px;">
                                  <span class="fw-500">{{ data1.Departure.AirportCode }} </span>, {{
                                    data1.Departure.AirportName }}
                                </div>
                              </div>

                              <div class="row d-flex">
                                <div class="col-sm-3 p-0">
                                  <span class="f-size-10 airline-date">{{ getdated1(data1.Arrival.Date) }}</span>
                                  <span class="f-size-13 airline-time" style="margin-left:5px;">{{ getflightarr(data1)
                                  }}</span>
                                </div>

                                <div class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0" style="margin-bottom: 4px;">
                                  <span class="fw-500">{{ data1.Arrival.AirportCode }}</span> , {{
                                    data1.Arrival.AirportName
                                  }}
                                </div>
                              </div>
                            </div>



                            <div class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box">
                              <div class="d-flex class-type">
                                <v-icon size="15px" color="#ccc">
                                  mdi-seat-passenger
                                </v-icon>

                                <span class="f-size-12" style="padding-left:5px;">
                                  Economy
                                </span>
                              </div>

                              <div class="d-flex ms-md-0 ms-2 class-type">
                                <v-icon size="15px" color="#ccc">
                                  mdi-food-fork-drink
                                </v-icon>

                                <span class="f-size-12" style="padding-left:5px;">
                                  Meal provided
                                </span>
                              </div>
                            </div>
                          </div>


                          <!-- <div class="d-flex justify-content-between align-center">
                            <hr v-if="data.Depature.length - 1 !== index"
                              style=" width: 40%;" class="mt-0 mb-0" />
                            <div class="d-flex justify-center mx-2 layover-size" v-if="data.Depature.length - 1 !== index"
                              style="width: 20%;">
                              <div class="d-flex align-center">
                                <span>{{ getlayoverdep(data.Depature) }}
                                  <span v-for="(data, index1) of layoverTimes" :key="index1">
                                    <span class="f-size-11 lay-fsize" v-if="index == index1"> {{ data }}Layover Time </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <hr v-if="data.Depature.length - 1 !== index"
                              style=" width: 40%;" class="mt-0 mb-0" />
                          </div> -->



                        </div>
                      </v-container>
                    </v-card>
                  </div>

                </div>




















                <!-- <v-card class="mt-4">
                <div class="row" style="background-color: #0b446d">
                  <h6 class="col-11" style="
                          text-align: center;
                          padding: 12px 0px;
                          color: white;
                          margin: 0px;
                        ">
                    <span>Flight Details: </span>
                    <span>Departure</span>
                    <span> | {{ getdeparDate(data.Depature) }}</span>
                    <span>
                      | {{ getdeparAirportCode(data.Depature) }} -
                      {{ getdeparArrivalcode(data.Depature) }}</span>
                  </h6>

                </div>
                <div class="pa-5">

                  <div style="
                        font-size: 15px;
                        font-weight: 500;
                        background-color: #e8f0fd;
                        padding: 5px;
                      ">
                    <span>onward</span>
                    <span style="margin-left: 10px">
                      Total Trip Duration :
                      {{ data.Depaturejourney.Time }}</span>
                  </div>
                </div>

                <v-container style="max-width: 100%">
                  <div v-for="(data1, index) of data.Depature" :key="index">
                    <v-timeline density="compact" side="end" truncate-line="both">
                      <v-timeline-item class="mb-4" dot-color="grey" size="15">
                        <div class="d-flex justify-space-between flex-grow-1">
                          <div>
                            <span style="font-size: 13px; font-weight: 500">
                              {{ getdated(data1.Departure.Date) }}
                            </span>
                          </div>
                        </div>
                      </v-timeline-item>

                      <v-timeline-item fill-dot class="mb-12" dot-color="info" size="large">
                        <template v-slot:icon>
                          <span class="small">{{
                            data1.FlightDetail.FlightDuration.Value
                          }}</span>
                        </template>
                        <div class="row g-2 mt-3">
                          <div class="col-md-1">
                            <v-img :src="data1.MarketingCarrier.logo" width="40px" height="40px"></v-img>
                          </div>
                          <div class="col-md-2 mt-2">
                            <p style="font-size: 14px; font-weight: 500">
                              {{ data1.MarketingCarrier.Name }}
                              <br />
                              {{ data1.MarketingCarrier.AirlineID }}
                              -
                              {{ data1.MarketingCarrier.FlightNumber }}
                            </p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="row">
                                <div class="col-md-3" align="center">
                                  <div style="font-size: 15px; font-weight: 600">
                                    {{ getflightdep(data1) }}
                                  </div>
                                </div>
                                <div class="col-md-9" style="font-size: 15px; font-weight: 500">
                                  {{ data1.Departure.AirportCode }} ,
                                  {{ data1.Departure.AirportName }}
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="col-md-3" align="center">
                                  <div style="font-size: 15px; font-weight: 600">
                                    {{ getflightarr(data1) }}
                                  </div>
                                </div>
                                <div class="col-md-9" style="font-size: 15px; font-weight: 500">
                                  {{ data1.Arrival.AirportCode }} ,
                                  {{ data1.Arrival.AirportName }}
                                </div>
                              </div>
                            </div>
                            <div class="row mt-4 gx-0">
                              <div class="col-md-5">
                                <div>
                                  <div class="ms-3">
                                    <span style="color: #ccc">
                                      <v-icon> mdi-food-fork-drink </v-icon>
                                    </span>
                                    <span style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        ">
                                      Meal provided
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="ms-3">
                                  <span style="color: #ccc">
                                    <v-icon> mdi-seat-passenger </v-icon>
                                  </span>
                                  <span style="font-size: 13px; font-weight: 500">
                                    Economy
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-timeline-item>

                      <v-timeline-item class="mb-4" dot-color="grey" size="15">
                        <div class="d-flex justify-space-between flex-grow-1">
                          <div>
                            <span style="font-size: 13px; font-weight: 500">
                              {{ getdated1(data1.Arrival.Date) }}
                            </span>
                          </div>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                    <div v-if="data.Depature.length - 1 !== index">
                      <div class="d-flex align-center m-2" style="background-color: antiquewhite">
                        <span class="ps-3 pt-1" style="font-family: serif">
                          {{ getlayoverdep(data.Depature) }}
                          <span v-for="(data, index1) of layoverTimes" :key="index1">
                            <span v-if="index == index1">
                              {{ data }}
                              <span style="font-size: 18px; margin-left: 8px">
                                Layover Time
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </v-container>
              </v-card> -->
              </div>
            </div>

            <div class="my-4" style="margin: 10px 12px">
              <div class="row">
                <div class="col-md-12 p-0">
                  <v-card>

                    <div class="row">
                      <div class="col-12 col-md-6">
                        <h3 class="pt-3 ps-3">Traveller Details</h3>
                      </div>
                    </div>


                    <div class="py-2 px-3">
                      <div class="row mt-2" v-for="(data, index) of adultData" :key="index">
                        <h6 style="color: blue">
                          Adult {{ index + 1 }}
                        </h6>

                        <div class="row">
                          <div class="col-lg-3">
                            <v-text-field label="First Name" variant="outlined" v-model="data.fname"
                              :rules="firstName"></v-text-field>
                          </div>

                          <div class="col-lg-3">
                            <v-text-field label="Middle Name" variant="outlined" v-model="data.middle"></v-text-field>
                          </div>

                          <div class="col-lg-3">
                            <v-text-field label="Last Name" variant="outlined" v-model="data.last"
                              :rules="lastName"></v-text-field>
                          </div>
                          <div class="col-lg-2">
                            <!-- <v-select label="Salutation" :items="['Mr', 'Miss', 'Mrs', 'Ms']" v-model="data.salutation"
                              variant="outlined"></v-select> -->
                            <v-select label="Salutation" :items="['Mr', 'Miss', 'Mrs', 'Ms']" v-model="data.salutation"
                              variant="outlined"></v-select>

                          </div>
                        </div>

                        <div class="row">

                          <div class="col-lg-3">
                            <!-- <v-select v-model="data.gender" label="Gender" :items="['Male', 'Female']" variant="outlined"
                              :rules="[(v) => !!v || 'this field is required']"></v-select> -->

                            <v-select v-model="data.gender" label="Gender" :items="['Male', 'Female']" variant="outlined"
                              :rules="[(v) => !!v || 'This field is required']"></v-select>
                          </div>

                          <div class="col-md-3">

                            <div class="col-md-12">
                              <span class="p-float-label">
                                <label class="dob-label" :class="data.clicked1 ? 'visible' : 'invisible'">Date of
                                  Birth</label>
                                <VueDatePicker v-model="data.dateOfBirth" ref="dpRef1" no-today :six-weeks="true"
                                  auto-apply
                                  :start-date="new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate())"
                                  :max-date="new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate())"
                                  placeholder="Date of Birth"
                                  :min-date="new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())"
                                  :format="dateFormat" :hide-navigation="['time']" @update:modelValue="adultDob()"
                                  class="px-0" />
                                <!-- <span class="p-error">Dep. Date is required</span> -->
                              </span>
                              <p v-if="dobAdultError" class="p-error px-4 m-0">Date of Birth is required</p>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="col-md-6 mt-2">
                            <v-btn-toggle class="d-flex align-center">
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="ffbdata(data, index)">
                                FFp
                                <v-tooltip activator="parent" location="top" width="200px">
                                  Please select an airline/frequent
                                </v-tooltip>
                              </v-btn>
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="tsadata(index)">
                                TSA
                                <v-tooltip activator="parent" location="top" width="200px">
                                  Please select an airline/frequent flyer program
                                </v-tooltip>
                              </v-btn>
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="apidata(index)">
                                APIS
                                <v-tooltip activator="parent" location="top" width="200px">
                                  the validating carrier are being displayed.
                                </v-tooltip>
                              </v-btn>
                            </v-btn-toggle>
                          </div> -->


                        <div>
                          <v-card class="ffb-cart" v-if="data.ffbnumber">
                            <div align="end">
                              <v-icon color="primary" @click="data.ffbnumber = false">
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Frequent Flyer Program</h6>

                            <div class="row mt-4">
                              <div class="col-md-3">
                                <v-select label="Frequent Flyer Program" rounted="5" v-model="air" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-text-field label="FFP Number" variant="outlined" v-model="ffb"></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn variant="outlined" color="primary" @click="reset()">
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart" v-if="data.TSAnumber">
                            <div align="end">
                              <v-icon color="primary" @click="data.TSAnumber = false">
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Transport Security Administration</h6>
                            <div class="row mt-4 gy-3">
                              <div class="col-lg-3">
                                <v-text-field label="Last name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="First name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="2nd and additional given names" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="Date of Birth" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <p class="redress">
                              Redress number and known traveller number
                              <span>
                                <v-icon @click="Redressnumber = !Redressnumber">mdi-menu-down</v-icon>
                              </span>
                            </p>

                            <div class="row mt-4 gy-3">
                              <div class="col-md-3">
                                <v-text-field v-if="Redressnumber" label="Redress Number" v-model="Redress"
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-text-field v-if="Redressnumber" label="Known Traveler Number" v-model="Traveler"
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn variant="outlined" color="primary" @click="reset1()">
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                            <h6>Advance Passenger Information System</h6>
                            <h6>Primary Data of the Travel Document</h6>
                            <div class="row mt-3 gy-3">
                              <div class="col-lg-3">
                                <v-text-field label="Last name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="First name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="2nd and additional given names    "
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-2">
                                <v-text-field label="Date Of Birth" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3">
                              <div class="col-3-lg">
                                <v-select label="Document Type" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-text-field label="Document Number" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-3-lg">
                                <v-select label="Issuance Country" rounted="5" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-select label="Nationality" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                            </div>

                            <div class="row mt-2 gy-3">
                              <div class="col-2-lg">
                                <v-text-field label="Issue Date" type="date" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-2-lg">
                                <v-text-field label="Expiration" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <p style="color: blue; cursor: pointer" @click="apivalue = !apivalue">
                              Passenger contact address (DOCA)
                              <span>
                                <v-icon>mdi-menu-down</v-icon>
                              </span>
                            </p>

                            <div class="row mt-2 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <p>Type of address</p>
                              </div>
                              <div class="col-md-10">
                                <v-radio-group inline color="primary" v-model="value">
                                  <v-radio label="Destination" value="1"></v-radio>
                                  <v-radio label="Residence" value="2"></v-radio>
                                </v-radio-group>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <v-text-field label="Address Details" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-2">
                                <v-text-field label="ZIP/Postal code" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-select label="Country" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-select label="State/province" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-2">
                                <v-text-field label="City" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <div class="col-12 mt-2" align="end">
                              <v-btn variant="outlined" color="primary">
                                Reset
                              </v-btn>
                            </div>
                          </v-card>
                        </div>
                      </div>


                      <div class="row mt-2" v-for="(data, index) of childData" :key="data">
                        <h6 style="color: blue">
                          Children {{ index + 1 }}
                        </h6>

                        <div class="row">
                          <div class="col-lg-3">
                            <v-text-field label="First Name" variant="outlined" v-model="data.fname"
                              :rules="firstName"></v-text-field>
                          </div>

                          <div class="col-lg-3">
                            <v-text-field label="Middle Name" variant="outlined" v-model="data.middle"></v-text-field>
                          </div>

                          <div class="col-lg-3">
                            <v-text-field label="Last Name" variant="outlined" v-model="data.last"
                              :rules="lastName"></v-text-field>
                          </div>

                          <div class="col-lg-2">
                            <v-select label="Salutation" :items="['Mstr', 'Miss']" v-model="data.salutation"
                              variant="outlined"></v-select>
                          </div>
                          <!-- <div class="col-lg-2">
                            <v-select label="Gender" :items="['Male', 'Female']" variant="outlined"
                              :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                          </div> -->
                        </div>

                        <div class="row">

                          <div class="col-lg-3">
                            <v-select label="Gender" :items="['Male', 'Female']" variant="outlined"
                              :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                          </div>
                          <!-- <label>Date of Birth</label> -->
                          <div class="col-md-3">
                            <!-- <span>
                              <v-icon>mdi-calendar</v-icon>
                            </span> -->
                            <span class="p-float-label">
                              <label class="dob-label" :class="data.clicked1 ? 'visible' : 'invisible'">Date of
                                Birth</label>
                              <VueDatePicker v-model="data.dateOfBirth" ref="dpRef1" no-today :six-weeks="true"
                                :start-date="new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate())"
                                :max-date="new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate())"
                                placeholder="Date of Birth" :rules="[(v) => !!v || 'This field is required']"
                                :min-date="new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate())"
                                :format="dateFormat" :hide-navigation="['time']" auto-apply
                                @update:modelValue="childDob()" class="px-0" />
                              <!-- <span class="p-error">Dep. Date is required</span> -->
                            </span>
                            <p v-if="dobChildError" class="p-error px-4 m-0">This field is required</p>
                          </div>

                          <!-- <div class="col-md-6 mt-2">
                            <v-btn-toggle class="d-flex align-center">
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="ffbdata(data, index)">
                                FFp
                                <v-tooltip activator="parent" location="top" width="200px">
                                  Please select an airline/frequent
                                </v-tooltip>
                              </v-btn>
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="tsadata(index)">
                                TSA
                                <v-tooltip activator="parent" location="top" width="200px">
                                  Please select an airline/frequent flyer program
                                </v-tooltip>
                              </v-btn>
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="apidata(index)">
                                APIS
                                <v-tooltip activator="parent" location="top" width="200px">
                                  the validating carrier are being displayed.
                                </v-tooltip>
                              </v-btn>
                            </v-btn-toggle>
                          </div> -->
                        </div>

                        <div>
                          <v-card class="ffb-cart" v-if="data.ffbnumber">
                            <div align="end">
                              <v-icon color="primary" @click="data.ffbnumber = false">
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Frequent Flyer Program</h6>

                            <div class="row mt-4">
                              <div class="col-md-3">
                                <v-select label="Frequent Flyer Program" rounted="5" v-model="air" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-text-field label="FFP Number" variant="outlined" v-model="ffb"></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn variant="outlined" color="primary" @click="reset()">
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart" v-if="data.TSAnumber">
                            <div align="end">
                              <v-icon color="primary" @click="data.TSAnumber = false">
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Transport Security Administration</h6>
                            <div class="row mt-4 gy-3">
                              <div class="col-lg-3">
                                <v-text-field label="Last name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="First name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="2nd and additional given names" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="Date of Birth" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <p class="redress">
                              Redress number and known traveller number
                              <span>
                                <v-icon @click="Redressnumber = !Redressnumber">mdi-menu-down</v-icon>
                              </span>
                            </p>

                            <div class="row mt-4 gy-3">
                              <div class="col-md-3">
                                <v-text-field v-if="Redressnumber" label="Redress Number" v-model="Redress"
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-text-field v-if="Redressnumber" label="Known Traveler Number" v-model="Traveler"
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn variant="outlined" color="primary" @click="reset1()">
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                            <h6>Advance Passenger Information System</h6>
                            <h6>Primary Data of the Travel Document</h6>
                            <div class="row mt-3 gy-3">
                              <div class="col-lg-3">
                                <v-text-field label="Last name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="First name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="2nd and additional given names    "
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-2">
                                <v-text-field label="Date Of Birth" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3">
                              <div class="col-3-lg">
                                <v-select label="Document Type" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-text-field label="Document Number" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-3-lg">
                                <v-select label="Issuance Country" rounted="5" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-select label="Nationality" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                            </div>

                            <div class="row mt-2 gy-3">
                              <div class="col-2-lg">
                                <v-text-field label="Issue Date" type="date" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-2-lg">
                                <v-text-field label="Expiration" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <p style="color: blue; cursor: pointer" @click="apivalue = !apivalue">
                              Passenger contact address (DOCA)
                              <span>
                                <v-icon>mdi-menu-down</v-icon>
                              </span>
                            </p>

                            <div class="row mt-2 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <p>Type of address</p>
                              </div>
                              <div class="col-md-10">
                                <v-radio-group inline color="primary" v-model="value">
                                  <v-radio label="Destination" value="1"></v-radio>
                                  <v-radio label="Residence" value="2"></v-radio>
                                </v-radio-group>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <v-text-field label="Address Details" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-2">
                                <v-text-field label="ZIP/Postal code" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-select label="Country" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-select label="State/province" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-2">
                                <v-text-field label="City" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <div class="col-12 mt-2" align="end">
                              <v-btn variant="outlined" color="primary">
                                Reset
                              </v-btn>
                            </div>
                          </v-card>
                        </div>
                      </div>



                      <div class="row mt-2" v-for="(data, index) of infrantData" :key="data">
                        <h6 style="color: blue">
                          Lap Infant {{ index + 1 }}
                        </h6>

                        <div class="row">
                          <div class="col-lg-3">
                            <v-text-field label="First Name" variant="outlined" v-model="data.fname"
                              :rules="firstName"></v-text-field>
                          </div>

                          <div class="col-lg-3">
                            <v-text-field label="Middle Name" variant="outlined" v-model="data.middle"></v-text-field>
                          </div>
                          <div class="col-lg-3">
                            <v-text-field label="Last Name" variant="outlined" v-model="data.last"
                              :rules="lastName"></v-text-field>
                          </div>

                          <div class="col-lg-2">
                            <v-select label="Salutation" :items="['Mstr', 'Miss']" v-model="data.salutation"
                              variant="outlined"></v-select>
                          </div>
                          <!-- <div class="col-lg-2">
                            <v-select label="Gender" :items="['Male', 'Female']" variant="outlined"
                              :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                          </div> -->
                        </div>

                        <div class="row">

                          <div class="col-lg-3">
                            <v-select label="Gender" :items="['Male', 'Female']" variant="outlined"
                              :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                          </div>
                          <!-- <label>Date of Birth</label> -->
                          <div class="col-md-3">
                            <span class="p-float-label">
                              <label class="dob-label" :class="data.clicked1 ? 'visible' : 'invisible'">Date of
                                Birth</label>
                              <VueDatePicker v-model="data.dateOfBirth" ref="dpRef1" no-today :six-weeks="true"
                                :start-date="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)"
                                :max-date="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)"
                                placeholder="Date of Birth" :rules="[(v) => !!v || 'this field is required',]"
                                :min-date="new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate())"
                                :format="dateFormat" :hide-navigation="['time']" auto-apply
                                @update:modelValue="infrantDob()" class="px-0" />
                              <!-- <span class="p-error">Dep. Date is required</span> -->
                            </span>
                            <p v-if="dobInfrantError" class="p-error px-4 m-0">This field is required</p>
                          </div>

                          <!-- <div class="col-md-6 mt-2">
                            <v-btn-toggle class="d-flex align-center">
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="ffbdata(data, index)">
                                FFp
                                <v-tooltip activator="parent" location="top" width="200px">
                                  Please select an airline/frequent
                                </v-tooltip>
                              </v-btn>
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="tsadata(index)">
                                TSA
                                <v-tooltip activator="parent" location="top" width="200px">
                                  Please select an airline/frequent flyer program
                                </v-tooltip>
                              </v-btn>
                              <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                @click="apidata(index)">
                                APIS
                                <v-tooltip activator="parent" location="top" width="200px">
                                  the validating carrier are being displayed.
                                </v-tooltip>
                              </v-btn>
                            </v-btn-toggle>
                          </div> -->
                        </div>

                        <div>
                          <v-card class="ffb-cart" v-if="data.ffbnumber">
                            <div align="end">
                              <v-icon color="primary" @click="data.ffbnumber = false">
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Frequent Flyer Program</h6>

                            <div class="row mt-4">
                              <div class="col-md-3">
                                <v-select label="Frequent Flyer Program" rounted="5" v-model="air" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-text-field label="FFP Number" variant="outlined" v-model="ffb"></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn variant="outlined" color="primary" @click="reset()">
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart" v-if="data.TSAnumber">
                            <div align="end">
                              <v-icon color="primary" @click="data.TSAnumber = false">
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Transport Security Administration</h6>
                            <div class="row mt-4 gy-3">
                              <div class="col-lg-3">
                                <v-text-field label="Last name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="First name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="2nd and additional given names" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="Date of Birth" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <p class="redress">
                              Redress number and known traveller number
                              <span>
                                <v-icon @click="Redressnumber = !Redressnumber">mdi-menu-down</v-icon>
                              </span>
                            </p>

                            <div class="row mt-4 gy-3">
                              <div class="col-md-3">
                                <v-text-field v-if="Redressnumber" label="Redress Number" v-model="Redress"
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-text-field v-if="Redressnumber" label="Known Traveler Number" v-model="Traveler"
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn variant="outlined" color="primary" @click="reset1()">
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                            <h6>Advance Passenger Information System</h6>
                            <h6>Primary Data of the Travel Document</h6>
                            <div class="row mt-3 gy-3">
                              <div class="col-lg-3">
                                <v-text-field label="Last name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="First name as per passport" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field label="2nd and additional given names    "
                                  variant="outlined"></v-text-field>
                              </div>
                              <div class="col-lg-2">
                                <v-text-field label="Date Of Birth" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3">
                              <div class="col-3-lg">
                                <v-select label="Document Type" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-text-field label="Document Number" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-3-lg">
                                <v-select label="Issuance Country" rounted="5" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-select label="Nationality" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                            </div>

                            <div class="row mt-2 gy-3">
                              <div class="col-2-lg">
                                <v-text-field label="Issue Date" type="date" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-2-lg">
                                <v-text-field label="Expiration" type="date" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <p style="color: blue; cursor: pointer" @click="apivalue = !apivalue">
                              Passenger contact address (DOCA)
                              <span>
                                <v-icon>mdi-menu-down</v-icon>
                              </span>
                            </p>

                            <div class="row mt-2 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <p>Type of address</p>
                              </div>
                              <div class="col-md-10">
                                <v-radio-group inline color="primary" v-model="value">
                                  <v-radio label="Destination" value="1"></v-radio>
                                  <v-radio label="Residence" value="2"></v-radio>
                                </v-radio-group>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <v-text-field label="Address Details" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-2">
                                <v-text-field label="ZIP/Postal code" variant="outlined"></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-select label="Country" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-select label="State/province" :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]" variant="outlined"></v-select>
                              </div>
                              <div class="col-md-2">
                                <v-text-field label="City" variant="outlined"></v-text-field>
                              </div>
                            </div>
                            <div class="col-12 mt-2" align="end">
                              <v-btn variant="outlined" color="primary">
                                Reset
                              </v-btn>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div class="mt-1" style="background-color: #f6f6f6; padding: 10px">
                        <h6>Passenger Contact Data</h6>
                      </div>

                      <div class="row mt-3">
                        <div class="col-lg-6">
                          <div class="row" v-for="data of paxContacts" :key="data">
                            <div class="col-md-6 mt-2">
                              <!-- <v-text-field label="Phone Number" :rules="userName" variant="outlined"
                                v-model="data.number"></v-text-field> -->
                              <vue-tel-input v-model="data.number" placeholder="Phone Number"
                                style="font-size:12px;"></vue-tel-input>
                            </div>
                            <div class="col-md-6 mt-2">
                              <v-text-field label="Email ID" :rules="emailRequire" variant="outlined"
                                v-model="data.email"></v-text-field>
                            </div>
                            <!-- <div class="col-md-1">
                              <v-icon v-if="paxContacts.length > 1" color="red" @click="delet()">
                                mdi-close-circle
                              </v-icon>
                            </div> -->
                          </div>
                          <!-- <div class="col-12" align="end">
                            <h6 @click="added()" style="cursor: pointer; color: blue">
                              + Add Passenger Contact
                            </h6>
                          </div> -->
                        </div>
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-md-5">
                              <v-checkbox v-model="contactCheck" class="spl-checkbox" label="Contact me on whatsapp"
                                color="success"></v-checkbox>
                            </div>
                            <div class="col-md-7">
                              <v-checkbox v-model="couponsCheck" class="spl-checkbox"
                                label="Send me the latest travel deals, special offers, coupons."
                                color="success"></v-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </v-card>
                </div>
              </div>
            </div>



            <div class="d-flex justify-center my-5" style="max-width: 100%; margin: auto">
              <v-checkbox class="px-3" style="flex: none"></v-checkbox>
              <span class="agree-content d-flex align-center">By selecting to continue I acknowledge that I have read and
                accept
                the Rules & Restrictions, Terms of use and Privacy Policy. In
                addition, I also confirm that I have verified that all information
                entered during the reservation process is accurate. I also
                acknowledge that this will serve as my electronic signature.
              </span>
            </div>
            <div class="mb-5" align="center">
              <v-btn width="35%" height="40px" class="continue-btn" @click="$router.push('/confirmBook')" style="
              color: white;
              text-transform: capitalize;
              background-color: #FF5400;
            ">Continue</v-btn>
            </div>
          </v-container>
        </div>

        <div class="col-lg-3 col-md-3">
          <div class="fare-summery" :style="{ top: topSticky }">
            <v-card max-width="400px" max-height="500px" class="pa-1 m-auto">

              <div class="d-flex justify-content-between">
                <div>
                  <p class="m-0" style="font-size:20px; font-weight: 500;">Total Price</p>
                </div>
                <div>
                  <p class="tot-price">USD {{ totalAmountpassenger }}</p>
                </div>
              </div>



              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="col-md-6">
                      <span style="font-size: 14px; font-weight: 500">Passengers</span>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="col-md-6" style="font-size: 14px">
                      <span v-if="adultLength > 0">{{ adultLength }} x Adult</span><br />
                      <span v-if="childLength > 0">{{ childLength }} x Child</span><br />
                      <span v-if="infLength > 0">{{ infLength }} x Lap Infant</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2">


                  <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example" aria-expanded="true"
                    aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                    <a href="#">Fare Summary</a></span>

                  <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                    aria-expanded="true" aria-controls="collapse-Example" v-model="isIcon"
                    style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ isIcon ? "mdi-chevron-up-box-outline" :
                      "mdi-chevron-down-box-outline" }}</v-icon>
                </div>

                <hr class="mt-1 mb-2" />

                <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example">

                  <div class="row">
                    <div class="col-6">
                      Base Price<br />
                      Taxes & Fees
                    </div>
                    <div class="col-6" align="center">
                      {{ totalbasePassanger }}<br />{{ totalTaxPassanger }}
                    </div>
                  </div>

                </div>

                <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p>


              </div>


            </v-card>


            <div class="mt-4 mb-2" align="center">
              <v-btn width="80%" height="40px" type="submit"
                style="font-size:16px; font-weight:500; color: white;text-transform: capitalize;background-color: #FF5400;">
                Continue</v-btn>
            </div>
          </div>

        </div>
      </div>
    </v-form>
  </v-container>
</template>

<script>
// import headerCom from '../components/headerCom.vue';
// import footerCom from '../components/footerCom.vue'
import moment from "moment";
import axios from "axios";
// import travellerDetail from "@/components/travellerDetails.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },


  components: {
    // headerCom,
    // footerCom,
    // travellerDetail,
    VueDatePicker,
  },
  data() {
    return {
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: '23%',


      contactCheck: true,
      couponsCheck: true,
      totalAmountpassenger: [],
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],
      mode: true,

      passengersDatas: {
        adultDatas: [],
        childDatas: [],
        infrantDatas: [],
      },

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      paxContacts: [
        {
          number: "",
          email: "",
        },
      ],

      emailRequire: [
        v => !!v || "This field is required",
        v => /.+@.+\..+/.test(v) || 'Please enter a valid email address',
      ],
      //     emailRequire: {
      //   required: (v) => !!v || 'Email is required',
      //   validEmail: (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address'
      // }

      itineraryInfo: false,
      itineraryInfo1: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infantLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      fareshow: false,
      // dobError:false,
      // clicked1: false,

      dobAdultError: false,
      dobChildError: false,
      dobInfrantError: false,

      flightList: [
        {
          departureDate: "Fri, Jul 30, 2023",
          flightName: "Air India",
          ticketNo: "AI-571/AI-131",
          fromTime: "06:50",
          toTime: "18:00",
          airportName: "Chennai (MAA)",
          fromPlace: "Chennai International Airport",
          toPlace: "Mumbai (BOM), Chhatrapati Shivaji International Airport",
          seatIcon: "mdi-seat-passenger",
          seatType: "Economy",
          foodIcon: "mdi-food-fork-drink",
          food: "Meal Provide",
          bagIcon: "mdi-bag-suitcase",
          baggage: "30kg (A)",
          refund: "Refundable",
          arrivalDate: "Fri, Jul 28, 2023",
        },
      ],
      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],

      firstName: [
        (v) => !!v || "Please enter your First Name",
        (v) => /^[a-zA-Z]+$/.test(v) || "Only alphabets are allowed",
      ],

      lastName: [
        (v) => !!v || "Please enter your Last Name",
        (v) => /^[a-zA-Z]+$/.test(v) || "Only alphabets are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) => /^[a-zA-Z]+$/.test(v) || "Only alphabets are allowed",
      ],
    };
  },
  methods: {
    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },

    getdepcode(data) {
      return data[0].Departure.AirportCode;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    depdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getstartdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    getdepname(data) {
      return data[0].Departure.AirportName;
    },
    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getarrname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },

    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },


    reset() {
      (this.ffb = ""), (this.air = "");
    },
    reset1() {
      (this.Redress = ""), (this.Traveler = "");
    },
    added() {
      let result =
        this.adultData.length + this.childData.length + this.infrantData.length;
      if (this.paxContacts.length < result) {
        this.paxContacts.push({
          number: "",
          email: "",
        });
      }
    },
    delet(index) {
      this.paxContacts.splice(index, 1);
    },
    // add() {
    //     for (let i = 0; i < this.adultData.length; i++) {
    //         if (this.adultData.length < 1) {
    //             this.adultData.push({
    //                 fname: "",
    //                 last: "",
    //                 middle: "",
    //                 year: "",
    //                 date: "",
    //                 month: "",
    //                 gender: "",
    //                 salutation: "",
    //                 ffbnumber: false,
    //             })
    //         }
    //     }
    // },
    ffbdata(data, index) {
      console.log(index, "index");
      console.log(data, "12345");
      this.adultData.map((item, i) => {
        if (i == index) {
          item.ffbnumber = !item.ffbnumber;
        }

        return item;
      });
    },
    tsadata(index) {
      this.adultData.map((item, i) => {
        if (i == index) {
          item.TSAnumber = !item.TSAnumber;
        }

        return item;
      });
    },
    apidata(index) {
      this.apivalue = true;
      this.adultData.map((item, i) => {
        if (i == index) {
          item.Apinumber = !item.Apinumber;
        }

        return item;
      });
    },

    day() {
      for (let i = 1; i <= 31; i++) {
        this.dateData.push(i);
        // console.log(this.date, '9999999')
      }
    },
    year() {
      for (let i = 2023; i <= 2035; i++) {
        this.yearData.push(i);
        // console.log(this.yearData, '9999999')
      }
    },

    adultDob() {
      this.adultData.map((v) => {
        if (v.dateOfBirth) {

          console.log(v.dateOfBirth, 'dobdob')

          v.clicked1 = true;
          this.dobAdultError = false;
          console.log(this.dobAdultError, 'dobdob')
        }
        else {
          v.clicked1 = false;
          this.dobAdultError = true;
        }
      })
    },
    childDob() {
      this.childData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobChildError = false;
        }
        else {
          v.clicked1 = false;
          this.dobChildError = true;
        }
      })
    },
    infrantDob() {
      this.infrantData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobInfrantError = false;
        }
        else {
          v.clicked1 = false;
          this.dobInfrantError = true;
        }
      })
    },

    // ClickOutDepat() {
    //     this.$refs.dpRef1.closeMenu();

    //     if (this.oneway.dedate) {
    //         this.clicked1 = true;
    //     }
    //     else {
    //         this.clicked1 = false;
    //     }
    // },

    getData() {
      for (let i = 0; i <= this.adultData.length; i++) {
        // console.log(this.adultData.length, 'ssss')
        if (this.adultData.length < this.adult) {
          this.adultData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Adult",
            clicked1: false,
            // dobAdultError: false,
          });
        } else {
          return this.adultData;
        }
      }
    },

    getChild() {
      for (let i = 0; i <= this.childData.length; i++) {
        if (this.childData.length < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            // dobChildError: false,
          });
        } else {
          return this.childData;
        }
      }
    },

    getinfrant() {
      for (let i = 0; i <= this.infrantData.length; i++) {
        if (this.infrantData.length < this.inf) {
          this.infrantData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            // dobInfrantError: false,
          });
        } else {
          return this.infrantData;
        }
      }
    },

    getpass() {
      let localData = JSON.parse(localStorage.getItem("rountData"));
      this.adult = localData.adult;
      this.child = localData.child;
      this.inf = localData.infrant;
    },

    getadult() {
      this.roundresult.forEach((v) => {
        this.totalAmountpassenger = parseFloat(v.TotalAmount);
        this.totalTaxPassanger = parseFloat(v.Taxprice);
        this.totalbasePassanger = parseFloat(v.price);
        console.log(v.price, "pppppppppppppppp");
        console.log(typeof this.TotalAmount, "this.TotalAmount");
      });

      // this.roundresult.forEach((v) => {
      //   v.pricedetail.forEach((s) => {
      //     console.log(s, "sssssssssssss");
      //     if (s.PassengerType == "ADT") {
      //       console.log(
      //         s.FareDetail.ApiPrice.TotalAmount,
      //         "000000000000000000"
      //       );
      //       console.log(this.adultLength, "this.adultLength");
      //       this.adultprice = parseInt(s.FareDetail.ApiPrice.TotalAmount / this.adultLength).toFixed(2)
      //       this.adulttax = s.FareDetail.ApiPrice.TaxAmount
      //       console.log(this.adultprice, "000000000000000000");
      //     } else if (s.PassengerType == "CHD") {
      //       this.childprice = parseInt(s.FareDetail.ApiPrice.TotalAmount / this.childLength).toFixed(2)
      //       this.childtax = s.FareDetail.ApiPrice.TaxAmount;
      //       console.log(this.childprice, "000000000000000000");
      //     } else if (s.PassengerType == "INF") {
      //       this.infprice = parseInt(s.FareDetail.ApiPrice.TotalAmount / this.infLength).toFixed(2)
      //       this.inftax = s.FareDetail.ApiPrice.TaxAmount;
      //       console.log(this.infprice, "000000000000000000");
      //     }
      //   });
      //   this.totalprice =
      //     this.adultprice * this.adultLength +
      //     this.childprice * this.childLength +
      //     this.infprice * this.infLength;

      //   this.totaltax = parseInt(this.adulttax + this.childtax + this.inftax).toFixed(2)
      //   this.totalamount = parseInt(this.totalprice) + parseFloat(this.totaltax);
      // });
    },

    onewayData() {
      this.onewayresult.forEach((v) => {
        this.totalAmountpassenger = parseFloat(v.TotalAmount);
        this.totalTaxPassanger = parseFloat(v.Taxprice);
        this.totalbasePassanger = parseFloat(v.price);
        console.log(v.price, "pppppppppppppppp");
        console.log(typeof this.TotalAmount, "this.TotalAmount");
      });

      //   this.onewayresult.forEach((v) => {
      //     v.pricedetail.forEach((s) => {
      //       console.log(s, "sssssssssssss");
      //       if (s.PassengerType == "ADT") {
      //         this.adultprice =
      //           s.FareDetail.ApiPrice.TotalAmount / this.adultLength;
      //         this.adulttax = s.FareDetail.ApiPrice.TaxAmount;
      //         console.log(this.adultprice, "000000000000000000");
      //       } else if (s.PassengerType == "CHD") {
      //         this.childprice =
      //           s.FareDetail.ApiPrice.TotalAmount / this.childLength;
      //         this.childtax = s.FareDetail.ApiPrice.TaxAmount;
      //         console.log(this.childprice, "000000000000000000");
      //       } else if (s.PassengerType == "INF") {
      //         this.infprice = s.FareDetail.ApiPrice.TotalAmount / this.infLength;
      //         this.inftax = s.FareDetail.ApiPrice.TaxAmount;
      //         console.log(this.infprice, "000000000000000000");

      //       }
      //     });
      //     this.totalprice =
      //       (this.adultprice * this.adultLength +
      //         this.childprice * this.childLength +
      //         this.infprice * this.infLength).toFixed(2)

      //     this.totaltax = parseInt(this.adulttax + this.childtax + this.inftax).toFixed(2)
      //     this.totalamount = (parseInt(this.totalprice) + parseInt(this.totaltax)).toFixed(2)
      //   });
      // },
    },



    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        this.topSticky = '5%';
      }
      else {
        this.scrolled = false;
        this.topSticky = '23%';
      }

      this.lastPosition = window.scrollY;
    },


    submitform() {
      this.passengersDatas.adultDatas = this.adultData
      this.passengersDatas.childDatas = this.childData
      this.passengersDatas.infrantDatas = this.infrantData

      console.log(this.passengersDatas, 'sfsfsfsfs.....')

      if (this.valid1) {

        this.paxContacts.forEach(v => {
          console.log(v, '0000000000000000')
          axios.post("https://vtapi.wistirna.com/api/insertpaxcontact", {
            "hit_id": "ca-650574c62017b8-37446718-3013",
            "pax_phone": v.number,
            "pax_email": v.email
          }, {
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              console.log(response, "responseresponseresponseresponseresponse");
            });

        })

        localStorage.setItem("TravelerDetails", JSON.stringify(this.passengersDatas));
        localStorage.setItem("passengerContact", JSON.stringify(this.paxContacts));

        alert("thank you")
        this.$router.push('/confirmBook')

      }
      else {
        alert("Please enter your detail")
        this.dobAdultError = true
        this.dobChildError = true
        this.dobInfrantError = true
      }
    },
  },


  watch: {
    adultData: {
      deep: true,
      handler(newData) {
        newData.forEach((data) => {
          if (data.salutation !== 'Mr') {
            data.gender = 'Female';
            console.log(data.salutation, 'ngngngngn...1.1..1.1.....')
            console.log(data.gender, 'ngngngngn...2..2.2.2.2.....')
          }
          else{
            data.gender = 'Male';
          }
        });
      },
    },
  },


  mounted() {
    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    console.log(this.dateOfBirth, "dobdob.....");
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.getpass();
    this.day();
    this.year();
    // this.add()
    this.getData();
    this.getChild();
    this.getinfrant();

    let resul = [];
    resul = JSON.parse(localStorage.getItem("rountData"));
    console.log(resul, "resul");
    if (resul.from.name) {
      this.fromData = resul.from.name;
    } else {
      this.fromData = resul.from;
    }
    if (resul.to.name) {
      this.toData = resul.to.name;
    } else {
      this.toData = resul.to;
    }
    this.adultLength = resul.adult;
    this.childLength = resul.child;
    this.infLength = resul.infrant;
    this.classed = resul.class;

    let roundData = JSON.parse(localStorage.getItem("itineryData"));
    if (roundData) {
      this.roundresult.push(roundData);
      this.getadult();
      this.itineraryInfo = true;
    }

    let onewayData = JSON.parse(localStorage.getItem("onewayItineyData"));
    if (onewayData) {
      this.onewayresult.push(onewayData);
      this.onewayData();
      this.itineraryInfo1 = true;
    }
    console.log(this.roundresult, "roundresult");

    this.fareItems.push(JSON.parse(localStorage.getItem("rounttrip")));
    console.log(this.fareItems, "fare.....");
  },
};
</script>

<style scoped>
>>>.v-card {
  overflow: unset;
  position: inherit !important;
}

>>>.v-input__details {
  display: block;
}


>>>.v-messages__message {
  font-size: 10px;
  line-height: 8px;
}

.vue-tel-input {
  height: 40px;
}

.flight-btn1 {
  font-size: 16px;
  font-family: serif;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.p-error {
  font-size: 10px;
  color: #B00020;
  letter-spacing: 0.0333333333em;
  transition-duration: 150ms;
}

>>>.spl-checkbox .v-label {
  font-size: 12px !important;
  font-weight: 600;
}

.active-line1 {
  height: 3px;
  background-color: red;
  width: 82.2px;
  position: absolute;
  left: 35px;
  border-radius: 90px 0px 0px 40px/14px 0px 0px 8px;
}

.active-line2 {
  height: 3px;
  background-color: red;
  width: 99px;
  position: absolute;
  left: 117.2px;
  border-radius: 0px 90px 40px 0px/0px 14px 8px 0px;
}

/* >>>.v-img__img {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 50%;
} */

.active1 {
  background-color: rgb(153, 190, 202);
  border-radius: 30px 0px 0px 30px;
  color: white;
}

.active2 {
  background-color: rgb(153, 190, 202);
  border-radius: 0px 30px 30px 0px;
  color: white;
}

/* Scroll Bar Styles */

.card-content::-webkit-scrollbar {
  width: 20px;
}

>>>.v-toolbar__content {
  height: 30px !important;
  padding: 2px 3px;
}

.card-content::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 25px;
}

/* minimalist design */
.card-content::-webkit-scrollbar {
  width: 10px;
}

.card-content::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

.card-content::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* striped lines */
.card-content::-webkit-scrollbar {
  width: 20px;
}

.card-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

/* animated  */
.card-content::-webkit-scrollbar {
  width: 8px !important;
}

.card-content::-webkit-scrollbar-track {
  background: darkblue;
}

.card-content::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* Scroll Bar Styles End */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}


.fw-500 {
  font-weight: 500;
}


.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

.close-btn {
  min-width: 12%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.reset-btn {
  color: brown;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  box-shadow: none;
  text-transform: capitalize;
}

.addmore-btn {
  color: dodgerblue;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  box-shadow: none;
  text-transform: capitalize;
}

.addmore-btn:hover {
  color: darkcyan;
}

>>>.v-selection-control .v-label {
  white-space: normal;
  word-break: break-word;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

/* Timeline */

>>>.v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>>.v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>>.v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.v-timeline--vertical.v-timeline {
  height: auto;
}

.card-space {
  margin: 5px 0px;
}

.fare-summery {
  position: sticky;
  padding: 10px 0px;
  width: 100%;
  z-index: 1;
  transition: top 0.5s;
}


.fare-summery-btn {}

.ffb-button {
  color: #0748ac !important;
  border: 1px solid #0748ac !important;
  margin-left: 10px;
  height: 40px !important;
}

.ffb-button:hover {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-button:focus {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-button:active {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-cart {
  box-shadow: none;
  background-color: #f6f6f6;
  padding: 10px 20px !important;
  margin-top: 10px;
}

.redress {
  cursor: pointer;
  color: #FF5400;
}

.redress:hover {
  cursor: pointer;
  color: #0b446d;
}

.v-input__control {
  height: 45px !important;
}

.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  top: -6px;
}

.v-field--center-affix .v-label.v-field-label {
  top: 39%;
  transform: translateY(-50%);
}

.v-selection-control .v-label {
  height: auto;
}

/* Datepicker Style Section */

.v-card {
  overflow: unset;
}

>>>.dp__input_icon {
  display: none;
}

.v-icon {
  width: 100%;
}

>>>.dp__input {
  height: 42px;
  border-color: darkgrey;
  border-radius: 4px !important;
  padding: 0px 20px;
}

>>>.dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.label-top {
  color: transparent;
  background: transparent;
}

.datepicker-label {
  position: relative;
  top: 10px;
  left: 6px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 11px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  background: white;
}

.label-top2 {
  position: absolute;
  top: 0px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 24px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  right: 87px;
  transform: translateY(-100%);
  background-color: white;
  padding: 0 8px;
  font-size: 12px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute;
  right: 69px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

>>>.v-input__control {
  height: 40px;
  display: flex;
  grid-area: control;
}

>>>.dp__input {
  height: 40px;
  border-color: darkgray;
}

>>>.v-input__control {
  height: 40px;
}

>>>.v-field__field {
  height: 40px;
}

>>>.v-field__input {
  padding-top: 0 !important;
}

.delete-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 12px;
  z-index: -1;
}

.p-float-label {
  position: relative;
  top: -4px;
}


.dob-label {
  position: relative;
  pointer-events: none;
  top: 6px;
  left: 12px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 12px;
  color: gray;
  z-index: 1;
  background: white;
  padding: 0px 3px;
  font-weight: 400;
}

.dob-label-1 {
  visibility: hidden;
}

/* Timeline End */

.tot-price {
  font-size: 22px;
  font-weight: 600;
}




@media only screen and (min-width: 481px) {
  .fare-show {
    display: none;
  }

  .continue-btn {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .fare-summery {
    display: none;
  }

  .agree-content {
    font-size: 11px;
  }

  .card-space {
    margin: 2px;
  }

  .font-change-14 {
    font-size: 14px;
  }

  .font-change-11 {
    font-size: 11px;
  }

  .layover-size {
    width: 50% !important;
  }

  .lay-fsize {
    font-size: 9px;
  }
}


@media only screen and (max-width: 767px) {
  .class-box {
    justify-content: flex-end;
  }
}



@media only screen and (max-width: 1200px) {
  .tot-price {
    font-size: 16px;
    font-weight: 600;
  }

  .lay-fsize {
    font-size: 9px;
  }
}

@media only screen and (max-width: 768px) {
  .price .price-content {
    text-align: left !important;
  }

  .card-box .spaced {
    padding: 5px 12px !important;
  }

  .tool-span span {
    font-size: 12px;
    font-weight: bold;
    padding: 0px 3px;
  }

  .sub-section {
    border-left: none !important;
  }

  .sub-section.row {
    padding-left: 0px !important;
  }

  .tot-price {
    font-size: 26px;
    font-weight: 500;
  }

  .airline-logo {
    padding: 0;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}
</style>
