<template>
  <div class="flight-back">
    <div>
      <section class="bannar-bg">
        <div class="container ">
          <div class="row  ">
            <div class="col-lg-6 col-md-6 ">
              <br>
              <div>

                <div class="banner-left-small-title ">
                  <h6 style="font-weight: 600;">BEST DESTINATION AROUND THE WORLD</h6>
                </div>
                <div class="banner-left-title mt-1">
                  <h1>
                    Travel to any corner of
                    <span class="banner-small-text">the world</span>
                  </h1>
                </div>
              </div>

              <div class="headcard" align="center">
                <v-card class="shadow" rounded="4"
                  style="width:90%; padding: 20px; overflow: unset;z-index:1; cursor: pointer;">
                  <div class="row">
                    <div class="col-md-10 w-auto">
                      <v-radio-group class="d-flex justify-center" inline color="secoundry" v-model="red"
                        style="position: relative; top: 0px;">
                        <v-radio label="Round Trip" value="1" class="normal-radio" :class="{ 'radio': red1 === 1 }"
                          @click="act(1)"></v-radio>
                        <v-radio label="Oneway" value="2" class="normal-radio" :class="{ 'radio': red1 === 2 }"
                          @click="act(2)"></v-radio>
                        <!-- <v-radio label="Multi-city" value="3" class="normal-radio" :class="{ 'radio': red1 === 3 }"
                        @click="act(3)"></v-radio> -->


                      </v-radio-group>
                    </div>
                    <br>
                  </div>


                  <div v-if="rount">
                    <v-form>
                      <div class="row my-4">
                        <div class="col-md-6 col-12 d-flex my-3">
                          <span>
                            <v-icon>mdi-airplane-takeoff</v-icon>
                          </span>
                          <span class="p-float-label">
                            <AutoComplete v-model="oneway.from" optionLabel="name" dropdown forceSelection
                              :suggestions="filteredCountries" @complete="search" @change="onChangeFrom()"
                              @click="fromClick(oneway)" style="border-bottom: 1px solid lightgrey; width:100%">

                              <template #option="slotProps">
                                <div class="flex align-items-center dropdown-box" @click="focus1()">
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">From</label>
                            <span class="p-error" v-if="fromErrorMessage">Departure is required</span>
                            <br>
                            <span class="p-error" v-if="maximumletter">Enter minimum 3 letters</span>

                          </span>

                        </div>



                        <div class="col-md-6 col-12 d-flex my-3">

                          <span class="icon-container1">
                            <v-icon>mdi-airplane-landing</v-icon>
                          </span>

                          <span class="p-float-label">
                            <AutoComplete v-model="oneway.to" ref="secondAutoComplete" optionLabel="name" dropdown
                              @click="toClick(oneway)" :suggestions="filteredCountries" forceSelection @complete="search"
                              @change="onChangeTo()" style="border-bottom: 1px solid lightgrey; width:100%">

                              <template #option="slotProps">
                                <div class="flex align-items-center dropdown-box" @click="focus2()">
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">To</label>
                            <span class="p-error" v-if="toErrorMessage">Arrival is required</span>
                            <br>
                            <span class="p-error" v-if="maximumletter1">Enter minimum 3 letters</span>
                          </span>

                        </div>
                      </div>


                      <div class="row gy-3">
                        <div class="col-md-6 col-12 d-flex my-3">

                          <span>
                            <v-icon>mdi-calendar</v-icon>
                          </span>

                          <span class="p-float-label">
                            <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                              Departure
                            </span>

                            <VueDatePicker ref="dpRef1" v-model="oneway.dedate" :min-date="new Date()" :six-weeks="mode"
                              :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                              :format="dateFormat" :hide-navigation="['time']" auto-apply
                              @update:modelValue="fromDateChanged" :on-click-outside="ClickOutDepat" class="px-0" />

                            <!-- <div class="col-12"> -->
                            <span class="p-error" v-if="fromDateError">Dep. Date is required</span>
                            <!-- </div> -->
                          </span>
                        </div>
                        <div class="col-md-6 col-12 d-flex my-3">
                          <span>
                            <v-icon>mdi-calendar</v-icon>
                          </span>
                          <span class="p-float-label">
                            <span :class="clicked2 ? 'label-top2' : 'datepicker-label2'">
                              Returning
                            </span>
                            <VueDatePicker ref="dpRef2" v-model="oneway.redate" :format="dateFormat" no-today
                              :six-weeks="mode" :start-date="oneway.dedate"
                              :min-date="oneway.dedate ? oneway.dedate : new Date()"
                              :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                              :hide-navigation="['time']" auto-apply @update:modelValue="toDateChanged"
                              :on-click-outside="ClickOutReturn" />

                            <span class="p-error" v-if="toDateError">Arr. Date is required</span>
                          </span>
                        </div>
                      </div>

                      <!-- <div class="row d-flex justify-content-around">
                      <div class="col-5">
                        <span class="p-error">{{ fromErrorMessage }}</span>
                      </div>
                      <div class="col-5">
                        <span class="p-error">{{ toErrorMessage }}</span>
                      </div>
                    </div> -->

                      <div class="row mt-2 ">

                        <div class="col-1 d-flex align-center">
                          <span style="    font-size: 18px;
    color: gray;">
                            <v-icon>
                              mdi-account-outline
                            </v-icon>
                          </span>
                        </div>




                        <div class="col-10">

                          <v-select v-model="selectedCountry" :items="countries" ref="countrySelect" variant="underlined"
                            style="position: relative; top: -10px;">

                            <!-- <template #item="{ item }"> -->
                            <template #item="{ }">

                              <div class="dropdown_section" style="cursor: pointer;">
                                <!-- <div>
                                <p class="text-center">Travelers</p>
                                <hr>
                              </div> -->

                                <div class="row d-flex align-center my-2 mx-1" style="height:37px;" @click.stop>
                                  <div class="col-3" style="width:38%">
                                    <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Adult</span>
                                  </div>
                                  <div class="col-3 px-0" style="width:9%">
                                    <v-icon style="width:auto; color: grey; font-size:18px; ">
                                      mdi-human-male-female
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width:50%; height: auto;">
                                    <div class="row" style="padding: 0px;">
                                      <div class="col-4" @click="deg1()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div class="col-4" style="background-color: white;" align="center">
                                        <div>{{ aduls }}</div>
                                      </div>
                                      <div class="col-4" @click="add1()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div>{{ item.name }}</div> -->
                                </div>

                                <div class="row d-flex align-center my-2 mx-1" @click.stop>

                                  <div class="col-4 d-flex flex-column pe-0" style="width:38%">
                                    <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Children</span>
                                    <span style="font-size: 11px; color: #4e4d4d;">2-11(yrs)</span>
                                  </div>
                                  <div class="col-2 px-0" style="width:9%">
                                    <v-icon style="width:auto; color: grey; font-size:18px;">
                                      mdi-human-child
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width:50%">
                                    <div class="row" style="padding: 0px;">
                                      <div class="col-4" @click="deg2()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div class="col-4" style="background-color: white;" align="center">
                                        <div>{{ aduls1 }}</div>
                                      </div>
                                      <div class="col-4" @click="add2()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div>{{ item.name }}</div> -->
                                </div>


                                <div class="row d-flex align-center my-2 mx-1" @click.stop>

                                  <div class="col-5 d-flex flex-column pe-0" style="width:38%">
                                    <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Lap Infant</span>
                                    <span style="font-size: 11px; color: #4e4d4d;">2(yrs)</span>
                                  </div>

                                  <div class="col-1 ps-0" style="width:9%">
                                    <v-icon style="width:auto; color: grey; font-size:18px;">
                                      mdi-baby-buggy
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width:50%">
                                    <div class="row" style="padding: 0px;">
                                      <div class="col-4" @click="deg3()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div class="col-4" style="background-color: white;" align="center">
                                        <div>{{ aduls2 }}</div>
                                      </div>
                                      <div class="col-4" @click="add3()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row d-flex align-center  my-2 mx-1" @click.stop>
                                  <div class="col-4 mt-3" style="width:35%">
                                    <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Cabin</span>
                                  </div>

                                  <div class="col-1 mt-3" style="width:12%">
                                    <v-icon style="width:auto; color: grey; font-size:18px;">
                                      mdi-seat-passenger
                                    </v-icon>
                                  </div>
                                  <div class="col-7" style="width:50%">
                                    <v-select v-model="oneway.class" ref="mySelect"
                                      :items="['Economy', 'Business', 'First Class']" variant="underlined"
                                      style="color:gray;"></v-select>
                                  </div>

                                </div>

                                <div align="end" class="mt-4 pe-3" @click="travels = false, localdone(), closeDropdown()">
                                  <v-btn rounded="3" color="red" width="100px" height="30px">
                                    Done
                                  </v-btn>
                                </div>
                              </div>
                            </template>


                            <template #selection="{ }">

                              <div>

                                <span>{{ aduls }} Adult</span>
                                <span v-if="aduls1 > 0">, {{ aduls1 }} Child</span>
                                <span v-if="aduls2 > 0">, {{ aduls2 }} Infant</span>
                                <span>, {{ oneway.class }}</span>
                              </div>
                            </template>

                          </v-select>

                        </div>
                      </div>
                      <div class="row mt-4 d-flex justify-end">
                        <div class="col-md-8">
                          <v-btn block class="search" :disabled="disButton" height="40px" rounded="2" @click="sended()"
                            style="font-size: 16px;">Search </v-btn>
                        </div>



                        <div class="col-md-2 d-flex align-center">
                          <!-- <v-progress-circular indeterminate color="primary" v-if="loadSearch"></v-progress-circular> -->
                          <v-progress-circular v-if="loadSearch" :width="3" color="primary"
                            indeterminate></v-progress-circular>
                        </div>
                      </div>
                    </v-form>
                  </div>


                  <div v-if="Oneway">
                    <v-form>
                      <div class="row my-4">
                        <div class="col-md-6 col-12 d-flex my-3">
                          <span>
                            <v-icon>mdi-airplane-takeoff</v-icon>
                          </span>
                          <span class="p-float-label">
                            <AutoComplete v-model="oneway.from" optionLabel="name" dropdown forceSelection
                              :suggestions="filteredCountries" @complete="search" @change="onChangeFrom()"
                              @click="fromClick(oneway)" style="border-bottom: 1px solid lightgrey; width:100%">

                              <template #option="slotProps">
                                <div class="flex align-items-center dropdown-box" @click="focus1()">
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">From</label>
                            <span class="p-error" v-if="fromErrorMessage">Departure is required</span><br
                              v-if="maximumletter">
                            <span class="p-error" v-if="maximumletter">Enter minimum 3 letters</span>

                          </span>

                        </div>



                        <div class="col-md-6 col-12 d-flex my-3">

                          <span class="icon-container1">
                            <v-icon>mdi-airplane-landing</v-icon>
                          </span>

                          <span class="p-float-label">
                            <AutoComplete v-model="oneway.to" ref="secondAutoComplete" optionLabel="name" dropdown
                              @click="toClick(oneway)" :suggestions="filteredCountries" forceSelection @complete="search"
                              @change="onChangeTo()" style="border-bottom: 1px solid lightgrey; width:100%">

                              <template #option="slotProps">
                                <div class="flex align-items-center dropdown-box" @click="focus2()">
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">To</label>
                            <span class="p-error" v-if="toErrorMessage">Arrival is required</span>
                            <span class="p-error" v-if="maximumletter1">Enter minimum 3 letters</span>
                          </span>

                        </div>
                      </div>


                      <div class="row gy-3">
                        <div class="col-md-12 col-12 d-flex my-3">

                          <span>
                            <v-icon>mdi-calendar</v-icon>
                          </span>

                          <span class="p-float-label">
                            <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                              Departure
                            </span>

                            <VueDatePicker ref="dpRef1" v-model="oneway.dedate" :min-date="new Date()" :six-weeks="mode"
                              :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                              :format="dateFormat" :hide-navigation="['time']" auto-apply
                              @update:modelValue="onewayDateChanged" :on-click-outside="ClickOutDepat" class="px-0" />

                            <!-- <div class="col-12"> -->
                            <span class="p-error" v-if="fromDateError">Dep. Date is required</span>
                            <!-- </div> -->
                          </span>
                        </div>

                      </div>


                      <div class="row mt-2 ">

                        <div class="col-1 d-flex align-center">
                          <span style="    font-size: 18px;
    color: gray;">
                            <v-icon>
                              mdi-account-outline
                            </v-icon>
                          </span>
                        </div>




                        <div class="col-10">

                          <v-select v-model="selectedCountry" :items="countries" ref="countrySelect" variant="underlined"
                            style="position: relative; top: -10px;">

                            <!-- <template #item="{ item }"> -->
                            <template #item="{ }">

                              <div class="dropdown_section" style="cursor: pointer;">
                                <!-- <div>
                                <p class="text-center">Travelers</p>
                                <hr>
                              </div> -->

                                <div class="row d-flex align-center my-2 mx-1" style="height:37px;" @click.stop>
                                  <div class="col-3" style="width:38%">
                                    <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Adult</span>
                                  </div>
                                  <div class="col-3 px-0" style="width:9%">
                                    <v-icon style="width:auto; color: grey; font-size:18px; ">
                                      mdi-human-male-female
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width:50%; height: auto;">
                                    <div class="row" style="padding: 0px;">
                                      <div class="col-4" @click="deg1()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div class="col-4" style="background-color: white;" align="center">
                                        <div>{{ aduls }}</div>
                                      </div>
                                      <div class="col-4" @click="add1()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div>{{ item.name }}</div> -->
                                </div>

                                <div class="row d-flex align-center my-2 mx-1" @click.stop>

                                  <div class="col-4 d-flex flex-column pe-0" style="width:38%">
                                    <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Children</span>
                                    <span style="font-size: 11px; color: #4e4d4d;">2-11(yrs)</span>
                                  </div>
                                  <div class="col-2 px-0" style="width:9%">
                                    <v-icon style="width:auto; color: grey; font-size:18px;">
                                      mdi-human-child
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width:50%">
                                    <div class="row" style="padding: 0px;">
                                      <div class="col-4" @click="deg2()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div class="col-4" style="background-color: white;" align="center">
                                        <div>{{ aduls1 }}</div>
                                      </div>
                                      <div class="col-4" @click="add2()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div>{{ item.name }}</div> -->
                                </div>


                                <div class="row d-flex align-center my-2 mx-1" @click.stop>

                                  <div class="col-5 d-flex flex-column pe-0" style="width:38%">
                                    <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Lap Infant</span>
                                    <span style="font-size: 11px; color: #4e4d4d;">2(yrs)</span>
                                  </div>

                                  <div class="col-1 ps-0" style="width:9%">
                                    <v-icon style="width:auto; color: grey; font-size:18px;">
                                      mdi-baby-buggy
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width:50%">
                                    <div class="row" style="padding: 0px;">
                                      <div class="col-4" @click="deg3()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div class="col-4" style="background-color: white;" align="center">
                                        <div>{{ aduls2 }}</div>
                                      </div>
                                      <div class="col-4" @click="add3()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row d-flex align-center  my-2 mx-1" @click.stop>

                                  <div class="col-4 mt-3" align="center">
                                    Cabin
                                  </div>

                                  <div class="col-2 mt-3 " align="center">
                                    <v-icon style="width:auto;  font-size:18px;">
                                      mdi-seat-passenger
                                    </v-icon>
                                  </div>
                                  <div class="col-6 ">
                                    <v-select v-model="oneway.class" ref="mySelect"
                                      :items="['Economy', 'Business', 'First Class']" variant="underlined"
                                      style="color:gray;"></v-select>
                                  </div>

                                </div>

                                <div align="end" class="mt-4 pe-3" @click="travels = false, localdone(), closeDropdown()">
                                  <v-btn rounded="3" color="red" width="100px" height="30px">
                                    Done
                                  </v-btn>
                                </div>
                              </div>
                            </template>


                            <template #selection="{ }">

                              <div>

                                <span>{{ aduls }} Adult</span>
                                <span v-if="aduls1 > 0">, {{ aduls1 }} Child</span>
                                <span v-if="aduls2 > 0">, {{ aduls2 }} Infant</span>
                                <span>, {{ oneway.class }}</span>
                              </div>
                            </template>

                          </v-select>

                        </div>
                      </div>
                      <div class="row mt-4 d-flex justify-end">
                        <div class="col-md-8">
                          <v-btn block class="search" :disabled="disButton" height="40px" rounded="2" @click="sendedone()"
                            style="font-size: 16px;">Search </v-btn>
                        </div>



                        <div class="col-md-2 d-flex align-center">
                          <!-- <v-progress-circular indeterminate color="primary" v-if="loadSearch"></v-progress-circular> -->
                          <v-progress-circular v-if="loadSearch" :width="3" color="primary"
                            indeterminate></v-progress-circular>
                        </div>
                      </div>
                    </v-form>
                  </div>


                  <div v-if="multicity">
                    <div v-for="(data, index) of input" :key="index">
                      <form action="">
                        <div class="row mt-4">
                          <div class="col-md-4 d-flex justify-center">
                            <span>
                              <v-icon>mdi-airplane-takeoff</v-icon>
                            </span>
                            <span class="p-float-label">
                              <AutoComplete v-model="data.from" optionLabel="name" dropdown
                                :suggestions="filteredCountries" @click="multiFromClick(index)" @complete="search"
                                @change="onChangeMultiFrom(index)" style="border-bottom: 1px solid lightgrey; width:100%">

                                <template #option="slotProps">
                                  <div class="flex align-items-center dropdown-box" @click="focus5(index)">
                                    <div>{{ slotProps.option.originalName }}</div>
                                  </div>
                                </template>
                              </AutoComplete>
                              <label for="ac">From</label>
                              <span v-if="data.multiFromError" class="p-error">Departure is required</span>
                            </span>
                          </div>


                          <div class="col-md-4 d-flex justify-center">
                            <span>
                              <v-icon>mdi-airplane-landing</v-icon>
                            </span>

                            <span class="p-float-label">
                              <AutoComplete v-model="data.to" ref="multiTo" optionLabel="name" dropdown
                                :suggestions="filteredCountries" @complete="search" @click="multiToClick(index)"
                                @change="onChangeMultiTo(index)" style="border-bottom: 1px solid lightgrey; width:100%">

                                <template #option="slotProps">
                                  <div class="flex align-items-center dropdown-box" @click="focus6(index)">
                                    <div>{{ slotProps.option.originalName }}</div>
                                  </div>
                                </template>
                              </AutoComplete>
                              <label for="ac">To</label>
                              <span v-if="data.multiToError" class="p-error">Arrival is required</span>
                            </span>
                          </div>


                          <div class="col-md-4 d-flex justify-center">
                            <span>
                              <v-icon>mdi-calendar</v-icon>
                            </span>
                            <label :class="data.clicked3 ? 'multiLabel-top' : 'multiDatepicker-label'">
                              Departure
                            </label>

                            <span class="p-float-label">
                              <VueDatePicker ref="dpRef4" v-model="data.departDate" :min-date="new Date()"
                                :six-weeks="mode" :format="dateFormat" :hide-navigation="['time']" auto-apply
                                @update:modelValue="newValue => multiDateChanged(index, newValue)"
                                :on-click-outside="multiClickOutside()" />
                              <!-- @focus="multiClickDeparture(index)" -->
                              <span v-if="data.multiDateError" class="p-error">Dep. Date is required</span>
                            </span>
                          </div>
                          <span class="delete-icon" @click="delet()">
                            <v-icon v-if="input.length > 2" color="red"
                              style="width: auto; font-size: 18px; position:relative; top:5px;">
                              mdi-close-octagon
                            </v-icon>
                          </span>
                        </div>
                      </form>
                    </div>


                    <div class="col-md-12 mt-2 d-flex justify-end" align="end" style="cursor:pointer">
                      <span @click="send(index)" v-if="addmore"> <v-icon color="green"
                          style="width: auto;">mdi-plus-circle</v-icon>
                        <span>Add More</span>
                      </span>
                      <span v-if="clearall" style="margin-left:5px" @click="clearall1()">
                        <a style="text-decoration: underline;color: blue;">Clear All</a>
                      </span>
                    </div>


                    <div class="row d-flex justify-center">
                      <div class="col-md-5">
                        <v-select v-model="select" :items="['Economy', 'Business', 'First Class']" variant="underlined"
                          style="position: relative;left:15px; color:gray;"></v-select>
                      </div>

                      <div class="col-md-1"></div>

                      <div class="col-md-5">

                        <v-select v-model="selectedCountry" :items="countries" ref="countrySelect" variant="underlined"
                          style="position: relative;left: 15px; color: gray;">

                          <!-- <template #item="{ item }"> -->
                          <template #item="{ }">

                            <div class="dropdown_section" style="cursor:pointer">
                              <!-- <div>
                              <p class="text-center">Travelers</p>
                              <hr>
                            </div> -->

                              <div class="row d-flex align-center bg-light my-2 mx-1" style="height:37px;" @click.stop>
                                <div class="col-3" style="width:38%">
                                  <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Adult</span>
                                </div>
                                <div class="col-3 px-0" style="width:9%">
                                  <v-icon style="width:auto; color: grey; font-size:18px;">
                                    mdi-human-male-female
                                  </v-icon>
                                </div>
                                <div class="col-6 adult" style="width:50%; height: auto;">
                                  <div class="row" style="padding: 0px;">
                                    <div class="col-4" @click="deg1()">
                                      <div class="adult-plus">-</div>
                                    </div>
                                    <div class="col-4" style="background-color: white;" align="center">
                                      <div>{{ aduls }}</div>
                                    </div>
                                    <div class="col-4" @click="add1()">
                                      <div class="adult-plus">+</div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div>{{ item.name }}</div> -->
                              </div>

                              <div class="row d-flex align-center bg-light my-2 mx-1" @click.stop>

                                <div class="col-4 d-flex flex-column pe-0" style="width:38%">
                                  <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Children</span>
                                  <span style="font-size: 11px; color: #4e4d4d;">2-11(yrs)</span>
                                </div>
                                <div class="col-2 px-0" style="width:9%">
                                  <v-icon style="width:auto; color: grey; font-size:18px;">
                                    mdi-human-child
                                  </v-icon>
                                </div>
                                <div class="col-6 adult" style="width:50%">
                                  <div class="row" style="padding: 0px;">
                                    <div class="col-4" @click="deg2()">
                                      <div class="adult-plus">-</div>
                                    </div>
                                    <div class="col-4" style="background-color: white;" align="center">
                                      <div>{{ aduls1 }}</div>
                                    </div>
                                    <div class="col-4" @click="add2()">
                                      <div class="adult-plus">+</div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div>{{ item.name }}</div> -->
                              </div>


                              <div class="row d-flex align-center bg-light my-2 mx-1" @click.stop>

                                <div class="col-5 d-flex flex-column pe-0" style="width:38%">
                                  <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Lap Infant</span>
                                  <span style="font-size: 11px; color: #4e4d4d;">2(yrs)</span>
                                </div>

                                <div class="col-1 ps-0" style="width:9%">
                                  <v-icon style="width:auto; color: grey; font-size:18px;">
                                    mdi-baby-buggy
                                  </v-icon>
                                </div>
                                <div class="col-6 adult" style="width:50%">
                                  <div class="row" style="padding: 0px;">
                                    <div class="col-4" @click="deg3()">
                                      <div class="adult-plus">-</div>
                                    </div>
                                    <div class="col-4" style="background-color: white;" align="center">
                                      <div>{{ aduls2 }}</div>
                                    </div>
                                    <div class="col-4" @click="add3()">
                                      <div class="adult-plus">+</div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div>{{ item.name }}</div> -->
                              </div>

                              <div align="end" class="mt-4 pe-3" @click="travels = false, localdone(), closeDropdown()">
                                <v-btn rounded="3" color="red" width="100px" height="30px">
                                  Done
                                </v-btn>
                              </div>
                            </div>
                          </template>


                          <template #selection="{ }">
                            <div>
                              <span>{{ aduls }} Ad</span>
                              <span v-if="aduls1 > 0">, {{ aduls1 }} Chd</span>
                              <span v-if="aduls2 > 0">, {{ aduls2 }} Inf</span>
                            </div>
                          </template>

                        </v-select>

                      </div>

                    </div>

                    <div class="row mt-4 d-flex justify-center">
                      <div class="col-md-8">
                        <v-btn block class="search" height="40px" rounded="2" @click="sendedMulticity()"
                          style="font-size: 16px;">Search </v-btn>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
            <div class="col-lg-6 mb-5 col-md-6 flight1">
              <div class="back">
                <v-img :src="require('@/assets/flight.png')" width="500px" height="500px" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <section class="mobile">
      <div class="container mt-3">
        <!-- <p class="text-center top-destination">TOP DESTINATION</p> -->
        <h2 class="text-center display-6 heading">Explore Top Destination</h2>
        <div class="row gy-5 mt-5">
          <div class="col-lg-3 col-md-6" v-for="data of Destination" :key="data">
            <v-card style="border-radius:4%;cursor: pointer;" height="350px">
              <v-img :src="data.url" cover height="250px" />
              <v-card-title style="padding-left:8px;">
                {{ data.name }}
              </v-card-title>
              <div class="row mt-2">
                <div class="col-6 d-flex" align="center">
                  <v-icon color="#ff5400">
                    mdi-star-outline
                  </v-icon>
                  <v-icon color="#ff5400">
                    mdi-star-outline
                  </v-icon>
                  <v-icon color="#ff5400">
                    mdi-star-outline
                  </v-icon>
                  <v-icon color="#ff5400">
                    mdi-star-outline
                  </v-icon>

                  <v-icon color="#ff5400">
                    mdi-star-outline
                  </v-icon>
                </div>
                <div class="col-6" align="center">
                  <h5 style="color:#ff5400"> {{ data.rate }}</h5>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>



    <section class="subcribe-card">
      <div class="container mt-5">
        <p class="how-we-work">HOW WE WORK</p>
        <h4 class="display-6 heading">We Offer Best Services</h4>

        <div class="row gy-4">
          <div class="col-lg-6 col-md-6">
            <div class="row mt-4" v-for="data1 of service" :key="data1">
              <div class="col-2">
                <v-card style="padding:5px;border-radius:10%;max-width:80px;">
                  <v-img :src="data1.url" max-height="50px" max-width="50px" />
                </v-card>
              </div>
              <div class="col-10">
                <h6>{{ data1.name }}</h6>
                <p>{{ data1.letter }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="car-right">
              <v-img :src="require('@/assets/car.png')" cover width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="travel-type mobile">
      <div class="container">
        <div class="pt-5">
          <h6 class="trvel-para">TRAVEL TYPE</h6>
          <h4 class="display-6 heading">
            What Types Of Travel
            Do We Specialize In?
          </h4>
        </div>
        <div class="mt-3">

          <v-sheet class="mx-auto" elevation="8" max-width="100%" color="rgb(243, 216, 207)"
            style="box-shadow: none !important;">
            <v-slide-group v-model="model" class="pa-4" selected-class="bg-success" show-arrows>
              <v-slide-group-item v-for="n in 15" :key="n" v-slot="{ isSelected, toggle, selectedClass }">
                <v-card class="slide-card" color="grey-lighten-1" :class="['ma-4', selectedClass]" @click="toggle"
                  v-for="data of slide" :key="data">
                  <v-img :src="data.url" cover height="350px" />
                  <p class="badge">{{ data.type }}</p>
                  <div class="d-flex fill-height align-center justify-center">
                    <v-scale-transition>
                      <v-icon v-if="isSelected" color="white" size="48" icon="mdi-close-circle-outline"></v-icon>
                    </v-scale-transition>
                  </div>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </v-sheet>
        </div>
      </div>
    </section>

    <!-- <section class="mobile">
    <div class="container">
      <div class="testi-div">
        <p class="text-center testi-para">TESTIMONIAL</p>
        <h4 class="display-6 text-center heading">
          What Our Clients Says About Us
        </h4>
      </div>

      <div class="row mt-5 gy-5">
        <div class="col-md-6 col-lg-3" v-for="data of profile" :key="data">
          <v-card style="padding: 10px 20px;border-radius:10%; position: relative;overflow: unset;">

            <div class="row">
              <v-img :src="data.url" height="100px" width="100px" style="height: 80px;
    width: 80px;
    position: absolute;
    top: -40px;
    right: 2px; " />
            </div>
            <br>

            <div class="row">
              <div class="col-5">
                <v-img :src="data.url2" />
              </div>
              <div class="col-7">
                <h5> {{ data.name }}</h5>
              </div>
            </div>
            <div class=" mt-2">
              <p>
                Lorem ipsum dolor sit amet,consectetur adipiscing bibendum blandit and pellentesque.
              </p>
            </div>
            <div class="col-12 ps-1 d-flex mt-3">
              <v-icon color="red">
                mdi-star-outline
              </v-icon>
              <v-icon color="red">
                mdi-star-outline
              </v-icon>
              <v-icon color="red">
                mdi-star-outline
              </v-icon>
              <v-icon color="red">
                mdi-star-outline
              </v-icon>
              <v-icon color="red">
                mdi-star-outline
              </v-icon>
            </div>
            <br>
          </v-card>
        </div>
      </div>
    </div>
  </section> -->



    <section class="subcribe-card">
      <div class="container subscribe py-4 mt-3 ">
        <div class="row">
          <div>
            <h3 class="heading text-center sub-text">
              Subscribe To Our Newsletter & get exciting offers
            </h3>
            <p style="font-size: 14px; color:rgba(255, 255, 255, 0.9);" class="text-center">
              Keep yourself updates with latest travels news and ongoing promotions. We promise no Spamming and we won't
              share your details with any 3rd party.
            </p>
          </div>

          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <div class="col-12 col-lg-12  radio-switch d-flex align-center"
                style="margin-right: 25px;margin-bottom: 3px;">
                <v-switch color="sucess" :model-value="false" @click="subRadio()" style="flex: none;"></v-switch>
                <span style="font-size: 14px; font-weight: 600; padding-left: 5px;">Send me on Whatsapp</span>
              </div>
            </div>
            <div class="col-sm-4">

            </div>

          </div>
          <div class="row mt-2">
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <v-text-field label="Email" variant="solo"></v-text-field>
            </div>
            <div class="col-sm-4">

            </div>

          </div>
          <div class="row gy-2" v-if="phoneNumber">
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <v-text-field label="Phone Number" variant="solo" type="number" maxlength="12"></v-text-field>
            </div>
            <div class="col-sm-4"></div>

          </div>

          <div class=" d-flex  justify-content-center align-center mt-2">
            <v-btn class="subscribe-btn" variant="tonal" @click="subscribeData()">Subscribe</v-btn>

          </div>

          <div class=" d-flex flex-colunm  justify-content-center align-center mt-2">

            <div>
              <v-checkbox v-model="subscribe.checkAgree" @change="clearAgreeError" style="flex: none;"></v-checkbox>
              <span class="agree-content " style="color:rgba(255, 255, 255, 0.9);position: relative;
    top: -35px;
    left: 37px;">I agree to receive
                promotional messages and updates from Buymytrip Travels.</span>
              <p class="agree-error">{{ agreeMsg }}</p>
            </div>
          </div>






        </div>
      </div>
    </section>

  </div>


  <div class="whatts-logo">
    <a href="https://api.whatsapp.com/send?phone=919500962057" target="_blank">
      <v-img :src="require('@/assets/whatts.png')" width="70px" /></a>
  </div>

  <!-- <component :is="selectedFooter" /> -->
</template>




<script>


import moment from 'moment';
// import loginService from '@/service/loginService';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import AutoComplete from 'primevue/autocomplete';
import userData from '@/airport_icao.json';


export default {
  components: {
    // headerCom,
    // headerCom1,
    // headerCom2,
    // headerCom3,

    // footerCom,
    // footerCom1,
    // footerCom2,
    // footerCom3,


    VueDatePicker,
    AutoComplete,
  },


  data() {
    return {
      // headerStyles: ['headerCom', 'headerCom1', 'headerCom2', 'headerCom3'],
      // footerStyles: ['footerCom', 'footerCom1', 'footerCom2', 'footerCom3'],

      // selectedHeader: 'headerCom1',
      // selectedFooter: 'footerCom',

      countries: [
        { name: 'Australia', code: 'AU' }
      ],
      selectedCountry: "",
      maximumletter: false,
      maximumletter1: false,
      fromDate: "",
      dateFormat: ' dd MMM yyyy',
      name1: 'John Doe',
      source: "vasanth",
      trav: true,
      loginbox: false,
      registerbox: false,
      terms: false,
      visible: false,

      selectAirports: "",
      red: "1",
      red1: 1,
      rount: true,
      Oneway: false,
      multicity: false,
      select: "Economy",
      travels: false,
      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      addition: 0,
      customer: [],
      buton: true,
      avath: false,
      error: false,
      viewbox: false,
      // selectAirports: "",
      token: 'Tc7RQaX79Hrx5CFK',
      loading: false,
      fromAirports: [],
      airitems: [],
      items: [],

      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      valid: false,
      responseData: [],

      phoneNumber: false,

      Errormsg: "",
      agreeMsg: "",
      subscribe: {
        email: "",
        phone: "",
        checkAgree: false,
      },
      input: [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ],

      addmore: true,
      clearall: false,

      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: ""
      },

      onewaydata: {
        "request_type": "air_search",
        "request": {
          "AirShoppingRQ": {
            "CoreQuery": {
              "OriginDestinations": {
                "OriginDestination": [
                  {
                    "Departure": {
                      "AirportCode": "MAA",
                      "Date": "2023-10-19"
                    },
                    "Arrival": {
                      "AirportCode": "BOM"
                    }
                  }
                ]
              }
            },
            "DataLists": {
              "PassengerList": {
                "Passenger": [
                  {
                    "PassengerID": "T1",
                    "PTC": "ADT"
                  }
                ]
              }
            },
            "Preference": {
              "TripType": "ONEWAY",
              "CabinType": "Y",
              "Currency": "CAD",
              "FareType": [
                "PUB"
              ]
            },
            "MetaData": {
              "Currency": "USD"
            }
          }
        },
        "country": "US",
        "token": "Tc7RQaX79Hrx5CFK"
      },

      Rountdata: {
        "request_type": "air_search",
        "request": {
          "AirShoppingRQ": {
            "CoreQuery": {
              "OriginDestinations": {
                "OriginDestination": [
                  {
                    "Departure": {
                      "AirportCode": "MAA",
                      "Date": "2023-08-25"
                    },
                    "Arrival": {
                      "AirportCode": "BOM"
                    }
                  },
                  {
                    "Departure": {
                      "AirportCode": "BOM",
                      "Date": "2023-10-27"
                    },
                    "Arrival": {
                      "AirportCode": "MAA"
                    }
                  }
                ]
              }
            },
            "DataLists": {
              "PassengerList": {
                "Passenger": [

                  {
                    "PassengerID": "T1",
                    "PTC": "ADT"
                  }
                ]
              }
            },
            "Preference": {
              "TripType": "ROUND",
              "CabinType": "Y",
              "Currency": "CAD",
              "FareType": [
                "PUB"
              ]
            },
            "MetaData": {
              "Currency": "USD"
            }
          }
        },
        "country": "US",
        "token": "Tc7RQaX79Hrx5CFK"
      },

      array: [
        {
          name: "keerthi",
          age: 90
        },
        {
          name: "keerthi",
          age: 90
        },
      ],


      Adult: [],
      Child: [],
      Infrant: [],
      urldedate: "",
      urlredate: "",
      roundfrom: "",
      roundto: "",
      concatenatedArray: [],
      multi: {
        from: null,
        to: null,
      },
      deatail: {
        name: "",
        email: "",
        password: "",
        repassword: ""
      },
      loginuser: {
        email: "",
        password: ""
      },
      userName: [
        v => !!v || 'Please enter your name',
      ],
      email: [
        v => !!v || 'Please enter your Email',
      ],
      password: [
        v => !!v || 'Please enter your Password',

      ],
      repassword: [
        v => !!v || 'Please enter your Re-Password',
        v => v === this.deatail.password || 'Passwords do not match'
      ],
      pnr: [
        v => !!v || 'Please enter your PNR/Booking Ref no',
      ],
      emaillast: [
        v => !!v || 'Please enter your Email/Lastname',
      ],
      Destination: [
        {
          name: "Andaman",
          rate: "$480",
          url: require('@/assets/andaman.jpg')
        },
        {
          name: "SriLanka",
          rate: "$890",
          url: require('@/assets/SriLanka.jpg')
        },
        {
          name: "Dubai",
          rate: "$2310",
          url: require('@/assets/DUBAI.jpg')
        },
        {
          name: "Thailand",
          rate: "$760",
          url: require('@/assets/Thailand.jpg')
        },
      ],

      service: [
        {
          url: require('@/assets/choose-location.png'),
          name: "Best Price Promise",
          letter: "Get best fares to destinations worldwide."
        },
        {
          url: require('@/assets/plane.png'),
          name: "Get Great Deals! ",
          letter: "Best fares in 500+ airlines for low airfares!"
        },
        {
          url: require('@/assets/star2.png'),
          name: "Expert Guidance",
          letter: "Get personalized assistance from our travel experts."
        },
      ],
      profile: [
        {
          url: require('@/assets/double-quotes.png'),
          url2: require('@/assets/profile.png'),
          name: "Alex Feder",
        },
        {
          url: require('@/assets/double-quotes.png'),
          url2: require('@/assets/profile.png'),
          name: "Elly Forb",
        },
        {
          url: require('@/assets/double-quotes.png'),
          url2: require('@/assets/profile.png'),
          name: "Mia Nest",
        },
        {
          url: require('@/assets/double-quotes.png'),
          url2: require('@/assets/profile.png'),
          name: "Dan Dorno",
        },
      ],

      slide: [
        {
          url: require('@/assets/slide1.jpg'),
          type: 'Exploration'
        },
        {
          url: require('@/assets/slide2.jpg'),
          type: 'Vacation'
        },
        {
          url: require('@/assets/slide3.jpg'),
          type: 'Business'
        },
        {
          url: require('@/assets/slide4.jpg'),
          type: 'Journey'
        }
      ]

    }
  },

  methods: {

    subRadio() {

      this.phoneNumber = !this.phoneNumber
    },
    adulted() {
      this.Adult.push({
        "PassengerID": "T1",
        "PTC": "ADT"
      })
      //console.log(this.Adult, 'add')
    },
    adulted1() {

      this.Adult.pop()
      //console.log(this.Adult, 'remove')
    },

    Childed() {
      this.Child.push({
        "PassengerID": "T1",
        "PTC": "CHD"
      })
    },
    Childed1() {
      this.Child.pop()
    },
    Infranted() {
      this.Infrant.push({
        "PassengerID": "T1",
        "PTC": "INF"
      })
    },
    Infranted1() {
      this.Infrant.pop()
    },

    act(data) {
      this.red1 = data
      //console.log(this.red1, '00')
      this.oneway.city = this.red1
      if (this.red1 == 2) {
        this.Oneway = true
        this.rount = false,
          this.multicity = false

      }
      if (data == 1) {
        this.Oneway = false
        this.rount = true
        this.multicity = false

      }
      if (data == 3) {
        this.Oneway = false
        this.rount = false
        this.multicity = true
      }
    },
    roundData() {
      let result = "";
      result = localStorage.getItem("rountData");

      let result1 = [];
      result1 = JSON.parse(result);
      this.oneway.class = result1.class;
      if (result1.from.name) {
        this.oneway.from = result1.from.name;

      } else {
        this.oneway.from = result1.from;

      }
      if (result1.to.name) {
        this.oneway.to = result1.to.name;

      } else {
        this.oneway.to = result1.to;

      }
      this.oneway.dedate = moment(result1.dedate).format("DD MMM-YYYY");


      this.oneway.redate = moment(result1.redate).format("DD MMM-YYYY");


      this.oneway.class = result1.class;

      this.aduls = result1.adult;


      this.aduls2 = result1.infrant;

      this.aduls1 = result1.child;


      this.select = result1.class;

    },
    send() {

      if (this.input.length < 5) {
        this.input.push({
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },)


        if (this.input.length == 5) {
          this.addmore = false
        }
      }

      if (this.input.length > 2) {
        this.clearall = true
      }

      for (let i = 1; i < this.input.length; i++) {
        this.input[i].from = this.input[i - 1].to;
      }

      // for (let i = 0; i <= this.input.length - 1; i++) {
      //     //console.log(this.input.length,'this.input.length')
      //     if(this.input.length==2){
      //       this.input[1].from = this.input[0].to
      //     }
      //     else if(this.input.length==3){
      //       this.input[2].from = this.input[1].to
      //     }
      //     else if(this.input.length==4){
      //       this.input[3].from = this.input[2].to
      //     }
      //   }
      //console.log(this.input, 'ooooo')
    },


    clearall1() {

      this.input.splice(2, 5)
      this.clearall = false
      this.addmore = true
    },
    delet(index) {
      this.input.splice(index, 1)
    },


    deg1() {
      this.adulted1()
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3()
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1

        this.added()
      }
      // //console.log( this.Adult,'concatenatedArray')
    },
    add1() {
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls += 1
        //console.log(this.aduls, '1111')

        this.adulted()
        this.added()

      }

      // //console.log( this.Adult,'concatenatedArray')


    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1

        this.Childed1()
        this.added()
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1
        //console.log(this.aduls1, '2222')

        this.Childed()
        this.added()
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1

        this.Infranted1()
        this.added()
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1
        //console.log(this.aduls2, '3333')

        this.Infranted()
        this.added()
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2
      this.concatenatedArray = this.Adult.concat(this.Child).concat(this.Infrant)
      //console.log(this.concatenatedArray, 'concatenatedArray')
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls")
    },

    closeDropdown() {
      this.$refs.countrySelect.blur();
    },
    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;
      this.$refs.dpRef2.openMenu();


      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      }
      else {
        this.clicked1 = false;
        this.fromDateError = true;
        this.$refs.dpRef1.openMenu();

        setTimeout(() => {
          this.$refs.dpRef2.closeMenu();
        }, 10)
      }
    },


    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      }
      else {
        this.clicked1 = false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
      }
      else {
        this.clicked2 = false;
        this.toDateError = true;
        this.$refs.dpRef2.openMenu();
      }
    },


    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();

      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
      }
      else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End



    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;
      //console.log(this.oneway.dedate, "kkkkkkkkk");

      // this.$refs.select1.focus();

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      }
      else {
        this.clicked1 = false;
        this.fromDateError = true;
      }
    },


    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      }
      else {
        this.clicked1 = false;
      }
    },
    // Oneway datePicker End

    // Multicity datepicker

    multiDateChanged(index, newValue) {
      //console.log(index, newValue, 'didididid...')
      this.departDate = newValue;

      this.input.map((v, i) => {
        if (index == i) {

          if (this.departDate) {
            v.clicked3 = true
            v.multiDateError = false
          } else {
            v.clicked3 = false
            v.multiDateError = true
          }
        }
      })
    },

    multiClickOutside() {
      this.input.clicked3 = false
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End

    sended() {
      localStorage.removeItem("rounttrip")
      localStorage.removeItem("onewaytrip")
      if (this.oneway.from && this.oneway.to && this.oneway.dedate && this.oneway.redate) {
        this.oneway.city = this.red1
        this.oneway.adult = this.aduls
        this.oneway.infrant = this.aduls2
        this.oneway.child = this.aduls1
        this.oneway.class = this.select
        let result1 = [];
        result1 = JSON.stringify(this.oneway)
        localStorage.setItem("rountData", result1)

        let res = ""
        res = this.oneway.from.name.split(" ")
        this.roundfrom = res[res.length - 1].replace("(", "").replace(")", "")
        console.log(this.roundfrom, 'frfrfrfrfrf11111111111111111..........')

        let res1 = ""
        res1 = this.oneway.to.name.split(" ")
        console.log(res1, 'pppppp3333332222222222222222')

        this.roundto = res1[res1.length - 1].replace("(", "").replace(")", "")
        this.urldedate = moment(this.oneway.dedate).format('YYYY-MM-DD')
        this.urlredate = moment(this.oneway.redate).format('YYYY-MM-DD')
        console.log(this.oneway, ' this.oneway this.oneway this.oneway this.oneway this.oneway')
        let rountdetail = {
          from: this.oneway.from.name,
          to: this.oneway.to.name,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city
        }
        console.log(rountdetail, 'rountdetailrountdetail')

        this.$router.push({ path: '/search', query: rountdetail })

      }

      else {
        alert("please enter your detail")

        this.validateOnewayForm();
      }
    },

    sendedone() {
      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        localStorage.removeItem("rounttrip")
        localStorage.removeItem("onewaytrip")
        this.oneway.city = this.red1
        this.oneway.adult = this.aduls
        this.oneway.infrant = this.aduls2
        this.oneway.child = this.aduls1
        this.oneway.class = this.select
        let result1 = [];
        result1 = JSON.stringify(this.oneway)
        localStorage.setItem("rountData", result1)

        let res = ""
        res = this.oneway.from.name.split(" ")
        this.roundfrom = res[res.length - 1].replace("(", "").replace(")", "")
        console.log(this.roundfrom, 'frfrfrfrfrf11111111111111111..........')

        let res1 = ""
        res1 = this.oneway.to.name.split(" ")
        console.log(res1, 'pppppp3333332222222222222222')

        this.roundto = res1[res1.length - 1].replace("(", "").replace(")", "")
        this.urldedate = moment(this.oneway.dedate).format('YYYY-MM-DD')
        // this.urlredate==moment(this.oneway.dedate).format('YYYY-MM-DD')
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city
        }
        this.$router.push({ path: '/search', query: rountdetail })

      }

      else {
        alert("please enter your detail")

        this.validateOnewayForm();
      }

    },

    sendedMulticity() {

      for (let i = 0; i < this.input.length; i++) {
        //console.log(i, '.....index no....', this.input[i].from, 'kdkdkdkd.....');

        if (this.input[i].from) {
          this.input[i].multiFromError = false;
          //console.log(this.input[i].from, 'mememememe.....');
        } else {
          this.input[i].multiFromError = true;
        }

        if (this.input[i].to) {
          this.input[i].multiToError = false;
        } else {
          this.input[i].multiToError = true;
        }

        if (this.input[i].departDate) {
          this.input[i].multiDateError = false;
        } else {
          this.input[i].multiDateError = true;
        }
      }

    },
    // submit() {
    //   //console.log(this.valid, '9999')
    //   if (this.valid) {
    //     loginService.createUser(this.deatail).then(res => {
    //       //console.log(res, '88888')
    //     })
    //   }
    // },

    login() {
      let user1 = this.customer.findIndex(v => v.email === this.loginuser.email)
      //console.log(user1, '23442234')
      let user2 = this.customer.findIndex(v => v.password === this.loginuser.password)
      //console.log(user2, '23442234')
      if (user1 === 0 && user2 === 0) {
        this.avath = true
        this.buton = false
      }
      else {
        this.avath = false
        this.buton = true
        this.error = true
      }
    },
    // Subcribe Section
    subscribeData() {

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      this.Errormsg = !this.subscribe.email ? "Email is Required" :
        !emailPattern.test(this.subscribe.email) ? "Email must be valid" : "";
      this.agreeMsg = !this.subscribe.checkAgree ? "You must agree to continue!" : "";

      if (this.Errormsg === "" && this.agreeMsg === "") {
        //console.log(this.Errormsg, 'eeeeeeeeeeee')
        //console.log(this.agreeMsg, 'rrrrrrrrrrrr')
      }
    },
    clearEmailError() {
      if (this.Errormsg && this.subscribe.email) {
        this.Errormsg = "";
      }
    },
    clearAgreeError() {
      if (this.agreeMsg && this.subscribe.checkAgree) {
        this.agreeMsg = "";
      }
    },
    // Subcribe Section End



    // AutoComplete Functions

    fromClick($event) {
      //console.log($event, 'p.....')
      if ($event.from) {
        $event.from = null
        this.fromErrorMessage = true;
      }
    },

    toClick($event) {
      //console.log($event, 'p.....')
      if ($event.to) {
        $event.to = null
        this.toErrorMessage = true;
      }
    },
    multiFromClick(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true
            //console.log('vvvvv......4444')
            if (!v.from) {
              v.multiFromError = true
              //console.log('vvvvv......555')
            }
            else {
              v.multiFromError = false
              //console.log('vvvvv......666')
            }
          }
        }
      })
    },

    multiToClick(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true
            //console.log('vvvvv......111')
            if (!v.to) {
              //console.log('vvvvv......222')
              v.multiToError = true
            }

            else {
              v.multiToError = false
              //console.log('vvvvv......333')
            }
          }
        }
      })
    },


    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
        }
      }, 50)

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100)
    },
    // if (this.oneway.from) {
    //   //console.log(this.oneway.from, 'okokok....1...')
    //   this.fromErrorMessage = false;
    // }
    // else {
    //   //console.log(this.oneway.from, 'okokok....2...')
    //   this.fromErrorMessage = true;
    // }


    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
        }
      }, 50)

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100)
    },


    onChangeMultiFrom(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
          } else {
            v.multiFromError = true;
          }
        }
      })
    },

    onChangeMultiTo(index) {
      this.input.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
          } else {
            v.multiToError = true;
          }
        }
      })
    },


    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },


    fetchData() {
      Object.keys(userData).forEach((icao) => {
        const airport = userData[icao];

        if (airport.iata !== '') {
          this.fromAirports.push({
            iata: airport.iata,
            icao: airport.icao,
            places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
          });
        }
      });

    },
    search(event) {
      if (event.query.length >= 3) {
        this.maximumletter = false
        this.maximumletter1 = false
        this.loading = true;
        const searchString = event.query.toUpperCase();
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : '';
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 50);
      }
      else {
        if (this.oneway.from) {
          this.maximumletter = true
        }
        if (this.oneway.to) {
          this.maximumletter1 = true
        }

      }
    },

    focus1() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").focus();
      }, 100);
    },

    focus2() {
      this.$refs.dpRef1.openMenu();
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement = this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          //console.log(this.input[0].to, 'bfbfbfbf.....')
          this.input[1].from = this.input[0].to;
        }
      }, 10);
    }
  },

  // AutoComplete Functions End
  watch: {
    select(newSelectValue) {
      console.log('select property changed to:', newSelectValue);

      setTimeout(() => {
        this.$refs.countrySelect.focus();
      }, 100);
    },
  },

  // AutoComplete Functions
  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(', ');
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },
  // AutoComplete Functions End


  mounted() {
    this.fetchData();
    //  
    // loginService.getAllUser().then(res => {
    //   this.customer = res.data
    //   //console.log(this.customer, '44444')
    // })
    //console.log(this.oneway, 'llllllllllllllll')
  },

}

</script>
<style scoped> .v-select .v-field .v-text-field__prefix,
 .v-select .v-field .v-text-field__suffix,
 .v-select .v-field .v-field__input,
 .v-select .v-field.v-field {
   cursor: pointer;
   height: 41px;
   box-shadow: none !important;
 }

 body {
   zoom: 0.9;
   -ms-zoom: 0.9;
   -webkit-zoom: 0.9;
   -moz-transform: scale(0.9, 0.9);
   -moz-transform-origin: top;
 }

 >>>.v-select .v-field.v-field {
   cursor: pointer;
   box-shadow: none;
 }

 >>>.v-overlay__content {
   outline: none;
   position: absolute;
   pointer-events: auto;
   contain: layout;
 }

 /* >>>.v-selection-control__input {
  bottom: 6px;
} */

 .v-select .v-select__selection-text {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-size: 14px;
   font-weight: 500;
 }

 .login-card {
   position: absolute;
   z-index: 2;
 }



 /* Datepicker Style Section */


 .dp__icon {
   display: block;
 }

 .v-icon {
   width: 100%;
 }

 >>>.dp__input {
   border: none;
   border-bottom: 1px solid lightgrey !important;
   border-radius: 0px !important;
   padding: 0px 2px;
 }

 .mdi-calendar {
   margin-right: 10px;
   width: auto;
   color: grey;
 }

 .calendar-container {
   display: flex;
   align-items: center;
   position: relative;
 }

 .label-top {
   position: absolute;
   top: 0px;
   left: -8px;
   transform: translateY(-100%);
   background-color: transparent;
   padding: 0 8px;
   font-size: 12px;
   color: gray;
   /* transition: all 0.3s ease-out; */
   pointer-events: none;
 }

 .datepicker-label {
   position: absolute;
   top: 24px;
   left: -8px;
   transform: translateY(-100%);
   background-color: transparent;
   padding: 0 8px;
   font-size: 1rem;
   color: gray;
   /* transition: all 0.3s ease-out; */
   pointer-events: none;
   z-index: 1;
 }

 .label-top2 {
   position: absolute;
   top: 0px;
   left: -8px;
   transform: translateY(-100%);
   background-color: transparent;
   padding: 0 8px;
   font-size: 12px;
   color: gray;
   /* transition: all 0.3s ease-out; */
   pointer-events: none;
 }


 .datepicker-label2 {
   position: absolute;
   top: 24px;
   left: -8px;
   transform: translateY(-100%);
   background-color: transparent;
   padding: 0 8px;
   font-size: 1rem;
   color: gray;
   /* transition: all 0.3s ease-out; */
   pointer-events: none;
   z-index: 1;
 }

 .multiLabel-top {
   position: absolute;
   right: 87px;
   transform: translateY(-100%);
   background-color: white;
   padding: 0 8px;
   font-size: 12px;
   color: #999;
   /* transition: all 0.3s ease-out; */
   pointer-events: none;
 }


 .multiDatepicker-label {
   position: absolute;
   right: 69px;
   background-color: transparent;
   padding: 0 8px;
   font-size: 1rem;
   color: #999;
   /* transition: all 0.3s ease-out; */
   pointer-events: none;
   z-index: 1;
 }


 .delete-icon {
   display: flex;
   justify-content: end;
   position: absolute;
   left: 12px;
   z-index: -1;
 }

 >>>.dp__clear_icon {
   padding-right: 0px;
 }


 /* Datepicker Style Section End */

 .dp__pointer {
   cursor: pointer;
   border: none;
   border-bottom: 1px solid grey;
   border-radius: 0px;
   width: 100% !important;
 }

 >>>.dp__input_wrap {
   position: relative;
   width: 100% !important;
   box-sizing: unset;
   top: 0px;
   left: 0px !important;
 }

 >>>.dp__input {
   background-color: #fff0;
 }

 .main-page {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
 }

 >>>.v-field__input {
   color: grey !important;
 }

 .form-btn {
   padding-left: 40px;
   color: white;
 }

 .btn-box {
   background-color: transparent;
   margin: 5px;
   padding: 2px;
   box-shadow: none;
   font-size: 15px !important;
 }

 .btn-active {
   background-color: #FF5400;
   color: white;
 }

 .v-checkbox .v-input__details {
   display: none;
 }

 /* Login Page End */


 /* Login Remember Btn */

 /* .v-selection-control {
  align-items: start;
} */

 .v-label {
   align-items: start;
 }

 .labeling1 {
   position: relative;
   /* bottom: -6px; */
   top: 10px;
   font-size: 14px;
   font-weight: 400;
   color: black;
   left: 30px;
   z-index: 2;
 }

 .labeling {
   position: relative;
   /* bottom: -6px; */
   top: 10px;
   font-size: 14px;
   font-weight: 400;
   color: rgb(51, 6, 148);
   left: 30px;
   z-index: 2;

 }

 .labeling:focus {
   color: red !important;
 }

 .v-selection-control__wrapper {
   width: 30px;
   max-height: 23px;
 }

 .back {
   /* background-image: url('./assets/bg.png'); */
   background-image: url('../assets/bg.png');
   background-repeat: no-repeat;
   background-size: 800px;
   background-position: top right 24%;
   z-index: 1;
 }



 body {
   font-family: var(--normal-font);
 }

 /* navbar */
 .logo {
   width: 180px;
 }

 .nav-link {
   font-weight: var(--font-600);
   font-size: var(--font-14);
 }

 .nav-link.active {
   color: var(--primary-color) !important;
 }

 .btn-white {
   background-color: white;
   padding: 10px 25px;
   border-radius: 15px;
 }

 .btn-text {
   color: var(--primary-color);
   font-weight: var(--font-700);
 }

 .v-select .v-field.v-field {
   cursor: pointer;
   box-shadow: none;
 }

 .v-btn__content {
   /* color: #FF5400; */
   font-size: 17px;
   font-weight: 600;
   text-transform: capitalize;
 }

 .book-ticket {
   /* color: #FF5400; */
   background-color: #f0f0f0;
 }

 .whatts-logo {
   display: block;
   position: fixed;
   cursor: pointer;
   right: 30px;
   bottom: 100px;
   z-index: 9999;
 }

 /* navbar end */

 /*Flight banner */
 .banner-left-small-title {
   color: var(--primary-color) !important;
 }

 .bannar-bg {
   background-image: url('../assets/bg-map.png');
   /* background-image: url('../assets/slider1.jpg');    */
   background-size: 55% 83%;
   background-repeat: no-repeat;
   background-position: center;
 }

 .flight-right {
   width: 500px !important;
   height: 500px;
 }

 .banner-left-small-title h6 {
   font-weight: var(--font-600);
 }

 .banner-small-text {
   color: var(--primary-color) !important;
 }

 .banner-left-title h4 {
   font-family: var(--title-font);
   font-weight: var(--font-600);
   font-size: 60px;
 }

 .location {
   color: #FF5400;
   width: 22px;
 }

 .form-control {
   border: none;
   border-bottom: 1px solid lightgrey;
   border-radius: 0;
   background-color: unset !important;
   /* width: 150px; */
 }

 .form-control:focus {
   color: unset !important;
   border-color: unset !important;
   outline: none !important;
   box-shadow: none !important;
 }

 .form-select {
   color: unset !important;
   /* border-color:unset !important; */
   outline: none !important;
   box-shadow: none !important;
   border: none;
   border-bottom: 1px solid lightgrey;
   border-radius: 0;
   /* width: 150px; */
 }

 .form-select:focus {
   border-color: unset !important;
 }

 .form-control::placeholder {
   font-size: 14px;
   font-weight: var(--font-500);
 }

 .input-group-text {
   text-align: center;
   white-space: nowrap;
   border: none;
 }

 .form-location {
   width: 18px;
   margin-top: -15px;
 }

 .btn {
   background-color: #FF5400;
   color: #fff;
 }

 .btn:hover {
   border: 1px solid #FF5400;
   color: #FF5400;
 }

 /* banner end */

 /* Explore Top Destination */

 .top-destination {
   color: #FF5400;
   font-size: 14px;
 }

 .heading {
   font-family: var(--title-font);
   font-weight: var(--font-600);
 }

 .card.top-card {
   height: 330px;
   border-radius: 25px;
 }

 .card.top-card .card-tour {
   height: 220px;
   width: auto;
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
 }

 /* .icon-container {
    margin-right: 10px;
  } */

 .calendar-wrapper {
   display: inline-block;
   border-bottom: 1px solid lightgray;
 }

 .calendar-label {
   display: block;
   margin-bottom: 5px;
 }

 .card-body .star {
   color: #FF5400;
   margin-top: 20px;
 }

 .card-body .rate {
   color: #FF5400;
 }


 .how-we-work {
   color: #ff5400;
   font-weight: 600;
   font-size: 14px;
 }

 .how-we-section {
   margin-top: 100px;
 }

 .choose {
   width: 40px;
 }


 .choose-item {
   box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
   ;
   width: 75px;
   height: 75px;
   border-radius: 10px;
 }

 .choose-para {
   font-size: 14px;
   font-weight: 600;
   margin-bottom: 0;
 }

 .car-right-wrapper {
   width: 700px;
   height: 700px;
 }

 .car-right {
   position: relative;
   bottom: 20%;
 }

 .lorem {
   font-size: 11px;
 }

 .session-bg {
   background-image: url('../assets/bg1.png');
   background-repeat: no-repeat;
   background-position: top 100px right -290%;
 }

 .travel-type {
   background-color: rgb(243, 216, 207);

 }

 .trvel-para {
   color: #ff5400;
   font-weight: 600;
 }

 .owl-theme .owl-nav [class*="owl-"] {
   border: 2px solid darkturquoise;
   padding: 15px !important;
   border-radius: 50% !important;
   background-color: rgb(243, 216, 207) !important;
   width: 50px;
   margin-right: 20px;
   color: darkturquoise;
 }

 .owl-images img {
   height: 350px;
   border-radius: 30px;
 }

 .owl-theme .item {
   width: 330px;
 }

 .owl-images {
   position: relative;
 }

 .badge {
   position: absolute;
   top: 40px;
   left: 15px;
   background-color: #ff5400;
   color: #fff;
   border-radius: 30px;
 }



 .testi-para {
   color: #FF5400;
   font-weight: 600;
 }

 .testi-div {
   margin-top: 100px;
 }

 .card img {
   width: 100%;
   height: 100%;
 }

 .test-name {
   font-size: 18px;
   font-weight: 400;
 }

 .card-text {
   font-size: 14px;
 }

 .card {
   height: 250px;
   box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
   border: none;
   border-radius: 25px;
 }

 .card-relative {
   position: relative;
 }

 .quotes {
   position: absolute;
   top: -35px;
 }


 /* Subscribe Section */

 .subscribe {
   /* background-color: navy !important; */
   background-color: #019DF2 !important;
   color: white;
   border-radius: 14px;
   line-height: 28px;
 }

 input {
   outline: none;
 }


 >>>.v-switch__track {
   height: 12px;
   width: 24px;
 }

 >>>.v-switch__thumb {
   width: 16px;
   height: 16px;
 }

 .subscribe-input {
   border-radius: 17px;
   border: none;
   padding: 15px 20px;
   background-color: #fff;
 }

 >>>>.v-input__control {
   display: flex;
   grid-area: control;
   height: 50px;
 }

 .subscribe-btn {
   border-radius: 5px;
   border: none;
   width: 200px;
   height: 35px;
   background-color: #ff5400 !important;
   color: white !important;
   /* background-color: #fff; */
 }

 >>>.v-input__details {
   display: none;
 }

 >>>.dp__overlay_col[aria-disabled="true"] {
   display: none;
 }

 .email-error {
   position: absolute;
   margin-top: 20px !important;
   margin-bottom: -25px;
   margin-right: 65px;
 }

 .agree-error {
   position: absolute;
   margin-top: 40px;
   margin-left: 40px;
 }

 /* Subscribe Section End */


 .form-input {
   border: none;
 }

 .btn-badge {
   background-color: aqua;
   border: none;
   border-radius: 40px;
 }

 .btn-badge:hover {
   background-color: aqua;
   border: none;
   color: black;

 }

 .py-8 {
   padding-top: 5rem;
   padding-bottom: 5rem;
 }





 .social-icons {
   color: var(--primary-color) !important;
 }

 .fs-14 {
   font-size: 14px;
   list-style-type: none;
 }

 .fs-14 li {
   padding: 6px;
 }

 section {
   margin: auto !important;
 }

 .slide-card {
   border-radius: 4%;
   width: 300px;
   height: 300px;
 }

 .card-test {
   position: relative;
   padding: 10px 15px;
   border-radius: 8%;
   max-height: 500px;
 }

 .card-img {
   position: absolute;
   width: 100px;
   height: 100px;
   top: -50%;
   right: 0;
   transform: translateY(-50%);
 }

 .radio {
   background-color: #E2E7FF;
   border-radius: 14px;
   color: #007bff;
   width: 120px;
   font-size: 15px !important;
   font-weight: 400px;
 }

 .v-input--density-default {
   --v-input-control-height: 38px;
   /* position: relative;
  top: 0px; */
 }

 .normal-radio {
   font-size: 14px !important;
   font-weight: 400px;
   height: 30px;
   width: auto;
   padding-right: 10px;
 }

 .cart-adult {
   padding: 2px 15px 15px 15px;
   width: 214px;
   cursor: pointer;
   position: absolute;
   top: 131px;
   left: 252px;
 }

 .adult {
   height: 100%;
   border: 1px solid lightgrey;
   border-radius: 5px;
   background-color: #F7F7F7;
 }

 .booknow {
   padding-right: 20px;
 }

 .adult-plus {

   text-align: center;
   font-size: 15px;
   font-weight: 500;
   color: #05386d;
 }

 .adult-title {
   font-size: 12px;
   font-weight: 600;
   color: #05386d;
 }

 >>>.p-datepicker-group-container {

   background-color: #ffffff !important;
   width: 200px;
   padding: 5px 3px;
   border: 1px solid lightgray;
   position: relative;
   right: 14px;

 }


 .calendar-wrapper {
   border-bottom: 1px solid #a9a1a1;
   max-width: 500px;
 }


 .calendar-wrapper {
   border-bottom: 1px solid #a9a1a1;
   max-width: 100% !important;
 }

 >>>.p-datepicker {
   width: auto;
   background: white !important;
 }


 /* Flight Search Box */


 .search {
   background-color: #ff5400;
   color: white !important;
   text-transform: capitalize;
 }

 .mdi-airplane-takeoff::before {
   content: "\F05D5";
   margin-top: 0px;
   margin-right: 10px;
   color: gray;
 }

 .mdi-airplane-landing::before {
   content: "\F05D4";
   margin-top: 0px;
   margin-right: 10px;
   color: gray;
 }

 >>>.dp__input_icon {
   display: none;
   top: 64%;
   left: -5% !important;
 }

 .p-component * {
   box-sizing: border-box;
   /* padding: 2px;
  padding-top: 8px;
  padding-bottom: 8px; */
 }

 >>>.p-autocomplete-loader {
   position: absolute;
   top: 50%;
   right: 5px;
   margin-top: -0.5rem;
   left: 13 5px !important;
   display: block !important;
 }

 >>>.p-icon {
   display: none;
 }

 >>>.p-autocomplete-panel {
   color: white;
   border-radius: 10px;
 }



 .p-error {
   font-size: 12px;
   color: red;
   /* position: absolute;
  left: 0px;
  top: 24px; */
 }

 .dropdown-box {
   padding: 8px 10px;
   /* border-radius: 10px; */
   color: white;
   background-color: lightslategray;
   border-bottom: 1px solid white;
 }

 .dropdown-box:hover {
   background-color: black;
   color: white;
   border-radius: 10px;
   /* background-color: lightgray;
  color: black; */
 }


 >>>.p-autocomplete-dd .p-autocomplete-input,
 .p-autocomplete-dd .p-autocomplete-multiple-container {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
   outline: none !important;
 }

 .p-float-label {
   width: 100%;
 }

 .p-float-label label {
   position: absolute;
   pointer-events: none;
   top: 14px;
   left: 0px;
   margin-top: -0.5rem;
   transition-property: all;
   transition-timing-function: ease;
   line-height: 1;
   color: gray;
 }

 >>>.p-input-filled {
   box-shadow: 1px 1px 6px 0px black !important;
 }


 /* End Flight Search Box */



 @media only screen and (max-width:480px) {
   .mobile {
     display: none
   }

   .flight1 {
     display: none
   }

   .flight-back {
     background-image: url('../assets/flight.png');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;

   }

   .subcribe-card {
     margin-top: 10% !important
   }


 }

 @media only screen and (max-width: 992px) {
   .car-right {
     width: 100%;
     height: auto;
     margin-top: 0;
   }

   .session-bg {
     background-image: none;
   }

   .back {
     background-image: none;
   }

   .flight-right {
     width: 80% !important;
   }

   .form-btn {
     padding: 10px 0px 0px 0px !important;

   }

   .btn-box,
   .pipe {
     color: blueviolet !important;
   }

   .btn-active {
     background-color: blueviolet;
     color: white !important;
   }

   .sub-text {
     font-size: 26px;
   }

   /* .subscribe-btn {
    width: 40%;
    height: 100%;
    font-size: 18px;
  } */

   .cart-adult {
     top: 113px !important;
     left: 275px !important;
   }

   .email-error {
     width: 100%;
   }

   .agree-error {
     margin-top: 30px;
   }

 }


 @media only screen and (max-width: 1200px) {
   .owl-theme .owl-nav [class*="owl-"] {
     margin-right: 0 !important;
   }

   .owl-theme .item {
     width: auto;
     padding-right: 15px;
   }

   .cart-adult {
     top: 134px;
     left: 188px;
   }

   .email-error {
     margin-right: 30px;
   }

   .agree-content {
     height: 75%;
     font-size: small;
   }
 }

 @media only screen and (max-width: 768px) {
   .owl-theme .owl-nav [class*="owl-"] {
     margin-right: 0 !important;
   }

   .owl-theme .item {
     width: auto;
   }

   .dropdown_section {
     width: auto !important;
   }

   /* .email-error {
    margin-right: 465px;
  } */

 }


 @media only screen and (max-width: 576px) {
   .cart-adult {
     top: 280px !important;
     left: 181px !important;
   }

   /* .email-error {
    margin-right: 240px;
  } */
 }



 .p-float-label::-webkit-scrollbar {
   width: 5px !important;
   background-color: navy !important;
 }

 .p-float-label::-webkit-scrollbar-track {
   box-shadow: inset 0 0 5px grey !important;
   border-radius: 10px !important;
 }

 .p-float-label::-webkit-scrollbar-thumb {
   background: lightgrey !important;
   border-radius: 10px !important;
 }</style>