<template>
  <div class="row d-flex flex-column align-end">
    <div class="col-lg-4 d-flex flex-column align-center" style="position: absolute; z-index: 2;">
      <v-card v-if="registerbox" style=" width:80%; border-radius:7px;"  outlined  v-click-outside="onClickOutside">
        <div class="row d-flex align-items-center justify-content-center">
          <div class="col-md-12 main-page">
            <div class="bg-light main-page mx-auto shadow-none"
              style="background-color:#f8b2b2; width: 100%; height: auto;">
              <div class="col-12 pe-3 pt-2" align="end" @click="closeRegisterBox()">
                <v-icon size="small" style="color:navy">mdi-close-circle</v-icon>
              </div>

              <div v-if="regForm">
                <v-form ref="form" v-model="valid" @submit.prevent="submitForm" style="width: 100%;">

                  <v-text-field v-model="personal.username" :rules="userName" label="Name"
                    variant="underlined" placeholder="Enter your Name" style="height: 60px;"></v-text-field>
                  <!-- <span style="color: red;">invalid Username</span><br> -->

                  <v-text-field v-model="personal.email" :rules="eMail" label="Email ID" placeholder="johndoe@gmail.com"
                    type="email" variant="underlined" style="height: 60px;"></v-text-field>
                  <!-- <span style="color: red;">invalid Email</span><br> -->

                  <v-text-field v-model="personal.password"  :rules="passWord" label="Password"
                    placeholder="Enter your password" variant="underlined" required
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                    @click:append-inner="visible = !visible" style="height: 60px;"></v-text-field>
                  <!-- <span style="color: red;">invalid password</span> -->

                  <v-text-field v-model="personal.rePassword" :rules="repassWord" label="Re-Password"
                    placeholder="Enter your Re-password" variant="underlined" required
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                    @click:append-inner="visible = !visible" style="height: 60px;"></v-text-field>
                  <!-- <span style="color: red;">invalid Re-password</span> -->

                  <div class="d-flex my-4">
                    <div class="col-9" align="end">
                      <v-btn width="70%" :disabled="btnDisabled" type="submit" style="background-color:#FF5400; color:white">Submit</v-btn>
                    </div>
                    <div class="col-3 ps-3" align="end">
                      <v-progress-circular v-if="loadRegister" :width="3" style="color:navy" indeterminate></v-progress-circular>
                    </div>
                  </div>
                  <!-- <v-divider></v-divider> -->
                  <p style="font-weight: 500;">You have already an account?
                    <span @click="registerbox = false, loginSection = true" style="cursor: pointer;"> &nbsp;
                      <b style="color:navy">Sign In</b></span>
                  </p>
                </v-form>
              </div>


              <div v-if="regMsg">
                <div class="d-flex justify-center align-center p-3">
                  <v-icon color="success" size="50">mdi-check-circle-outline</v-icon>
                  <h4 class="text-success ps-2 m-0"> Register Successfully...</h4>
                </div>
                <div class="d-flex justify-center align-center p-3">
                  <p>{{ registerMsg }}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>

  <div v-if="loginSection">
    <login />
  </div>
</template>

<script>
import login from '../components/loginCom.vue';
import axios from 'axios';

export default {
  components: {
    login
  },
  data() {
    return {

      valid: false,

      registerbox: true,
      loginSection: false,
      terms: false,
      visible: false,

      regForm: true,
      regMsg: false,
      registerMsg: "",
      loadRegister:false,
      btnDisabled:false,

      personal: {
        username: "",
        email: "",
        password: "",
        rePassword: ""
      },
      data1: {
        "request_type": "user_register",
        "user_name": "admin",
        "email": "admin12553435@gmail.com",
        "password": "1234567890",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "0000",
      },


      userName: [
        v => !!v || 'Please enter your Username',
        v => (v.length >= 3) || 'Name must be greater than 3 ',
      ],

      eMail: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

      passWord: [
        v => !!v || 'Please enter your Password',
        // v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        // v => (v && v.length <= 20) || 'Password must be less than 20 characters',
        // v => (v && /[a-z]/.test(v)) || 'Password must contain at least one lowercase letter',
        // v => (v && /[!@#$%^&*]/.test(v)) || 'Password must contain at least one special character'
      ],
      repassWord: [
        v => !!v || 'Please enter your Re-password',
        v => v === this.personal.password || 'Passwords do not match'
      ],


      registerReq: {
        "request_type": "user_register",
        "user_name": "",
        "email": "",
        "password": "",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "0000",
        "token": "Tc7RQaX79Hrx5CFK"
      }
    };
  },
  methods: {
    onClickOutside(){
this.registerbox=false
    },
    submitForm() {

      console.log(this.valid, 'oooooooooo')
      if (this.valid) {

        this.loadRegister=true
        this.btnDisabled=true

        console.log(this.personal, 'pupupu')

        this.registerReq.user_name = this.personal.username
        this.registerReq.email = this.personal.email
        this.registerReq.password = this.personal.password

        console.log(this.registerReq, 'ffff.....')

        axios.post('https://vtapi.wistirna.com/api/rest', this.registerReq, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {

            if (response.data) {
              this.regForm = false
              this.regMsg = true
              console.log('Response:', response.data);
              console.log('Response-Msg:', response.data.register.message);
              this.registerMsg = response.data.register.message

              // this.registerbox = false
            }
          })
      }
    },

    closeRegisterBox() {
      this.registerbox = false;
      this.$emit('close'); // Emit the 'close' event to the parent component
      console.log(this.registerbox, '00000')
    },
  }
}
</script>



<style scoped>
.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
}

.btn-active {
  background-color: blueviolet;
  color: white;
}

>>>.v-input__details {
  display: block !important;
}


/* .v-checkbox .v-input__details {
  display: none;
} */

/* Login Page End */



@media only screen and (max-width: 992px) {

  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }
}
</style>