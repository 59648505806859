<template>
      
  <v-app>

    <component :is="selectedHeader" />
    
    <v-main>
      <router-view />
    </v-main>

    <component :is="selectedFooter" />

  </v-app>
</template>


<script>
import headerCom from '@/components/headerCom.vue'
import headerCom1 from '@/components/headerCom1.vue'
import headerCom2 from '@/components/headerCom2.vue'
import headerCom3 from '@/components/headerCom3.vue'

import footerCom from "@/components/footerCom.vue"
import footerCom1 from '@/components/footerCom1.vue'
import footerCom2 from '@/components/footerCom2.vue'
import footerCom3 from '@/components/footerCom3.vue'

export default {
  name: 'App',
  components: {
    headerCom,
    headerCom1,
    headerCom2,
    headerCom3,

    footerCom,
    footerCom1,
    footerCom2,
    footerCom3,
  },
  data:() => ({
    headerStyles: ['headerCom', 'headerCom1', 'headerCom2', 'headerCom3'],
      footerStyles: ['footerCom', 'footerCom1', 'footerCom2', 'footerCom3'],

      selectedHeader: 'headerCom1',
      selectedFooter: 'footerCom',
      // headerStyles: ['headerCom', 'headerCom1', 'headerCom2','headerCom3'],
      // footerStyles: ['footerCom', 'footerCom1', 'footerCom2','footerCom3'],

    // selectedHeader: 'headerCom',
    // selectedFooter: 'footerCom'
  }),
}
</script>

<style>


</style>