import { createRouter, createWebHistory } from "vue-router";
// import parentCompo from '../components/parentCompo.vue'
import pagenot from "../components/pageNot.vue";
import date from "../components/dateCompo.vue";
import flightInfo from "../components/flightInfo.vue";
import confirmBook from "../components/confirmBook.vue";
// import TelInput from '../components/telInput.vue';
import bookingCofirm from "../components/bookingCofirm.vue";
import hello from "../components/HelloWorld.vue";
import search from "../components/SearchResult.vue";
// import loader from '../components/loaderComponent.vue'
import travellerDetail from "@/components/travellerDetails.vue";
import test from "@/components/testCom.vue";

const routes = [
  {
    path: "/",
    component: hello,
  },
  {
    path: "/pageNot",
    component: pagenot,
  },

  {
    path: "/search",
    component: search,
  },
  // {
  //   path:"/loader",
  //   component:loader
  // },
  {
    path: "/travellerDetail",
    component: travellerDetail,
  },
  {
    path: "/flightInfo",
    component: flightInfo,
  },
  {
    path: "/confirmBook",
    component: confirmBook,
  },
  {
    path: "/date",
    component: date,
  },
  {
    path: "/test",
    component: test,
  },
  {
    path: "/bookingCofirm",
    component: bookingCofirm,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: pagenot },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
