<template>
    <!-- Footer -->

    <footer>
        <div class="footer-middle">
            <div class="container">
                <div class="row d-flex justify-center">
                    <div class="col-md-2 col-8">
                        <v-img :src="require('@/assets/buymytrip.png')" height="50px" class="bg-light" max-width="100%"
                            align="center" />
                    </div>
                    <div class="col-md-2 col-sm-6 d-flex justify-center">
                        <!--Column1-->
                        <div class="footer-pad">
                            <h4>Company</h4>
                            <ul class="list-unstyled">
                                <li>About us</li>
                                <li>Destination</li>
                                <li>Packages</li>
                                <li>Contact us</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 d-flex justify-center">
                        <!--Column1-->
                        <div class="footer-pad">
                            <h4>Help</h4>
                            <ul class="list-unstyled">
                                <li>Help/Faqs</li>
                                <li>Cancel Your Flight</li>
                                <li>Press</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 d-flex justify-center">
                        <!--Column1-->
                        <div class="footer-pad">
                            <h4>More</h4>
                            <ul class="list-unstyled">
                                <li>Domestic Flights</li>
                                <li>Investor Realtion</li>
                                <li>Partnership</li>
                                <li>Jobs</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 d-flex justify-center">
                        <!--Column1-->
                        <div class="footer-pad">
                            <h4>Terms</h4>
                            <ul class="list-unstyled">
                                <li>Privacy policy</li>
                                <li>Terms of use</li>
                                <li>Accessibility</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex flex-column align-center justify-center">
                        <h4>Follow Us</h4>
                        <ul class="social-network social-circle">
                            <li><a href="#" class="icoFacebook" title="Facebook"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#" class="icoLinkedin" title="Linkedin"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="#" class="icoLinkedin" title="Linkedin"><i class="fa fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 copy">
                        <p class="text-center">&copy; Copyright 2023 - <a href="">buymytrip</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <!-- Footer End -->
</template>


<style scoped>
/*FOOTER*/

footer {
    background: #16222A;
    background: -webkit-linear-gradient(59deg, #3A6073, #16222A);
    background: linear-gradient(59deg, #3A6073, #16222A);
    color: white;
    margin-top: 10px;
}

footer a {
    color: #fff;
    font-size: 14px;
    transition-duration: 0.2s;
}

footer a:hover {
    color: #FA944B;
    text-decoration: none;
}

.copy {
    font-size: 12px;
    padding: 10px;
    border-top: 1px solid #FFFFFF;
}

.footer-middle {
    padding-top: 1em;
    color: white;
}


/*SOCİAL İCONS*/

/* footer social icons */

ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
}

ul.social-network li {
    display: inline;
    margin: 0 5px;
}


/* footer social icons */

.social-network a.icoFacebook:hover {
    background-color: #3B5998;
}

.social-network a.icoLinkedin:hover {
    background-color: #007bb7;
}

.social-network a.icoFacebook:hover i,
.social-network a.icoLinkedin:hover i {
    color: #fff;
}

.social-network a.socialIcon:hover,
.socialHoverClass {
    color: #44BCDD;
}

.social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 15px;
}

.social-circle li i {
    margin: 0;
    line-height: 30px;
    text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

.social-circle i {
    color: #595959;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}

.social-network a {
    background-color: #F9F9F9;
}
</style>